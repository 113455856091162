import { Revision } from './../../../models/revision';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdministracionService } from '../../administracion.service';


@Component({
  selector: 'app-reset-tacometro',
  templateUrl: './reset-tacometro.component.html',
  styleUrls: ['./reset-tacometro.component.css']
})
export class ResetTacometroComponent implements OnInit {

  public vehiculo: number;
  public placa: string;
  public km: number;
  public ultKm: number;
  public error: string = "";


  constructor(public bsModalRef: BsModalRef,
              private mensaje: ModalMensajeService,
              private service: AdministracionService ) { }

  ngOnInit(): void {
  }

  guardar(){
    this.error="";
    if(!this.km){
      this.error ="Ingrese kilometraje valido!";
      return;
    }

    this.mensaje.confirmMensaje('¿ Realmente quiere resetear el tacometro del vehículo, Seguro que quiere continuar.?').subscribe(arg => {
      if(arg) {
         this.registrarRevision();
      }
   });
  }

  registrarRevision() {

    const revision: Revision ={
      codigo: 0,
      vehiculo: this.vehiculo,
      vehiculoplaca: this.placa,
      conductor: null,
      kilometraje: this.km,
      km: 0,
      usuariosis: null,
      observacion: 'Revisión creada por el sistema para el reseteo del  kilometraje del vehículo.',
      tipo: 18,
      mantenimiento:0,
      estado:0
    }

     this.service.resetTacometro(revision).subscribe(result => {
         if(result.estado){
          this.bsModalRef.hide();
          this.mensaje.showMensaje('Tacometro del vehículo reseteado.','info');
         }else{
          this.mensaje.showMensaje('No se pudo realizar la operación.','error');
         }
     });
    }



}
