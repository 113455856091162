import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SelectOption } from 'src/app/models/select-option';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdministracionService } from '../administracion.service';

@Component({
  selector: 'app-servicio',
  templateUrl: './servicio.component.html',
  styleUrls: ['./servicio.component.css']
})
export class ServicioComponent implements OnInit {

  public categorias: SelectOption[]=[];
  public servicios: SelectOption[]=[];

  public servicio: SelectOption;
  public error: string;
  public tractive: number;

  public categoria: number;

  constructor(private service: AdministracionService,
              private mensaje: ModalMensajeService,
              private sesions: SesionService,
              private router: Router
              ) {
    this.servicio = {
      codigo: null,
      descripcion: ''
    }
    this.error ='';

  }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(13)) {
      this.router.navigate(['/inicio']);
    }
    this.getCats();
    }

    getCats(){
      this.service.getCategorias().subscribe(result => {
       this.categorias = result.categorias;
      });
    }


    getServiciosCat(){
      if(this.categoria){
        this.service.getServiciosCat(this.categoria).subscribe(result => {
          this.servicios = result.servicios;
        });
      }
    }


    insertServicio(){
      if(!this.categoria){
        this.error ='Seleccione una categoria.';
        return;
      }
      if(this.servicio.descripcion.trim() ==''){
        this.error ='Ingrese una descripción para el servicio.';
        return;
      }

      this.servicio.aux2 = this.categoria;
      this.service.insertServicio(this.servicio).subscribe(result => {
        this.servicios= result.servicios;
       });

    }


    enableServ(item: SelectOption){
      if(item.estado==0){return;}
      this.servicios.forEach(element => {
         element.enable = element.codigo == item.codigo;
         element.temp = element.descripcion;
      });
    }

    updateServ(item: SelectOption){
      if(item.temp.trim() == ''){
        this.error ='ingrese una descripción para el servicio.';
        return;
      }

      if(item.descripcion.trim() !== item.temp.trim()){
        this.error ='';
        this.service.updateServicio(item).subscribe(result => {
          if(result.estado){
            this.servicios = result.servicios;
          }else{
            this.mensaje.showMensaje('Error al realizar operación','error');
          }

        });
      }else{
        item.enable = false;
      }
    }

    deleteServicio(item: SelectOption){
      if(item.estado==0){return;}
      this.mensaje.confirmMensaje('¿ Seguro que quiere eliminar el servicio ?').subscribe(arg => {
        if(arg){
          this.service.deleteServicio(item.codigo, this.categoria).subscribe(result => {
            if (result.estado) {
               this.mensaje.showMensaje('Servicio eliminado','info')
            } else {
              this.mensaje.showMensaje('Error al realizar operación','error');
            }
           this.servicios = result.servicios;
          });
        }
      });
    }


}
