<div class="row mb-5" style="max-width: 700px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Perfiles</h3></div>
     <section>


      <div class="cav-radius mt-1"></div>
      <div class="table-responsive" >

         <table class="table" style="border: solid 1px #cccccc;">
          <thead class="thead-dark small">
           <tr class="bg-info text-white" >
            <th scope="col" >COD.</th>
              <th scope="col" class="text-left">DESCRIPCIÓN</th>
              <th scope="col" class="text-left">VEHICULOS</th>
              <th scope="col" class="text-left">ESTADO</th>
              <th scope="col" class="text-left"></th>
              <th scope="col" class="text-left"></th>
           </tr>
          </thead>
           <tbody>
           <tr *ngFor="let item of perfiles;let i = index; let impar = odd;let par = even;"
            [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}"
            (click)="tractive = i">
            <td>{{item.codigo}}</td>
            <td class="text-left">{{item.descripcion}}</td>
            <td class="text-left">{{item.vehiculos == 0 ? 'Todos': 'Conductor'}}</td>
            <td>{{item.estado==1 ?'Activo':'Inactivo'}}</td>
            <td class="text-left"><img src="assets/GENERAL/mod.png" width="30" (click)="modPerfil(item)">   </td>
            <td class="text-left"><img  [src]="item.estado==1 ? 'assets/GENERAL/delete.png' : 'assets/GENERAL/delete2.png'" width="30" (click)="desactivarPerfil(item)">   </td>

           </tr>

          </tbody>
         </table>

     </div>

     <button type="button" class="btn btn-warning" [routerLink]="['/administracion/permisos/perfil',0]" >Nuevo Perfil</button>

    </section>

  </div>
</div>
