import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VehiculoService } from '../consulta-vehiculo/vehiculo.service';
import { SesionService } from '../core/services/sesion.service';
import { UtilService } from '../core/services/util.service';
import { WaitService } from '../core/services/wait.service';
import { Conductor } from '../models/conductor';
import { SelectOption } from '../models/select-option';
import { usuario } from '../models/usuario';
import { Vehiculo } from '../models/vehiculo';
import { GraficaConductorComponent } from './grafica-conductor/grafica-conductor.component';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public tractive: number;
  public error: string;
  public vehiculos: Vehiculo[];
  public conductores: Conductor[];

  bsModalRef: BsModalRef;

  public currentPage: number;
  public totalitem: number;

  public sucursales: SelectOption[]=[];
  public sucursal: number;

  public fecha =  new Date();
  public daterangepickerModel: Date[];

  public bsConfig = { dateInputFormat: 'YYYY/MM/DD',
  containerClass: 'theme-default',
  isAnimated: false,
  adaptivePosition: true };
  maxDate = new Date();

  public sesion: usuario;
  public itemPag:number = 25;

  public load: boolean =false;

  public option = 'vehiculos';

  constructor(private service: HomeService,
              private serviceVehiculo: VehiculoService,
              private util: UtilService,
              private router: Router,
              private sesions: SesionService,
              private wait: WaitService,
              private route: ActivatedRoute,
              private modal: BsModalService) {
    this.vehiculos = [];
    this.conductores = [];
    this.currentPage = 1;
    this.error='';
    this.daterangepickerModel =[new Date(), new Date()];

    this.sesions.select$().subscribe( s=> this.sesion = s);
   }

  ngOnInit(): void {
    this.ini();
  }

  ini(){
    this.service.ini().subscribe(result => {
      this.sucursales = result.sucursales;
      this.sucursal = this.sucursales[0].codigo;
      this.getVehiculos(1);
    });
  }

  getVehiculos(pag: number){
    if(!this.sucursal){return;}
    let desde = '0';
    let hasta = '0';
    this.currentPage = pag;
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
     }

    this.service.getVehiculos(pag, this.itemPag, this.sucursal, desde, hasta).subscribe(result => {

      result.vehiculos.forEach(element => {
        if(element.notificaciones){
          element.notificaciones = JSON.parse(element.notificaciones);
        }else{
          console.log('noti',element.notificaciones)
        }
      });
      
      /* result.vehiculos.forEach(element => {
        var aux = JSON.parse(element.notificaciones);
        
        element.notificaciones = JSON.parse(element.notificaciones);

        var good_notifications = []
        var bad_notifications = []
        for(let n in aux){
          if(n['dias']>0){
            good_notifications.push(n)
          }else{
            bad_notifications.push(n)
          }
        }
        element.bad_notificaciones  = JSON.stringify(good_notifications)
        element.good_notificaciones = JSON.stringify(good_notifications)
      }); */

      console.log(result)
      this.vehiculos = result.vehiculos;
      this.totalitem = result.total;
      //this.totalitem = result.total;  
      this.load = true;

      this.wait.hidden();

    });
  }



  consultarVehiculo(vehiculo: number){
    this.router.navigate(['/consultavehiculo',vehiculo]);
  }

  consultarConductor(conductor: number, nombre: string){
    this.router.navigate(['/consultaconductor/'+conductor+'/'+nombre]);
  }



  getConductores(pag: number) {
    if(!this.sucursal){return;}
    let desde = '0';
    let hasta = '0';
    this.currentPage = pag;
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
     }

    this.service.getConductores(pag, this.itemPag, this.sucursal, desde, hasta).subscribe(result => {
        this.conductores = result.conductores;
        this.totalitem = result.total;
        this.load = true;

        this.wait.hidden();

    });
  }

consultarData(pag?:number) {
  if(this.option=='vehiculos') {
    this.getVehiculos(pag?? this.currentPage );
  }else{
    this.getConductores(pag?? this.currentPage );
  }
}

  pageChanged(event:any){
    this.currentPage = event.page;
    this.consultarData();
  }

  onPagesLength(){
    this.currentPage=1;
    if(this.option=='vehiculos'){
      this.getVehiculos(1);
    }else{
      this.getConductores(1);
    }
  }

  addRevision(placa: string){
    this.router.navigate(['/add/revision', placa]);
  }

  addMantenimiento(placa: string){
    this.router.navigate(['/add/mantenimiento', placa]);
  }


  getReporte(){
    let desde = '0';
    let hasta = '0';
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
     }
    this.service.getReporte(this.sucursal, desde, hasta);
  }

  getReporteMant(){
    let desde = '0';
    let hasta = '0';
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
     }
    this.service.getReporteMant(this.sucursal, desde, hasta);
  }

  getReporteGeneralRevisiones() {
    let desde = '0';
    let hasta = '0';
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
     }
    this.service.getReporteRevisiones(this.sucursal, desde, hasta);
  }

  getReporteGeneralComparendos() {
    let desde = '0';
    let hasta = '0';
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
     }
    this.service.getReporteComparendos(this.sucursal, desde, hasta);
  }

  getReporteGeneralAccidentes() {
    let desde = '0';
    let hasta = '0';
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
     }
    this.service.getReporteAccidentes(this.sucursal, desde, hasta);
  }

  getReporteGeneralCombustible() {
    let desde = '0';
    let hasta = '0';
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
     }
    this.service.getReporteTanqueo(this.sucursal, desde, hasta);
  }


  showGraph(conductor: Conductor) {

    let desde = '0';
    let hasta = '0';
    if(this.daterangepickerModel && this.daterangepickerModel.length > 0 && this.daterangepickerModel[0] && this.daterangepickerModel[1]  ){
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }

    this.serviceVehiculo.getCombustible(conductor.codigo,0,desde, hasta,0).subscribe(res => {
        const initialState = {
          conductor: conductor,
          consumos: res.consumos,
          desde: desde,
          hasta:hasta
       };
      this.bsModalRef = this.modal.show(GraficaConductorComponent, {initialState: initialState , class: 'modal-800'} );
    });



  }

}
