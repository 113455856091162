

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { WaitService } from '../services/wait.service';

@Injectable({
  providedIn: 'root'
})
export class NgHttpService {
  server: string;
  constructor(private http: HttpClient, private wait: WaitService) {
    // remoto
    // this.server = '/restapi/v1/';

  //  local remoto
       //this.server = 'https://samsales.com.co/restapi/v1/';
      this.server = 'https://back.distribucioneshicar.com/v1/';
       //this.server = 'https://app.distribucioneshicar.com.co/restapi/v1/';
    //local
     //this.server = 'http://localhost:8888/hicar_php/restapi/v1/';
     //this.server = 'http://localhost/hicar_php/restapi/v1/';

   //hostdime
   // this.server = "http://hicar.serviya.com.co/restapi/v1/";

   }

public get = (url: string): Observable<any> => {
  return this.http.get(this.server + url);
 // return this.http.get('http://localhost:8090/saludo');
}
public getData = (url: string, data: any): Observable<any> => {
  return this.http.get(this.server + url, data);
 // return this.http.get('http://localhost:8090/saludo');
}

public post = (url: string, data: any): Observable<any> => {
  return this.http.post(this.server + url, data);
}

public put =  (url: string, data: any): Observable<any> => {
  return this.http.put(this.server + url, data);
}
public patch =  (url: string, data: any): Observable<any> => {
  return this.http.patch(this.server + url, data);
}

public delete =  (url: string): Observable<any> => {
  return this.http.delete(this.server + url);
}


public dowloadExcel = (url: string, filename: string) => {
  this.http.get(this.server + url, {responseType: 'blob' as 'json'} ).subscribe( (response: any) => {
    const blob = new Blob([response ], {type: 'application/ms-excel; charset=utf-8'});
    fileSaver.saveAs(blob, filename + '.xls');

  } );
}

}
