//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//import { THIS_EXPR } from '@angular/compiler/src/';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VehiculoService } from '../consulta-vehiculo/vehiculo.service';
import { SesionService } from '../core/services/sesion.service';
import { Revision } from '../models/revision';
import { revisionCategoria } from '../models/revision-categoria';
import { SelectOption } from '../models/select-option';
import { tipoRevision } from '../models/tipo-revision';
import { usuario } from '../models/usuario';
import { Vehiculo } from '../models/vehiculo';
import { ModalMensajeService } from '../share/modal-mensaje/modal-mensaje.service';
import { FirmaComponent } from './firma/firma.component';
import { RevisionService } from './revision.service';

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.css']
})
export class RevisionComponent implements OnInit {
  public sub: any;
  public vehiculo: Vehiculo;

  public placavehiculo: string;
  public error: string;
  public error2: string;

  public codrevision: number;
  public revision: Revision;
  public categorias: revisionCategoria[];
  public estados: SelectOption[];
  public categoria: revisionCategoria;
  public tipos: tipoRevision[]=[];
  public tipo: tipoRevision;

  public kilometraje: number;
  public kilometrajeant: number = 0;
  public maxKM: number = 0;
  public conductor: string;
  public observacion: string;

  public currentPage: number = 1;
  public npag :number = 1;

  public estado: boolean;
  public proceso: number;
  bsModalRef: BsModalRef;

  public reset: boolean = false;

  public sesion: usuario;

  constructor(private service: RevisionService,
              private route: ActivatedRoute,
              private router: Router,
              private servicevehiculo: VehiculoService,
              private modalService: BsModalService,
              private sesions: SesionService,
              private mensaje: ModalMensajeService) {

                this.error = '';
                this.error2 = '';
                this.codrevision =0;
                this.clearRevision();
                this.categorias = [];
                this.sub = this.route.params.subscribe(params => {

                  if(params.revision){
                    this.codrevision = parseInt(params.revision);
                  }else{
                    this.codrevision=0;
                   // this.clearRevision();
                  }

                  if(params.placa) {

                    this.placavehiculo =params.placa;
                    this.consultarVehiculo();
                  }



                 });

                 this.sesions.select$().subscribe( s=> this.sesion = s);

               }

  ngOnInit(): void {
      if(!this.sesions.validOpcion(3)) {
        this.router.navigate(['/inicio']);
      }
  }

clearRevision() {
  this.revision = {
    codigo: 0,
    vehiculo: null,
    conductor: '',
    kilometraje: null,
    observacion: '',
    tipo: 0,
    estado:1
  }
  this.proceso = 0;
}

onSelectConductor(data: any): void {
  this.revision.conductor = data.codigo;
}

  ini(){

    this.service.ini(this.codrevision, this.vehiculo.codigo).subscribe(result => {
      this.categorias = result.items;
      this.estados = result.estados;
      this.kilometrajeant = result.km;
      this.maxKM = result.maxkm;
      this.tipos = result.tipos;
      if(this.codrevision !==0) {
        this.revision = result.revision;
        this.estado = this.revision.estado == 1;
        this.proceso=1;
        this.setTipo();
      } else {
        this.clearRevision();
        this.estado = true;
        this.proceso = 0;
      }
      this.getPag();
      this.setPage(1);
    });
  }

  consultarPlaca(){
    if(!this.placavehiculo || this.placavehiculo.trim() == '') {
      this.error='Ingrese la placa del vehículo a consultar.';
      return;
    }else{
      this.error ='';
    }
    this.router.navigate(['/add/revision',this.placavehiculo]);
  }

  consultarVehiculo(){
    if(!this.placavehiculo || this.placavehiculo.trim() == '') {
      this.error='Ingrese la placa del vehículo a consultar.';
      return;
    }else{
      this.error ='';
    }
    this.servicevehiculo.getVehiculo(this.placavehiculo).subscribe(result => {
       this.vehiculo = result.vehiculo;
       if(this.vehiculo.revisionpendiente > 0 && this.codrevision == 0) {
          this.codrevision = this.vehiculo.revisionpendiente;
       }

       this.ini();
    });
}

setTipo(){
  this.tipos.forEach(t => {
    if(t.codigo == this.revision.tipo){
      this.tipo = t;
    }
  });
}

getCategoriasItems(){
 this.setTipo();
  this.service.getCategoriaItems(this.codrevision, this.revision.tipo).subscribe(result => {
      this.categorias = result.items;
  });
}


getPag(){

  this.npag = 1;
  let aux = 1;
  let key = true;
  if(this.revision && this.revision.codigo !== 0){

    if(this.revision.tipo && this.revision.tipo > 0){

      if(this.tipo && this.tipo.agrupar == 1) {
        this.categorias.forEach(element => {
          if(key) {
            if(element.estado == 1) {
              aux++;
           } else {
             aux++;
             key = false;
           }
          }
        });
      }else{
        aux++;
      }

    }

  }
  this.npag = aux;
}

validar(){
let aux='';
   if(!this.revision.tipo || this.revision.tipo==0) {
     this.error2='Seleccione un tipo de revisión.';
     return false;
   }

  if(!this.revision.kilometraje) {
    aux = "Kilometraje, ";
  }else{

    if( this.revision.kilometraje < this.kilometrajeant) {
      this.error2 = 'Ingrese un kilometraje mayor o igual que el ultimo registrado.';
      return false;
    } else {
      if(this.maxKM > 0 && this.revision.kilometraje > this.maxKM) {
        this.error2 = 'Ingrese un kilometraje menor o igual que el kilometraje de la siguiente revisión.';
        return false;
      }
      this.error2 ='';
    }
  }

  if(!this.revision.conductor) {
    aux +="conductor, ";
  }
  if(aux!=='') {
    this.error2 ="Debe diligenciar todos los campos. Falta ("+aux+")";
    return false;
  }else{
    this.error2 = "";
    return true;
  }
}


validarCat(categoria: revisionCategoria){
  let aux='';
  categoria.items.forEach(item => {
    let t = parseInt(item.tipo.toString())
    switch(t){
      case 1:{
        if(!item.estado){
          aux += item.descripcion+', ';
        }
      break}
      case 3:{
        if(item.valor !== 0 && item.valor !==1) {
          aux += item.descripcion+', ';
        }
      break;}
      case 4:{
        if(!item.observacion || item.observacion.trim() =='') {
          aux += item.descripcion+', ';
        }
      break;}
      default:{
        if(!item.valor) {
          aux += item.descripcion+', ';
        }
      break;}
    }
  });

  if(aux!=='') {
    this.error2 ="Debe diligenciar todos los campos. Falta ("+aux+")";
    return false;
  }else{
    this.error2 = '';
    return true;
  }
}

validarCats(categorias: revisionCategoria[]){
  this.error2='';
   categorias.forEach(element => {
      if(this.error2=='' && !this.validarCat(element)){
        return false;
      }
   });

   return this.error2=='';
}


guardar(){
  if(this.currentPage == 1) {
      this.registrarRevision();
  }else{
    this.registrarCategoriaRevision();
  }
}

update(){
  if(this.currentPage == 1) {
     this.updateRevision();
  }else{
     this.updateCategoriaRevision();
  }
}

registrarRevision() {
    if(!this.validar()) {return;}

  if(this.revision.kilometraje - this.kilometrajeant > 200) {
    this.mensaje.confirmMensaje('¿ La diferencia de kilometros con relación a la última revisión es mayor de 200 KM, Seguro que quiere continuar.?').subscribe(arg => {
      if(arg) {
         this.confirmRegistrarRevision();
      }
   });
  }else{

    if(this.reset) {
      this.mensaje.confirmMensaje('¿ Realmente quiere reiniciar el tacometro del vehículo, Seguro que quiere continuar.?').subscribe(arg => {
        if(arg) {
           this.confirmRegistrarRevision();
        }
     });
    } else {
      this.confirmRegistrarRevision();
    }

  }


}

confirmRegistrarRevision() {
  this.revision.vehiculo = this.vehiculo.codigo;

  if (this.kilometrajeant == 0) {
    this.revision.km = 0;
  } else {
    this.revision.km = this.revision.kilometraje - this.kilometrajeant;
  }

  this.revision.mantenimiento=0;
  this.revision.estado = 1;
   this.service.insertRevision(this.revision).subscribe(result => {
       if(result.estado){
         this.revision = result.revision;
         this.router.navigate(['/add/revision', { 'revision': this.revision.codigo,'placa': this.vehiculo.placa}  ]);
       }else{
        this.mensaje.showMensaje('No se pudo realizar la operación.','error');
       }
   });
}

updateRevision() {
  if(!this.validar()) { return;}

  if(this.reset) {
    this.mensaje.confirmMensaje('¿ Realmente quiere reiniciar el tacometro del vehículo, Seguro que quiere continuar.?').subscribe(arg => {
      if(arg) {
         this.confirUpdate();
      }
   });
  } else {
    this.confirUpdate();
  }

}

confirUpdate(){
  this.revision.vehiculo = this.vehiculo.codigo;
  this.revision.km = this.reset ? this.revision.kilometraje : this.revision.kilometraje - this.kilometrajeant;
  this.service.updateRevision(this.revision).subscribe(result => {
      if(result.estado){
        this.revision = result.revision;
        this.getPag();
        this.setPage(2);
      }else{
        this.mensaje.showMensaje('No se pudo realizar la operación.','error');
      }
  });

}
registrarCategoriaRevision() {

   let categorias: revisionCategoria[]=[];
    if(this.tipo.agrupar==1) {
      if(!this.validarCat(this.categoria)) {return;}
      this.categoria.revision = this.revision.codigo;
      this.categoria.items.forEach( item => {
        item.revision = this.revision.codigo;
      });
      categorias.push(this.categoria);
    } else {
      categorias = this.categorias;
      if(!this.validarCats(categorias)) {return;}
      this.error2='';
      categorias.forEach(element => {
        element.revision=this.revision.codigo;
        element.items.forEach( item => {
          item.revision = this.revision.codigo;
        });
      });
    }


 this.service.insertRevisionCategoria(categorias).subscribe(result => {
     if(result.estado){

      if(this.tipo.agrupar==1) {
        this.categorias[this.currentPage-2] = result.categorias[0];
        this.getPag();

         if(this.currentPage +1 > this.categorias.length + 1) {
         // this.mensaje.showMensaje('Revisión registrada exitosamente.','info');
          this.service.endrevision(this.revision.codigo).subscribe( result => {
            this.revision.estado=0;
            this.estado = false;
            this.mensaje.confirmMensaje('Revisión registrada exitosamente.<br>¿ Desea firmar la revisión ?').subscribe(arg => {
              if(arg){
               //OPEN FIRMAR
               this.firmar(this.revision.codigo);
              }
              this.goBack();
            });
          });
         }else{
          this.setPage(this.currentPage +1);
         }

      }else{

        this.categorias = result.categorias;
        this.revision.estado=0;
        this.estado = false;

        this.service.endrevision(this.revision.codigo).subscribe( result => {
        this.mensaje.confirmMensaje('Revisión registrada exitosamente.<br>¿ Desea firmar la revisión ?').subscribe(arg => {
          if(arg){
            //OPEN FIRMAR
            this.firmar(this.revision.codigo);
           }
           this.goBack();
           });

          });
      }

     }else{
      this.mensaje.showMensaje('No se pudo realizar la operación.','error');
     }
 });

}


updateCategoriaRevision(){

  if(!this.categoria && this.categorias.length > 0) {
    this.categoria = this.categorias[0];
  }

  if(!this.validarCat(this.categoria)) {return;}
  this.categoria.revision = this.revision.codigo;

 this.service.updateRevisionCategoria(this.categoria).subscribe(result => {
     if(result.estado){
      this.categorias[this.currentPage-2] = result.categoria;
      this.getPag();

       if(this.currentPage +1 > this.categorias.length + 1) {
        //this.mensaje.showMensaje('Revisión registrada exitosamente.','info');
        this.service.endrevision(this.revision.codigo).subscribe( result => {
          // this.router.navigate(['/consultavehiculo',this.vehiculo.placa]);
          this.mensaje.confirmMensaje('Revisión registrada exitosamente.<br>¿ Desea firmar la revisión ?').subscribe(arg => {
            if(arg){
              //OPEN FIRMAR
              this.firmar(this.revision.codigo);
             }
             this.goBack();

          });
        });
       }else{
        this.setPage(this.currentPage +1);
       }

     }else{
      this.mensaje.showMensaje('No se pudo realizar la operación.','error');
     }
 });

}

goBack(){
  this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/3']);
}
descartar(){
   this.kilometraje=null;
   this.conductor='';

   this.categorias.forEach(cat => {
    cat.items.forEach(item => {
      item.estado=null;
    });
  });
  this.error='';
}


delete(){
  this.mensaje.confirmMensaje('¿ Realmente quiere eliminar la revisión ?').subscribe(arg => {
    if(arg){
      this.service.deleteRevision(this.revision.codigo).subscribe(result => {
        this.goBack();
      });
    }
  });

}

pageChanged(event: any): void {
  if( event.page > 1 && !this.validar()) {
    event.page=1;
  }
  this.setPage(event.page);
}


setPage(i: number){
 this.proceso=0;
  if(i == 1) {
    this.categoria = null;
    this.proceso = this.revision.codigo == 0? 0 : 1;
  } else {
    if(this.tipo.agrupar == 1) {
      this.categoria = this.categorias[i -2];
      this.proceso = this.categoria.estado;
    }else{
      this.proceso = this.categorias[0].estado;
      //this.categoria = this.categorias[0];
    }
  }
  setTimeout(()=>  this.currentPage = i , 10);
}

upImgResult(data:any){
  //this.ini();
  if(data.estado) {
    if(this.tipo.imgcategoria==1){
      this.categoria.imagenes = data.imagenes;
    }else{
      this.revision.imagenes = data.imagenes;
    }

  }else{
    this.mensaje.showMensaje(data.mensaje,'error');
  }

}


firmar(mov: number){
  const initialState = {
    revision: mov,
    placa: this.vehiculo.placa
   };
  this.bsModalRef = this.modalService.show(FirmaComponent, { initialState , class: 'modal-700'} );
}

getImgEstado(codigo: any, estado: any) {
  estado = parseInt(estado);
  switch(parseInt(codigo)) {
      case 1:{
      return estado == codigo? 'assets/GENERAL/block.png': 'assets/GENERAL/block0.png';
      }
      case 2:{
        return estado == codigo? 'assets/GENERAL/uncheck.png': 'assets/GENERAL/uncheck0.png';
      }
      case 3:{
        return estado == codigo? 'assets/GENERAL/regular.png': 'assets/GENERAL/regular0.png';
      }
      case 4:{
        return estado == codigo? 'assets/GENERAL/ok2.png': 'assets/GENERAL/ok20.png';
      }
  }
}

 seleccionarTodosCat(index: number, tipo: any, value: any){

  switch(parseInt(tipo)) {
    case 1 :{
      this.categorias[index].items.forEach(element => {
        console.log('tipo 1',element);
        if(element.tipo == tipo) {
         element.estado = value;
        }
     });
     break;
    }
    case 3 :{
      this.categorias[index].items.forEach(element => {
        console.log('tipo 3',element);
        if(element.tipo == tipo) {
         element.valor = value;
        }
     });
     break;
    }
  }

 }
}
