import { IfStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConsultaMantenimientoComponent } from '../consulta-vehiculo/consulta-mantenimiento/consulta-mantenimiento.component';
import { VehiculoService } from '../consulta-vehiculo/vehiculo.service';
import { SesionService } from '../core/services/sesion.service';
import { UtilService } from '../core/services/util.service';
import { Imagen } from '../models/imagen';
import { Mantenimiento, ServicioMantenimiento } from '../models/mantenimiento';
import { SelectOption } from '../models/select-option';
import { Vehiculo } from '../models/vehiculo';
import { ModalMensajeService } from '../share/modal-mensaje/modal-mensaje.service';
import { MantenimientoService } from './mantenimiento.service';

@Component({
  selector: 'app-mantenimiento',
  templateUrl: './mantenimiento.component.html',
  styleUrls: ['./mantenimiento.component.css']
})
export class MantenimientoComponent implements OnInit {

  public tractive: number;
  public sub: any;
  public bsConfig = { dateInputFormat: 'YYYY/MM/DD',
  containerClass: 'theme-default',
  isAnimated: false,
  adaptivePosition: true };
  maxDate = new Date();

  public vehiculo: Vehiculo;

  public placavehiculo: string;
  public error: string;
  public error2: string;

  public categorias: SelectOption[];
  public servicioscategoria: SelectOption[]=[];
  public servicios: SelectOption[];
  public tipos: SelectOption[]=[];
  public proveedores: SelectOption[] =[];

  public mantservicios: ServicioMantenimiento[]=[];
  public newservicio: ServicioMantenimiento;
  public categoria: number;

  public estado: boolean = true;



  public fecha = new Date();
  public fechaMantenimiento= new Date();
  public problema: string;
  public observacion: string;
  public mecanico: string;
  public conductor: number;
  public nombreconductor: string;
  public kilometrajeant : number;
  public maxKilometraje: number= 0;
  public kilometraje: number;
  public tipo: number;
  public codigo: number = 0;
  public isUpdate: boolean;


  bsModalRef: BsModalRef;
  public imagenes: Imagen[]=[];
  public upimagen: boolean;

  constructor(private service: MantenimientoService,
    private route: ActivatedRoute,
    private router: Router,
    private servicevehiculo: VehiculoService,
    private util: UtilService,
    private modal: BsModalService,
    private sesions: SesionService,
    private mensaje: ModalMensajeService) {

      this.error = '';
      this.error2 = '';
      this.newservicio = {
        mantenimiento: 0,
        servicio: 0,
        serviciodescripcion: '',
        observacion: '',
        valorunidad: null,
        cantidad: 1
       }

      this.sub = this.route.params.subscribe(params => {
        if(params.placa) {
          this.placavehiculo =params.placa ;
          this.codigo = params.codigo;
          this.isUpdate =  this.codigo > 0;
          this.consultarVehiculo();
        }
       });

     }

  ngOnInit(): void {
    this.service.ini().subscribe(result => {
      this.categorias = result.categorias;
      result.servicios.forEach(element => {
        element.estado=1;
      });
      this.servicios = result.servicios;
      this.tipos = result.tipos;
      this.proveedores = result.proveedores;
    });

    if(!this.sesions.validOpcion(4)) {
      this.router.navigate(['/inicio']);
    }

    if(this.isUpdate && this.sesions.sesion.perfil !=1){
      this.router.navigate(['/inicio']);
    }
  }

  getServiciosCat() {
    this.servicioscategoria = [];
   this.servicios.forEach(element => {
       if(element.aux2 == this.categoria && element.estado !== 0) {
         this.servicioscategoria.push(element);
       }
   });
   this.newservicio.servicio=null;
  }

  consultarPlaca(){
    if(!this.placavehiculo || this.placavehiculo.trim() == '') {
      this.error='Ingrese la placa del vehículo a consultar.';
      return;
    }else{
      this.error ='';
    }

    //this.router.navigate(['/add/mantenimiento',this.placavehiculo]);

  }


  consultarVehiculo() {
    if(!this.placavehiculo || this.placavehiculo.trim() == '') {
      this.error='Ingrese la placa del vehículo a consultar.';
      return;
    }else{
      this.error ='';
    }
    this.servicevehiculo.getVehiculoKM(this.placavehiculo).subscribe(result => {
       this.vehiculo = result.vehiculo;
       this.kilometrajeant = result.km;

       if(this.isUpdate){
          this.service.getMantenimiento(this.codigo).subscribe( mant => {
             this.setMantenimiento(mant);
          });
        }

    });
}

setMantenimiento(mantenimiento:any) {
    this.conductor = mantenimiento.conductor;
    this.nombreconductor = mantenimiento.nombreconductor;
    this.tipo = mantenimiento.tipo;
    var aux = new Date(mantenimiento.fecha)
    aux.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles'})
    this.fechaMantenimiento = aux;
    this.problema = mantenimiento.problema;
    this.observacion = mantenimiento. observacion;
    this.mecanico = mantenimiento.mecanico;
    this.mantservicios = mantenimiento.servicios;
    //ultkm: !this.kilometrajeant ? 0 : this.kilometrajeant,
    this.kilometraje = mantenimiento.km;
    this.imagenes = mantenimiento.imagenes;
    this.kilometrajeant = mantenimiento.kilometrajeant;
    this.maxKilometraje = mantenimiento.kilometrajepost;

}

addServicio(){
  if(!this.newservicio.servicio) {
    this.error2 = 'Seleccione un servicio para agregar al mantenimiento.';
    return;
  }else{
    this.error2 ='';
  }

  if( !this.newservicio.proveedor || this.newservicio.proveedor < 0) {
    this.error2 = 'Seleccione un proveedor.';
    return;
  }

  if( !this.newservicio.numfactura || this.newservicio.numfactura.trim() == '' ) {
    this.error2 = 'Ingrese el número de la factura del servicio.';
    return;
  }

  if( !this.newservicio.valorunidad || this.newservicio.valorunidad < 0) {
    this.error2 = 'Ingrese un valor numérico.';
    return;
  }

  this.servicios.forEach(element => {
     if(element.codigo == this.newservicio.servicio) {
       this.newservicio.serviciodescripcion = element.descripcion;
     }
  });


  if(this.newservicio.mantenimiento == 0) {
    this.newservicio.mantenimiento = 1;
    this.mantservicios.push(this.newservicio);
  }else{
    this.mantservicios.forEach(element => {
       if(element.servicio == this.newservicio.servicio) {
         element.valorunidad = this.newservicio.valorunidad;
         element.observacion = this.newservicio.observacion;
       }
    });
  }

  this.descartarServicio();
}

modServicio(servicio: ServicioMantenimiento) {
  let cat =0;
  this.servicios.forEach(element => {
    if(element.codigo == servicio.servicio) {
      element.estado=1;
      cat = element.aux2;
    }
  });

  this.categoria = cat;
  this.getServiciosCat();

  this.newservicio =JSON.parse(JSON.stringify(servicio));
}

deleteServicio(index: number){
  this.servicios.forEach(element => {
    if(element.codigo == this.mantservicios[index].servicio) {
       element.estado = 0;
    }
  });

  this.mantservicios.splice(index, 1);
  this.descartarServicio();
  this.getServiciosCat();
}

validar(){

  if (!this.tipo) {
    this.error ='Seleccione el tipo de mantenimiento.';
    return false;
  }

  if (!this.fecha) {
    this.error ='Seleccione la fecha del mantenimiento.';
    return false;
  }

  if (!this.conductor) {
    this.error ='Ingrese el conductor del vehiculolo.';
    return false;
  }

  if (!this.mecanico || this.mecanico.trim() =='') {
    this.error ='Ingrese el nombre del mecánico.';
    return false;
  }

  if(!this.kilometraje) {
    this.error ='Ingrese el kilometraje actual del vehiculo.';
    return false;
  }else{
    if(this.kilometraje < this.kilometrajeant) {
      this.error2 = 'Ingrese un kilometraje mayor o igual que el ultimo registrado.';
      return false;
    }

    if(this.maxKilometraje > 0 &&  this.kilometraje >= this.maxKilometraje) {
      this.error2 = 'Esta actualizando el mantenimiento, no puede ingresar un kilmetraje mayor que el de la siguiente revisión ('+this.maxKilometraje+' km).';
      return false;
    }

  }



  if(this.mantservicios.length == 0) {
    this.error ='Agrege por lo menos un servicio al mantenimiento.';
    return false;
  }

this.error ='';
return true;
}

onSelectConductor(data: any): void {
  this.conductor = data.codigo;
  //this.formGroup.controls.nombreconductor.setValue(data.nombre);
}

registrarMantenimiento(){

  if(!this.validar()){
    return;
  }


  //const difKm = this.kilometraje - this.kilometrajeant??0;
  //if(difKm > )

  let total =0;
  this.mantservicios.forEach(element => {
    total = total + (parseFloat( element.valorunidad.toString() ) * element.cantidad) ;
  });


  let mantenimiento: Mantenimiento = {
    codigo: this.codigo ,
    vehiculo:this.vehiculo.codigo,
    conductor: this.conductor,
    tipo: this.tipo,
    fecha: this.util.formatDate(this.fechaMantenimiento),
    problema: !this.problema ? '' : this.problema,
    observacion: !this.observacion ? '' : this.observacion,
    mecanico: this.mecanico,
    valor: total,
    servicios: this.mantservicios,
    ultkm: !this.kilometrajeant ? 0 : this.kilometrajeant,
    km: this.kilometraje

  }

 this.service.registrarMantenimiento(mantenimiento).subscribe(result => {
   if(result.estado) {
    //this.descartar();
    this.estado=false;
    this.codigo = result.mantenimiento.codigo;

    if(!this.isUpdate){
      this.mensaje.confirmMensaje('Mantenimiento registrado exitosamente.<br><br>¿ Quiere subir imágenes de las facturas ?').subscribe(arg => {
        if(arg){
          this.upimagen = !this.upimagen;
        } else {
          this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/4']);
        }
      });
    } else {

      this.mensaje.showMensaje('Mantenimiento actualizado exitosamente.','info');
      this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/4']);
    }



   //
  }else{
   this.mensaje.showMensaje('No se pudo realizar la operación.','error');
  }
 });

}

detalleMantenimiento(man: Mantenimiento) {
  const initialState = {
    mantenimiento: man,
    vehiculo: this.vehiculo,
    upimagen: true
   };
  this.bsModalRef = this.modal.show(ConsultaMantenimientoComponent,{initialState: initialState, class:'modal-700'});
}


descartar(){
  if(this.isUpdate){
    this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/4']);
  }else{
    this.fecha = new Date();
    this.problema='';
    this.observacion='';
    this.mecanico='';
    this.tipo=null;
    this.codigo=null;
    this.mantservicios=[];
    this.estado = true;
    this.descartarServicio();
  }

}

descartarServicio(){
  this.newservicio = {
    mantenimiento: 0,
    servicio: null,
    serviciodescripcion: '',
    observacion: ''
   }

  this.servicioscategoria = [];

  this.servicios.forEach(element => {
    element.estado = 1;
  });

  this.servicios.forEach(element => {
    this.mantservicios.forEach(element2 => {
      if(element.codigo == element2.servicio) {
        element.estado = 0;
      }
    });
  });
}

validCero(){

}


upImgResult(data:any){
  //this.ini();
  if(data.estado) {
    this.imagenes = data.imagenes;
  }else{
    this.mensaje.showMensaje(data.mensaje,'error');
  }

}

}
