import { Injectable } from '@angular/core';
import { NgHttpService } from '../core/http/ng-http.service';
import { Accidente } from '../models/accidente';

@Injectable({
  providedIn: 'root'
})
export class AccidenteService {

  constructor(private http: NgHttpService) { }


  insertAccidente(accidente: Accidente){
    return this.http.post('accidente', accidente);
  }

  getImagenes(codigo: number) {
     return this.http.get('accidentes/'+codigo+'/imagenes');
  }

  getAccidente(codigo: number){
    return this.http.get('accidente/'+codigo);
  }
}
