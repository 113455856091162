import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SelectOption } from 'src/app/models/select-option';
import { usuario } from 'src/app/models/usuario';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdministracionService } from '../../administracion.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  public usuarios: usuario[]=[];
  public error: string;
  public tractive: number;

  public perfil: number;
  public estado: number;
  public perfiles: SelectOption[];
  public estados: SelectOption[]=[];
  public currentPage: number =1;
  public totalitem: number=0;
  public itemPag:number = 25;

  constructor(private service: AdministracionService,
    private mensaje: ModalMensajeService,
    private sesions: SesionService,
    private router: Router) {
      this.error ='';
      this.perfil=0;
      this.estado=-1;
      this.perfiles=[];
      this.estados=[
                    {codigo:1,descripcion:'Activo'},
                    {codigo:0,descripcion:'Inactivo'},
                    {codigo:-1,descripcion:'Todos'}
                  ];
     }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(15)) {
      this.router.navigate(['/inicio']);
    }

    this.service.iniUsuarios().subscribe(r => {
      this.perfiles=r.perfiles;
      this.perfiles.push({codigo:0, descripcion:'Todos'})
      this.filtrarUsusarios();
    });

  }

  filtrarUsusarios(){
    this.service.getUsuarios(this.perfil ?? 0,this.estado ?? -1,this.currentPage, this.itemPag).subscribe(result => {
      this.usuarios = result.usuarios;
      this.totalitem = result.total
     });
  }

  goToUser(codigo: number) {
    this.router.navigate(['/administracion/usuario', codigo] );
  }
  newUser(){
    this.router.navigate(['/administracion/usuarios/add'] );
  }
  pageChanged(event:any){
    this.currentPage = event.page;
    this.filtrarUsusarios();
  }
  onPagesLength(){
    this.currentPage=1;
    this.filtrarUsusarios();
  }
}
