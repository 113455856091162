<div class="row mb-5" style="max-width: 600px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Administrar Servicios Recurrentes</h3></div>

<section>

  <section *ngIf="serviciosR.length > 0">
  <div class="cav-radius mt-1"></div>
  <div class="table-responsive" >

     <table class="table" style="border: solid 1px #cccccc;">
      <thead class="thead-dark small">
       <tr class="bg-info text-white" >
        <th scope="col" >COD.</th>
          <th scope="col" class="text-left">SERVICIO</th>
          <th scope="col" class="text-left">MEDIDA</th>
          <th scope="col" class="text-left">Km/Días</th>
          <th scope="col" class="text-left one-line">Alerta</th>
          <th scope="col" ></th>
          <th scope="col" ></th>
       </tr>
      </thead>
       <tbody>
       <tr *ngFor="let item of serviciosR ;let i = index; let impar = odd;let par = even;"
        [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive, 'red': item.estado==0}"
        title="Usuario:{{item.usuariosisnombre}}  Fecha: {{item.fecha}}"
        (click)="tractive = i" class="small" >
        <td>{{item.codigo}}</td>
        <td class="text-left">{{item.descripcion}}</td>
        <td class="one-line">{{ item.tipo == 1 ?  'Kilometraje': 'Tiempo'}}</td>
         <td class="one-line">{{item.tipo == 1? (item.kilometraje  | number) +' Km.' : item.dias+' Dias' }} </td>
         <td class="one-line">{{item.tipo == 1? (item.kmnotificar  | number) +' Km.' : item.diasnotificar+' Días' }}</td>
         <td><img src="assets/GENERAL/mod.png" (click)="modServicio(item)" width="30"></td>
         <td><img src="assets/GENERAL/delete.png"  (click)="deleteServicio(item.codigo)" width="30"></td>
       </tr>

      </tbody>
     </table>

 </div>
</section>


 <div class="row mt-5" style="border:solid 1px #cccccc;border-radius: 10px;padding: 10px;">
  <div class="col col-12 mb-3 text-left"><b>Agregar Nuevo Servicio Recurrente</b></div>

  <div class="col col-12 text-left">
    <label for="fecha" class="col-form-label " >Categoría</label>
    <select class="custom-select" [disabled]="newservicio.estado == 1" [(ngModel)]="categoria" (change)="getServiciosCat()" >
      <option *ngFor=" let item of categorias" [ngValue]="item.codigo" >{{item.descripcion}}</option>
    </select>
  </div>
  <div class="col col-12 text-left">
    <label for="fecha" class="col-form-label " >Servicio</label>
    <select class="custom-select" [disabled]="newservicio.estado == 1"  [(ngModel)]="newservicio.codigo" >
      <option *ngFor=" let item of servicioscategoria" [ngValue]="item.codigo" >{{item.descripcion}}</option>
    </select>
  </div>


  <div class="col col-12 text-left">
    <label for="fecha" class="col-form-label " >Tipo Medición</label>
    <select class="custom-select"  [(ngModel)]="newservicio.tipo" >
      <option *ngFor=" let item of tipos" [ngValue]="item.codigo" >{{item.descripcion}}</option>
    </select>
  </div>


  <div  *ngIf="newservicio.tipo==1" class="col col-6 text-left">
    <label for="fecha" class="col-form-label " >Kilometraje</label>
    <input  type="number" class="form-control" [(ngModel)]="newservicio.kilometraje" name="id" placeholder="Km">
  </div>

  <div  *ngIf="newservicio.tipo==1" class="col col-6 text-left">
    <label for="fecha" class="col-form-label " >Kilometraje Alerta</label>
    <input  type="number" class="form-control" [(ngModel)]="newservicio.kmnotificar" name="id" placeholder="Km">
  </div>



  <div *ngIf="newservicio.tipo==2" class="col col-6 text-left">
    <label for="fecha" class="col-form-label " >Días</label>
    <input  type="number" class="form-control" [(ngModel)]="newservicio.dias" name="id" placeholder="Días">
  </div>

  <div *ngIf="newservicio.tipo==2" class="col col-6 text-left">
    <label for="fecha" class="col-form-label " >Días Alerta</label>
    <input  type="number" class="form-control" [(ngModel)]="newservicio.diasnotificar" name="id" placeholder="Días">
  </div>



</div>

<div  class="row " style="max-width: 600px;margin: 0px auto; ">

  <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error !== '' " >
    {{error}}
  </div>

  <div class="btn-conten mt-5">
  <div  class="butomForm" (click)="insertServicio()" *ngIf="newservicio.estado !== 1">
    <div class="img save"></div>
    <div>Registrar</div>
  </div>

  <div  class="butomForm" *ngIf="newservicio.estado == 1" (click)="updateServicio()">
    <div class="img update"></div>
    <div>Actualizar</div>
  </div>

  <div class="butomForm" (click)="descartarServicio()">
      <div class="img discard"></div>
    <div>Descartar</div>
  </div>
</div>
</div>


</section>

</div>
</div>
