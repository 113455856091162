import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ScrollingService } from "./scrolling.service";

@Injectable({
  providedIn: 'root'
})
export class WaitService {

  public estado: boolean;
  public estado$ = new Subject<boolean>();
  public scrollTop: number;
  public saveScroll: number;

  public disable: boolean;

  constructor(private scroll: ScrollingService) {
    this.scroll.select$().subscribe( result => {
      this.scrollTop = result;
    });

  }

  dispatch(estado: boolean) {
    this.estado = estado;
    this.saveScroll = this.scrollTop;
    this.estado$.next(estado);
  }

  select$() {
    return this.estado$.asObservable();
  }


  show() {
    if (this.disable == true) { return; }
    this.dispatch(true);
  }
  hidden() {
    this.dispatch(false);
  }
}
