<div class="row mb-5" style="max-width: 700px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Tipo Revisión</h3></div>
     <section>


     <div class="row mt-5" style="border:solid 1px #cccccc;border-radius: 10px;padding: 10px;">
      <div class="col col-12 mb-3 text-left"><b>{{ codigo ? 'Modificar Tipo Revisión.' : 'Agregar Nueva Categoría.' }}</b></div>

      <div class="col col-12 text-left">
        <label for="fecha" class="col-form-label " >Descripción Categoría</label>
        <input  type="text" class="form-control" [disabled]="tipo.codigo!==0 && tipo.estado == 0" [(ngModel)]="tipo.descripcion" name="id" placeholder="Descripción tipo">
      </div>
      <div class="col col-12 text-left">
        <label for="fecha" class="col-form-label " >Paginar Items por Categoría ?</label>
        <select class="custom-select"  [(ngModel)]="tipo.agrupar">
          <option  [ngValue]="1" >Si</option>
          <option  [ngValue]="0" >No</option>
        </select>
      </div>


      <div class="col col-12 text-left">
        <label for="fecha" class="col-form-label " >Registro fotográfico</label>
        <select class="custom-select"  [(ngModel)]="tipo.imgcategoria">
          <option  [ngValue]="1" >Subir fotografía por categoría</option>
          <option  [ngValue]="0" >Subir fotografía general</option>
        </select>
      </div>

      <div class="col col-12 text-left">
        <label for="fecha" class="col-form-label "> Observación Categoría</label>
        <select class="custom-select"  [(ngModel)]="tipo.observacioncategoria">
          <option  [ngValue]="1" >Observación por categoría</option>
          <option  [ngValue]="0" >Observación general</option>
        </select>
      </div>


    </div>

<br>
  <div><h5>Selecciona los item de la Revisión:</h5></div>
  <br>
  <div style="border: solid 1px #cccccc; padding: 5px;margin: 5px;border-radius: 5px;" *ngFor="let categoria of tipo.items;" class="text-left">
  <div class="row" style="cursor: default;">
    <div class="col" style="max-width: 20px;"><input [(ngModel)]="categoria.check" (change)="chechAll(categoria); $event.stopPropagation();" [id]="'cat'+categoria.codigo" type="checkbox"></div>
    <div class="col" (click)="categoria.enable = !categoria.enable"  [attr.aria-expanded]="!categoria.enable" aria-controls="collapseBasic"><b>{{categoria.descripcion}} <div style="float: right;">{{ categoria.enable ? '-' : '+' }}</div></b></div>
   </div>
    <div style="padding-left: 20px;" id="collapseBasic" [collapse]="!categoria.enable" [isAnimated]="true">
      <br>
      <div *ngFor="let item of categoria.items;" class="form-group form-check">
        <input [(ngModel)]="item.enable" type="checkbox" class="form-check-input" [id]="item.codigo">
        <label class="form-check-label" [for]="item.codigo">{{item.descripcion}}</label>
      </div>
      <a [routerLink]="['/administracion/revision/item/categoria', categoria.codigo]" routerLinkActive="nav-item-active">+ Item</a>
    </div>
  </div>




    <div  class="row " style="max-width: 600px;margin: 0px auto; ">

      <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error !== '' " >
        {{error}}
      </div>

      <div class="btn-conten mt-5">
      <div *ngIf="!tipo.codigo"  class="butomForm" (click)="insertTipo()" >
        <div class="img save"></div>
        <div>Registrar</div>
      </div>
      <div *ngIf="tipo.codigo"  class="butomForm" (click)="updateTipo()" >
        <div class="img update"></div>
        <div>Actualizar</div>
      </div>

    </div>
    </div>


    </section>

  </div>
</div>
