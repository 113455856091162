import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footpage',
  templateUrl: './footpage.component.html',
  styleUrls: ['./footpage.component.css']
})
export class FootpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
