import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { RevisionService } from '../revision.service';

@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css']
})
export class FirmaComponent implements AfterViewInit {

  public revision: number;
  public placa: string;

  @ViewChild('SignaturePad1') signaturePad1: SignaturePad;
  @ViewChild('SignaturePad2') signaturePad2: SignaturePad;

  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 2,
    'canvasWidth': 600,
    'canvasHeight': 300
  };

  public imagenUrl1: string="";
  public imagenUrl2: string="";
  public error: string ='';

  constructor(public bsModalRef: BsModalRef,
              private service: RevisionService,
              private mensaje: ModalMensajeService,
              private router: Router) {
  }


  ngAfterViewInit(): void {
    // this.signaturePad is now available
    this.signaturePad1.set('minWidth', 2); // set szimek/signature_pad options at runtime
    this.signaturePad1.clear(); // invoke functions from szimek/signature_pad API

    this.signaturePad2.set('minWidth', 2); // set szimek/signature_pad options at runtime
    this.signaturePad2.clear(); // invoke functions from szimek/signature_pad API
  }


  drawComplete(index: number) {
    // will be notified of szimek/signature_pad's onEnd event
    //console.log(this.signaturePad.toDataURL());
    if(index==1) {
      this.imagenUrl1 = this.signaturePad1.toDataURL();
    }else {
      this.imagenUrl2 = this.signaturePad2.toDataURL();
    }

  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }


  enviar(){

    if(this.imagenUrl1.trim() == "" || this.imagenUrl2.trim() == "") {
     this.error = 'Debe dibujar las dos firmas.';
      return;
    }else{
      this.error='';
    }

     let data={
       firmas:[this.imagenUrl1,this.imagenUrl2] ,
       revision: this.revision
     }

     this.service.guardarFirmar(data).subscribe(result => {
       this.mensaje.showMensaje('Revisión firmada exitosamente.','info');
       this.bsModalRef.hide();
       this.router.navigate(['/consultavehiculo',this.placa]);
     });
  }


  clear(index: number){
    if(index==1) {
      this.signaturePad1.clear();
      this.imagenUrl1="";
    }else{
      this.signaturePad2.clear();
      this.imagenUrl2="";
    }

  }

}
