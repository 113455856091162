<div class="row mb-5" style="max-width: 1000px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Nuevo Vehículo</h3></div>




     <div  class="text-left" style="border:solid 1px #cccccc;border-radius: 10px;padding: 30px;">
      <form [formGroup]="formGroup">
      <div class="mb-4 mt-4"><h3>Datos Generales</h3></div>
      <div class="row ">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="placa" class="col col-form-label-sm mb-0 label-f" >Placa:</label>
          <div> <input type="text" class="form-control" formControlName="placa" placeholder="Placa del vehículo" (blur)="consultarVehiculo()"  (keydown.enter)="consultarVehiculo()" ></div>
         </div>
         <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="sucursal" class="col col-form-label-sm label-f">Sucursal:</label>
          <div>
            <select class="custom-select" formControlName="sucursal">
              <option *ngFor=" let item of sucursales" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
          </div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="linea" class="col col-form-label-sm label-f">Linea:</label>
          <div> <input type="text" class="form-control" formControlName="linea" placeholder="Linea del vehículo" ></div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="tipo" class="col col-form-label-sm label-f" >Tipo:</label>
          <div>
            <select class="custom-select" formControlName="tipo">
              <option *ngFor=" let item of tipos" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
           </div>
        </div>

      </div>

      <div class="row">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="marca" class="col col-form-label-sm label-f">Marca:</label>
          <div>
            <select class="custom-select" formControlName="marca">
              <option *ngFor=" let item of marcas" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
          </div>
        </div>

        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="color" class="col col-form-label-sm label-f">Color:</label>
          <div> <input type="text" class="form-control" formControlName="color" placeholder="Color vehículo" ></div>
         </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="numchasis" class="col col-form-label-sm label-f">Num. Chasis:</label>
          <div> <input type="text" class="form-control" formControlName="numchasis" placeholder="Numero chasis vehículo" ></div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="nummotor" class="col col-form-label-sm label-f">Num. Motor:</label>
          <div> <input type="text" class="form-control" formControlName="nummotor" placeholder="Numero de motor vehículo" ></div>
        </div>

      </div>

      <div class="row">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="cilindraje" class="col col-form-label-sm label-f">Cilindraje:</label>
          <div> <input type="text" class="form-control" formControlName="cilindraje" placeholder="Cilindraje vehículo" ></div>
        </div>

        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="uso" class="col col-form-label-sm label-f">Uso:</label>
          <div>
            <select class="custom-select" formControlName="uso">
              <option *ngFor=" let item of usos" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
          </div>
         </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="numlicenciatransito" class="col col-form-label-sm label-f">Num. Lic. Transito:</label>
          <div> <input type="text" class="form-control" formControlName="numlicenciatransito" placeholder="Numero de licencia de transito vehículo" ></div>
        </div>

        <div class="col col-12 col-sm-6 mt-6 mb-6"></div>

        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="fechasoat" class="col col-form-label-sm label-f">Fecha SOAT :</label>
          <div>  <input type="text" class="form-control" formControlName="fechasoat"
            #datepickerYMD="bsDatepicker"
            bsDatepicker
            [bsConfig]="bsConfig"
            placeholder="YYYY/MM/DD"></div>
         </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="fechatecnomecanica" class="col col-form-label-sm label-f">Fecha Tecnomecánica:</label>
          <div> <input type="text" class="form-control" formControlName="fechatecnomecanica"
            #datepickerYMD="bsDatepicker"
            bsDatepicker
            [bsConfig]="bsConfig"
            placeholder="YYYY/MM/DD"></div>
        </div>
      </div>
</form>
<br>
      <div class="row">
        <div class="col col-12 col-sm-12 col-lg-6 col-xl-6 text-left">
          <label for="fecha" class="col-form-label" >Nombre conductor</label>
          <div>
            <input [(ngModel)]="asyncSelected"
            [typeahead]="dataSourceUsuarios"
            (typeaheadLoading)="changeTypeaheadLoading($event)"
            (typeaheadOnSelect)="typeaheadOnSelectUser($event)"
            [typeaheadOptionsLimit]="20"
            typeaheadOptionField="nombre"
            placeholder="Buscar conductor"
            class="form-control search-txt"
            (focus)="clear()"
            >
            <small id="emailHelp" class="form-text text-muted text-left">Conductor actual: {{formGroup.controls.nombreconductor.value}}.</small>
          </div>
         </div>

         <div class="col col-12 col-sm-12 col-lg-6 col-xl-6 text-center" *ngIf="formGroup.controls.codigo.value != 0 ">
          <button   type="button" class="btn btn-warning mr-2" (click)="showResetTacometro()" >
            Resetear Tacometro
          </button>
         </div>


      </div>



      <form [formGroup]="formGroup">
      <div class="mb-3 mt-5"><h3>Motor</h3></div>
      <div class="row ">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="motorvalvulaxclindro" class="col col-form-label-sm label-f">Cant. válvulas por cilindro:</label>
          <div> <input type="text" class="form-control" formControlName="motorvalvulaxclindro" placeholder="Válvulas vehículo" ></div>
         </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="motorcilindros" class="col col-form-label-sm label-f">Cantidad de cilindros:</label>
          <div> <input type="text" class="form-control" formControlName="motorcilindros" placeholder="Numero de cilindros vehículo" ></div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="motorturbo" class="col col-form-label-sm label-f">Turbo:</label>
          <div> <input type="text" class="form-control" formControlName="motorturbo" placeholder="Turbo vehículo" ></div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="motororientacion" class="col col-form-label-sm label-f">Orientación:</label>
          <div> <input type="text" class="form-control" formControlName="motororientacion" placeholder="Orientación del motor vehículo" ></div>
        </div>
      </div>



      <div class="mb-3 mt-5"><h3>Dirección - Transmisión - Suspensión</h3></div>
      <div class="row ">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="tipodireccion" class="col col-form-label-sm label-f">Tipo de dirección:</label>
          <div>
            <select class="custom-select" formControlName="tipodireccion">
              <option *ngFor=" let item of tiposdireccion" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
          </div>
         </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="tipotransmision" class="col col-form-label-sm label-f">Tipo de transmisión:</label>
          <div>
            <select class="custom-select" formControlName="tipotransmision">
              <option *ngFor=" let item of tipostransmision" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
          </div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="numvelocidades" class="col col-form-label-sm label-f">Numero de velocidades:</label>
          <div> <input type="text" class="form-control" formControlName="numvelocidades" placeholder="Velocidades vehículo" ></div>
        </div>
        <div class="col col-12 col-sm-6 mt-3 mb-6">
          <label for="tiporodamiento" class="col col-form-label-sm label-f">Tipo de Rodamientos:</label>
          <div>
            <select class="custom-select" formControlName="tiporodamiento">
              <option *ngFor=" let item of tiposrodamientos" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="suspensiondelantera" class="col col-form-label-sm label-f">Suspensión delantera:</label>
          <div> <input type="text" class="form-control" formControlName="suspensiondelantera" placeholder="Suspensión delantera vehículo" ></div>
         </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="suspensiontrasera" class="col col-form-label-sm label-f">Suspensión trasera:</label>
          <div> <input type="text" class="form-control" formControlName="suspensiontrasera" placeholder="Suspensión trasera vehículo" ></div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="numllantas" class="col col-form-label-sm label-f">Número llantas:</label>
          <div> <input type="text" class="form-control" formControlName="numllantas" placeholder="Numero de llantas vehículo" ></div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="dimensionrines" class="col col-form-label-sm label-f">Dimensión de Rines:</label>
          <div> <input type="text" class="form-control" formControlName="dimensionrines" placeholder="Rines vehículo" ></div>
        </div>
      </div>
      <div class="row ">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="materialrines" class="col col-form-label-sm label-f">Material de rines:</label>
          <div> <input type="text" class="form-control" formControlName="materialrines" placeholder="Rines vehículo" ></div>
         </div>
      </div>



      <div class="mb-3 mt-5"><h3>Frenos</h3></div>
      <div class="row ">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="frenosdelanteros" class="col col-form-label-sm label-f">Tipo de frenos delanteros:</label>
          <div>
            <select class="custom-select" formControlName="frenosdelanteros">
              <option *ngFor=" let item of tiposfreno" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
          </div>
         </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="frenostraseros" class="col col-form-label-sm label-f">Tipo de frenos traseros:</label>
          <div>
            <select class="custom-select" formControlName="frenostraseros">
              <option *ngFor=" let item of tiposfreno" [ngValue]="item.codigo" >{{item.descripcion}}</option>
            </select>
          </div>
        </div>
      </div>




      <div class="mb-3 mt-5"><h3>Carroceria</h3></div>
      <div class="row ">
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="carroceriaserie" class="col col-form-label-sm label-f">Número de Serie:</label>
          <div> <input type="text" class="form-control" formControlName="carroceriaserie" placeholder="Carrocería vehículo" ></div>
         </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="carroceriaventanas" class="col col-form-label-sm label-f">Número de ventanas:</label>
          <div> <input type="text" class="form-control" formControlName="carroceriaventanas" placeholder="Ventanas vehículo" ></div>
        </div>
        <div class="col col-12 col-sm-6 mt-6 mb-6">
          <label for="carroceriacapacidadcarga" class="col col-form-label-sm label-f">Capacidad de carga y/o pasajeros:</label>
          <div> <input type="text" class="form-control" formControlName="carroceriacapacidadcarga" placeholder="Carga vehículo" ></div>
        </div>

      </div>


      <div *ngIf="error !== '' " class="row alert alert-danger p-2 small mt-5">
        {{error}}
      </div>
      <div class="row mt-5">

        <div class="btn-conten" >
        <div class="butomForm" *ngIf="formGroup.controls.codigo.value == 0" (click)="save()" >
          <div class="img save"></div>
          <div>Guardar</div>
        </div>

        <div class="butomForm" *ngIf="formGroup.controls.codigo.value !== 0" (click)="save()">
          <div class="img update"></div>
          <div>Modificar</div>
        </div>

        <div class="butomForm" (click)="descartar()">
            <div class="img discard"></div>
          <div>Descartar</div>
        </div>
      </div>
      </div>
    </form>

    </div>



  </div>
</div>
