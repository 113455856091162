import { PlatformLocation } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { MantenimientoService } from 'src/app/mantenimiento/mantenimiento.service';
import { Imagen } from 'src/app/models/imagen';
import { Mantenimiento, ServicioMantenimiento } from 'src/app/models/mantenimiento';
import { Vehiculo } from 'src/app/models/vehiculo';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { VehiculoService } from '../vehiculo.service';
import { StyleMantenimiento } from './constant';

@Component({
  selector: 'app-consulta-mantenimiento',
  templateUrl: './consulta-mantenimiento.component.html',
  styleUrls: ['./consulta-mantenimiento.component.css']
})
export class ConsultaMantenimientoComponent implements OnInit, AfterViewInit {

  public tractive: number;
  public suscripM: Subscription[] = [];
  public suscrip: Subscription[] = [];

  public mantenimiento: Mantenimiento;
  public vehiculo: Vehiculo;
  public codvehiculo: number;


  public servicios: ServicioMantenimiento[] = [];
  public imagenes: Imagen[]=[];

  public kmTemp: number;
  public isEditKm: boolean;
  public ultKm : number;

  constructor(
    private router: Router,
    public bsModalRef: BsModalRef,
    private serviceM: ModalService,
    private locations: PlatformLocation,
    private modalService: BsModalService,
    private mensaje: ModalMensajeService,
    public sesions: SesionService,
    private serviceMant: MantenimientoService,
    private service: VehiculoService) {
      this.suscribModal();
      this.serviceM.nmodal = 1;
     }
  ngAfterViewInit(): void {
    this.kmTemp=this.mantenimiento.km;
    if(!this.vehiculo && this.codvehiculo) {
      this.service.getVehiculoCod(this.codvehiculo).subscribe(r => {
        this.vehiculo=r.vehiculo;
        this.getDetalles();
      });
    }else{
      this.getDetalles();
    }
  }

     suscribModal(){
      this.suscripM.push(this.modalService.onHide.subscribe((reason: string) => {
          this.suscripM.forEach(element => {
            element.unsubscribe();
          });
          this.suscrip = [];
          if(reason !== 'BACK') {
            history.back();
          }
       }));
    }

  ngOnInit(): void {
    history.pushState(null, '');
    this.locations.onPopState((event) => {
      // ensure that modal is opened

      if(this.serviceM.nmodal == 1){
        if(this.bsModalRef !== undefined) {
          this.modalService.setDismissReason('BACK');
         // if(!this.mensaje.estado) {
            this.bsModalRef.hide();
          // }else{
          //  this.mensaje.closeAll();
          //}
      }
      }

    });


  }


  ngOnDestroy(): void {
    this.suscrip.forEach(element => {
      element.unsubscribe();
  });
  this.suscripM.forEach(element => {
    element.unsubscribe();
   });
  }


  async getDetalles() {
    const [detall,km] = await forkJoin([this.service.getMantenimientoServicios(this.mantenimiento.codigo),
                                        this.service.getUltmKmMantenimiento(this.mantenimiento.codigo, this.vehiculo.codigo) ]).toPromise();

                                        this.servicios = detall.servicios;
                                        this.imagenes = detall.imagenes;
                                        this.ultKm  = km.km;

  }

  editKm(key: boolean){
    this.isEditKm = key;
    this.kmTemp = this.mantenimiento.km;
  }

  updateKm() {
    const km = this.kmTemp-this.ultKm;
    let mnj = '';
    /* if(km < 0){
      this.mensaje.showMensaje('Kilometraje actual inferior al kilometraje anterior.','error');
      return false;
    } */

    if(km > 200) {
      this.mensaje.confirmMensaje('¿ La diferencia de kilometros con relación al último mantenimiento es mayor de 200 km, Seguro que quiere continuar.?').subscribe(arg => {
        if(arg) {
          this.confirmUpdateKm();
        }
    });
    }else{
      this.confirmUpdateKm();
    }

  }

  confirmUpdateKm(){
    this.service.updateKmMatenimiento(this.mantenimiento.codigo, this.kmTemp).subscribe(r => {
      if(r.estado) {
        this.mantenimiento.km = this.kmTemp;
        this.editKm(false);
      }
    })
  }

delete(){
  this.mensaje.confirmMensaje('¿Seguro que quiere eliminar el mantenimeinto.?').subscribe(arg => {
     if(arg) {
        this.serviceMant.deleteMantenimiento(this.mantenimiento.codigo).subscribe(result => {
            this.bsModalRef.hide();
            this.mensaje.showMensaje('Mantenimiento eliminado','info');
        });
     }
  });

}

modMantenimiento(){
  this.service.eventPending = true;
  this.bsModalRef.hide();
}


  print(){
  const cav =`<div style="text-align: center"><img height="40px" src="assets/GENERAL/logo.png"></div> <div style="text-align: center"><b>MANTENIMIENTO N° ${this.mantenimiento.codigo}</b></div> `;
  let imgs='';
  this.imagenes.forEach(element => {
    imgs +='<img src="'+element.url+'" width="100%">';
  });

    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
             ${StyleMantenimiento.style}
          </style>
        </head>
    <body onload="window.print();window.close()">
    ${cav}
    ${printContents}
     <br>
     ${imgs}
    </body>
    </html>`
    );
    popupWin.document.close();
  }
}
