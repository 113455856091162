import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SesionService } from '../core/services/sesion.service';
import { TokenStoreService } from '../core/http/token-store.service';
import { usuario } from '../models/usuario';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent implements OnInit {

  private usuario: usuario;
  private username: string;
  private password: string;
  public error: string | null;
  
  constructor(
    private service: LoginService,
    private router: Router,
    private sesion: SesionService,
    private token: TokenStoreService,
    private activeRoute: ActivatedRoute
    ){}

  ngOnInit(): void {

    this.username = this.activeRoute.snapshot.paramMap.get("username");
    this.password = this.activeRoute.snapshot.paramMap.get("password");

    if(this.token.isLogged()) {
      this.router.navigate(['/inicio']);
    }

    this.usuario = {
      codigo: 0,
      username: this.username,
      password: this.password
    }

    this.service.login(this.usuario).subscribe(result => {
      if(!result.estado) {
        this.error = result.mensaje;
        return;
      }

      this.usuario = result.usuario;
      this.token.dispatch(this.usuario.token);
      this.sesion.dispatch(this.usuario);
      this.router.navigate(['/inicio']);
    })

  }

}
