<div class="row mb-5" style="max-width: 750px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Usuarios del Sistema</h3></div>
     <section>

      <div class="form-group row" >
        <label for="sucursal" class="col-sm-2 col-form-label" >Perfil :</label>
        <div class="col-sm-4" >
          <select  class="custom-select" [(ngModel)]="perfil" (change)="filtrarUsusarios()">
            <option *ngFor=" let item of perfiles" [ngValue]="item.codigo" >{{item.descripcion}}</option>
          </select>
        </div>

        <label for="sucursal" class="col-sm-2 col-form-label" >Estado :</label>
        <div class="col-sm-4" >
          <select  class="custom-select" [(ngModel)]="estado" (change)="filtrarUsusarios()">
            <option *ngFor=" let item of estados" [ngValue]="item.codigo" >{{item.descripcion}}</option>
          </select>
        </div>

      </div>


      <section *ngIf="usuarios.length > 0">
        <div class="row mt-5"><div class="col text-right"><button type="button" class="btn btn-warning" (click)="newUser()"  >Nuevo Usuario</button></div>
        </div>
      <div class="cav-radius mt-1"></div>
      <div class="table-responsive" >

         <table class="table" style="border: solid 1px #cccccc;">
          <thead class="thead-dark small">
           <tr class="bg-info text-white small" >
            <th scope="col" class="text-left">ID.</th>
            <th scope="col" class="text-left" >NOMBRE</th>
            <th scope="col" class="text-left">PERFIL</th>
            <th scope="col" class="text-left">USUARIO</th>
            <th scope="col" class="text-left">ESTADO</th>
            <th scope="col" class="text-left"></th>
           </tr>
          </thead>
           <tbody>
           <tr *ngFor="let item of usuarios;let i = index; let impar = odd;let par = even;"
            [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" class="small"
            (click)="tractive = i"  >
            <td>{{item.identificacion}}</td>
            <td class="text-left">{{item.nombre}}</td>
            <td class="text-left">{{item.perfildescripcion}}</td>
            <td class="text-left">{{item.username}}</td>
            <td class="text-left">{{item.estado==1?'Activo':'Inactivo'}}</td>
            <td class="text-left"><img src="assets/GENERAL/mod.png" width="30" (click)="goToUser(item.codigo)"></td>
           </tr>

          </tbody>
         </table>

     </div>
    </section>

    <div class="text-center mt-4">
      <pagination style="display: inline-block" [totalItems]="totalitem" [itemsPerPage]="itemPag" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
      <div class="btn-group" dropdown>
        <select  class="custom-select" [(ngModel)]="itemPag" (change)="onPagesLength()">
          <option [ngValue]="50" >50</option>
          <option [ngValue]="25" >25</option>
          <option [ngValue]="10" >10</option>
        </select>
    </div>

  </div>

    </section>

  </div>
</div>
