import { Injectable } from '@angular/core';
import { NgHttpService } from '../core/http/ng-http.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: NgHttpService) { }

  ini(){
    return this.http.get('home/ini');
  }
  getVehiculos(pag: number, itempag: number, sucursal:number, desde: string, hasta: string) {
    return this.http.get('home/pag/'+pag+'/npag/'+itempag+'/sucursal/'+sucursal+'/desde/'+desde+'/hasta/'+hasta);
  }

  getConductores(pag: number, itempag: number, sucursal:number, desde: string, hasta: string) {
    return this.http.get('home/conductores/pag/'+pag+'/npag/'+itempag+'/sucursal/'+sucursal+'/desde/'+desde+'/hasta/'+hasta);
  }

  getReporte(sucursal: number, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/novedades/sucursal/'+sucursal+'/desde/'+desde+'/hasta/'+hasta,'ReporteNovedades');
  }

  getReporteMant(sucursal: number, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/mantenimientos/sucursal/'+sucursal+'/desde/'+desde+'/hasta/'+hasta,'ReporteMantenimientosSucursal');
  }

  getReporteRevisiones(sucursal: number,desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/revisiones/sucursal/'+sucursal+'/usuario/0/vehiculo/-1/desde/'+desde+'/hasta/'+hasta,'ReporteRevisiones');
  }

  getReporteComparendos(sucursal: number, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/comparendos/sucursal/'+sucursal+'/usuario/0/vehiculo/-1/desde/'+desde+'/hasta/'+hasta,'ReporteComnparendos');
  }

  getReporteAccidentes(sucursal, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/accidentes/sucursal/'+sucursal+'/usuario/0/vehiculo/-1/desde/'+desde+'/hasta/'+hasta,'ReporteAccidentes');
  }
  getReporteTanqueo(sucursal, desde: string, hasta: string) {
    return this.http.dowloadExcel('reporte/combustible/sucursal/'+sucursal+'/usuario/0/vehiculo/-1/desde/'+desde+'/hasta/'+hasta,'ReporteAccidentes');
  }
}
