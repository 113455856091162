import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SelectOption } from 'src/app/models/select-option';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdministracionService } from '../administracion.service';

@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styleUrls: ['./proveedor.component.css']
})
export class ProveedorComponent implements OnInit {

  public proveedores: SelectOption[]=[];

  public proveedor: SelectOption;
  public error: string;
  public tractive: number;
  constructor(private service: AdministracionService,
              private mensaje: ModalMensajeService,
              private sesions: SesionService,
              private router: Router) {
    this.proveedor = {
      codigo: null,
      descripcion: '',

    }
    this.error ='';
  }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(8)) {
      this.router.navigate(['/inicio']);
    }
    this.getProveedores();
    }

    getProveedores(){
      this.service.getProveedores().subscribe(result => {
       this.proveedores = result.proveedores;
      });
    }


    insertProveedor() {
      if(this.proveedor.descripcion == ''){
        this.error ='ingrese una descripción para el proveedor';
        return;
      }
      this.error ='';
      this.service.insertProveedor(this.proveedor).subscribe(result => {
       this.proveedores = result.proveedores;
      });
    }


    enableProv(item: SelectOption){
      if(item.estado==0){return;}
      this.proveedores.forEach(element => {
         element.enable = element.codigo == item.codigo;
         element.temp = element.descripcion;
      });
    }

    updateProv(item: SelectOption){
      if(item.temp.trim() == ''){
        this.error ='ingrese una descripción para el proveedor.';
        return;
      }

      if(item.descripcion.trim() !== item.temp.trim()){
        this.error ='';
        this.service.updateProveedor(item).subscribe(result => {
          if(result.estado){
            this.proveedores = result.proveedores;
          }else{
            this.mensaje.showMensaje('Error al realizar operación','error');
          }

        });
      }else{
        item.enable = false;
      }
    }

    deleteProv(item: SelectOption){
      if(item.estado==0){return;}
      this.mensaje.confirmMensaje('¿ Seguro que quiere eliminar el proveedor ?').subscribe(arg => {
        if(arg){
          this.service.deleteProveedor(item.codigo).subscribe(result => {
            if (result.estado) {
               this.mensaje.showMensaje('Proveedor eliminado','info')
            } else {
              this.mensaje.showMensaje('Error al realizar operación','error');
            }
           this.proveedores = result.proveedores;
          });
        }
      });
    }
}
