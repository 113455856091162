import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { SesionService } from "../services/sesion.service";
import { NgHttpService } from "./ng-http.service";


@Injectable({
  providedIn: 'root'
})
export class TokenStoreService {

  public token = '';
  public token$ = new BehaviorSubject<string>('');

  constructor(private router: Router,
              private sesion: SesionService,
              private http: NgHttpService) {
    if (localStorage.getItem('HICARDATA') ) {
      this.dispatch(localStorage.getItem('HICARDATA'));
    }
  }

  select$ = () => this.token$.asObservable();

  dispatch = (token: string) =>  {

    this.token = token;
    if (token !== '') {
      localStorage.setItem('HICARDATA', token);
    } else {
      localStorage.clear();
    }

    this.token$.next(this.token);
  }

  isLogged = (): boolean => this.token !== '';


  public logout() {
      this.dispatch('');
      this.router.navigate(['/inicio', 1]);
  }
}
