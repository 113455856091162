import { Injectable } from '@angular/core';
import { NgHttpService } from '../core/http/ng-http.service';
import { Comparendo } from '../models/comparendo';

@Injectable({
  providedIn: 'root'
})
export class ComparendoService {

  constructor(private http: NgHttpService) { }


 insertComparendo(comp: Comparendo) {
  return  this.http.post('comparendo', comp);
 }

 updateComparendo(comp: Comparendo) {
  return  this.http.put('comparendo', comp);
 }
 getComparendo(codigo: number){
  return  this.http.get('comparendo/'+codigo);
 }

}
