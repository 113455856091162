import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { revisionCategoria } from 'src/app/models/revision-categoria';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdminrevisionService } from '../adminrevision.service';

@Component({
  selector: 'app-categoria-revision',
  templateUrl: './categoria-revision.component.html',
  styleUrls: ['./categoria-revision.component.css']
})
export class CategoriaRevisionComponent implements OnInit {

  public categorias: revisionCategoria[]=[];

  public categoria: revisionCategoria;
  public error: string;
  public tractive: number;

  constructor(private service: AdminrevisionService,
              private mensaje: ModalMensajeService,
              private sesions: SesionService,
              private router: Router) {
     this.categoria = {
      codigo: null,
      descripcion: ''
    }
    this.error ='';
  }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(9)) {
      this.router.navigate(['/inicio']);
    }
    this.getCats();
    }

    getCats(){
      this.service.getCategorias().subscribe(result => {
       this.categorias = result.categorias;
      });
    }


    enableCat(cat: revisionCategoria){
      if(cat.estado==0){return;}
       this.categorias.forEach(element => {
          element.enable = element.codigo == cat.codigo;
          element.temp = element.descripcion;
       });
    }

    updateCategoria(cat: revisionCategoria) {
      if(cat.temp.trim() == ''){
        this.error ='ingrese una descripción para la categoría';
        return;
      }

      if(cat.descripcion.trim() !== cat.temp.trim()){
        this.error ='';
        this.service.updateCategoria(cat).subscribe(result => {
          if(result.estado){
            cat.descripcion = result.categoria.descripcion;
            cat.enable = false;
          }else{
            this.mensaje.showMensaje('Error al realizar operación','error');
          }

        });
      }else{
        cat.enable = false;
      }

    }


  insertCategoria() {
    if (this.categoria.descripcion == '') {
      this.error ='ingrese una descripción para la categoría';
      return;
    }
    this.error ='';
    this.service.insertCategoria(this.categoria).subscribe(result => {
      if (result.estado) {
         this.mensaje.showMensaje('Categoría creada','info')
         this.categoria.descripcion='';
      } else {
        this.mensaje.showMensaje('Error al realizar operación','error');
      }
     this.categorias = result.categorias;
    });
  }

  deleteCategoria(cat: revisionCategoria) {
    if(cat.estado==0){return;}
    this.mensaje.confirmMensaje('¿ Seguro que quiere eliminar la categoría ?').subscribe(arg => {
      if(arg){
        this.service.deleteCategoria(cat.codigo).subscribe(result => {
          if (result.estado) {
             this.mensaje.showMensaje('Categoría eliminada','info')
          } else {
            this.mensaje.showMensaje('Error al realizar operación','error');
          }
         this.categorias = result.categorias;
        });
      }
    });

  }

}
