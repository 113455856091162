<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Gráfica novedades conductor <b>{{conductor?.nombre}}</b> </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3 pt-2">

  <div  style="width: 100%;overflow: hidden;border: solid 1px #cccccc;border-radius: 20px;" class="mt-5 pb-2" *ngIf="grafica.data">
    <div class="text-center pt-4"><b>Novedades ({{desde}} - {{hasta}})</b></div>
  <google-chart #chart
  [type]="grafica.type"
  [data]="grafica.data"
  [columns]="grafica.columns"
  [options]="grafica.options"
  [dynamicResize]="true"
  [height] = "300"
  style="width: 100%;"
 >
</google-chart>
</div>


<div  style="width: 100%;overflow: hidden;border: solid 1px #cccccc;border-radius: 20px;" class="mt-5 pb-2" *ngIf="graficaLinea.data">
  <div class="text-center pt-4"><b>Consumo Combustible ({{desde}} - {{hasta}})</b></div>
<google-chart #chart
[type]="graficaLinea.type"
[data]="graficaLinea.data"
[columns]="graficaLinea.columns"
[options]="graficaLinea.options"
[dynamicResize]="true"
[height] = "300"
style="width: 100%;"
>
</google-chart>
</div>


  <div class="text-center mt-4 mb-4">
    <button  (click)="bsModalRef.hide()" type="button" class="btn btn-warning">Cerrar</button>
  </div>

</div>



