import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStoreService } from '../core/http/token-store.service';
import { SesionService } from '../core/services/sesion.service';
import { usuario } from '../models/usuario';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username: string;
  public password: string;
  public usuario: usuario;
  public error: string;

  constructor(private service: LoginService,
              private router: Router,
              private sesion: SesionService,
              private token: TokenStoreService) {
    this.error ='';
    this.username='';
    this.password='';
  }

  ngOnInit(): void {
  }

  valid(){

    if(!this.username || this.username==''){
      this.error ='Ingrese nombre de usuario.';
      return false;
    }

    if(!this.password || this.password==''){
      this.error ='Ingrese la contraseña.';
      return false;
    }

    this.error ='';
    return true;

  }


  login(){
    if(!this.valid()) {
       return;
    }


    this.usuario = {
      codigo: 0,
      username: this.username,
      password: this.password
    }

    this.service.login(this.usuario).subscribe(result => {
       if(result.estado) {
        this.usuario = result.usuario;

        this.token.dispatch(this.usuario.token);
        this.sesion.dispatch(this.usuario);

        this.router.navigate(['/inicio']);

       }else{
         this.error = result.mensaje;
       }
    });
  }

}
