<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Gráfica novedades vehiculo <b>{{placa}}</b></h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3 pt-2">

  <div  style="width: 100%;overflow: hidden;border: solid 1px #cccccc;border-radius: 20px;" class="mt-5 pb-2" *ngIf="grafica.data">
    <div class="text-center pt-4"><b>Servicios por kilometraje</b></div>
  <google-chart #chart
  [type]="grafica.type"
  [data]="grafica.data"
  [columns]="grafica.columns"
  [options]="grafica.options"
  [dynamicResize]="true"
  [height] = "300"
  style="width: 100%;"
 >
</google-chart>
</div>
<br><br>
<div  style="width: 100%;overflow: hidden;border: solid 1px #cccccc;border-radius: 20px;" class="mt-5 pb-2" *ngIf="grafica2.data">
    <div class="text-center pt-4"><b>Servicios por dias</b></div>
  <google-chart #chart
  [type]="grafica2.type"
  [data]="grafica2.data"
  [columns]="grafica2.columns"
  [options]="grafica2.options"
  [dynamicResize]="true"
  [height] = "300"
  style="width: 100%;"
 >
</google-chart>
</div>
<br><br><br>
  <div class="text-center mt-4 mb-4">
    <button  (click)="bsModalRef.hide()" type="button" class="btn btn-warning">Cerrar</button>
  </div>

</div>



