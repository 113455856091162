import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ModalMensajeService } from '../modal-mensaje/modal-mensaje.service';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent implements OnInit {
  title: string;
  mensaje: string;
  tipo: string;
  closeBtnName: string;
  list: any[] = [];
  public suscrip: Subscription[]=[];

  public onClose: Subject<boolean>;
  constructor(public bsModalRef: BsModalRef,
    private locations: PlatformLocation,
    private modalS: ModalMensajeService,
    private modalService: BsModalService) {
      this.suscrip.push(this.modalService.onHide.subscribe((reason: string) => {
        this.modalS.estado = false;
    }));
  }
     ngOnInit() {
      history.pushState(null, '');
      this.locations.onPopState((event) => {
        // ensure that modal is opened
        if(this.modalS.estado) {
          this.modalS.closeAll();
        }
      });



      this.onClose = new Subject();
    }

    public onConfirm(): void {
      this.onClose.next(true);
      this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

  }
