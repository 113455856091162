<div class="row mb-5" style="max-width: 1000px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-2 title-text"><h3>Consulta </h3></div>


     <div class="form-group row content-filtro" style="max-width: 800px;margin: 0px auto;" >

      <div class="col col-10 col-sm-4  text-left">
       <label for="fecha" class="col-form-label" >Placa Vehículo</label>
       <div >
           <input type="text"
           class="form-control"
           name="id"
           placeholder="Placa del vehículo"
           [(ngModel)]="placavehiculo"
           (keydown.enter)="consultarVehiculo()"
           >
       </div>
      </div>
      <div class="col col-sm-1 col-2  text-left p-0">
         <img src="assets/GENERAL/descartar.png" [hidden]="placavehiculo == ''" style="margin-top: 40px;" width="30" (click)="clear(1)"/>
      </div>

      <!--conductor-->
      <div class="col col-sm-6 col-10  text-left">
        <label for="fecha" class="col-form-label" >Conductor</label>
        <div *ngIf="sesion?.vehiculos == 0">
            <app-search-conductor (onSelect)="onSelectConductor($event)" [setText]="asyncSelected"></app-search-conductor>
        </div>
        <div *ngIf="sesion?.vehiculos == 1">
          <input type="text"
          class="form-control"
          [value]="sesion.nombre"
          disabled
          >
         </div>

       </div>
       <div class="col col-sm-1 col-2  text-left p-0" *ngIf="sesion?.vehiculos == 0">
          <img src="assets/GENERAL/descartar.png" [hidden]="this.asyncSelected ==''" style="margin-top: 40px;" width="30" (click)="clear(2)"/>
       </div>




      <div *ngIf="error !== '' " class="col col-12 mt-4 mb-2">
        <div class="row alert alert-danger p-2 small mb-4"   style="max-width: 350px;margin: 0px auto;">
          {{error}}
        </div>
      </div>

      </div>

      <div class="col col-12 mt-1">
        <button type="button" class="btn btn-warning" (click)="consultarVehiculo()">Consultar</button>
     </div>




    <div *ngIf="vehiculo || codUser" class="form-group row" style="padding: 10px;margin-top: 20px;" >

      <div class="col col-12 text-left">
        <label for="fecha" class="col-form-label" >Rango Fecha :</label>
        <div >
         <input type="text"
         [bsConfig]="bsConfig"
         placeholder="Seleccione fecha inicial y fecha final"
         class="form-control"
         [maxDate]="maxDate"
         bsDaterangepicker
         [(ngModel)]="daterangepickerModel"
         (bsValueChange)="activeTab(activetag.codigo - 1)">
        </div>
       </div>



<div class="col">
      <div class="tab-head text-left">
        <div class="d-lg-none tab-active">
          <div class="btn-group" dropdown>
            <div id="button-basic" dropdownToggle  class="dropdown-toggle btn btn-sm" aria-controls="dropdown-basic">
              {{activetag.title}} <span class="caret"></span>
          </div>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" class="dropdown-item " *ngFor="let tab of activetabs; let i = index;" (click)="activeTab(i)" style="cursor: default;" >{{tab.title}}</li>
            </ul>
          </div>
        </div>
        <div *ngFor="let tab of activetabs; let i = index;" style="padding: 5px 10px;" class="d-none d-lg-block"    [ngClass]="{'tab-active':tab.active,'tab':!tab.active}"  (click)="activeTab(i)" >
        {{tab.title}}
        </div>
      </div>

      <div class="tab-conten" >


        <section *ngIf="activetag.codigo == 1" class="m-5 text-left">

          <section *ngIf="vehiculo">
            <div class="mb-4 mt-4"><h3>Datos Generales</h3></div>
            <div class="row ">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Placa:</div>
                <div><b>{{vehiculo.placa}}</b></div>
               </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Linea:</div>
                <div><b>{{vehiculo.linea}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Tipo:</div>
                <div><b>{{vehiculo.tipodescrip}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Marca:</div>
                <div><b>{{vehiculo.marcadescrip}}</b></div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Color:</div>
                <div><b>{{vehiculo.color}}</b></div>
               </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Num. Chasis:</div>
                <div><b>{{vehiculo.numchasis}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Num. Motor:</div>
                <div><b>{{vehiculo.nummotor}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Cilindraje:</div>
                <div><b>{{vehiculo.cilindraje}} c.c.</b></div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Uso:</div>
                <div><b>{{vehiculo.usodescrip}}</b></div>
               </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Num. Lic. Transito:</div>
                <div><b>{{vehiculo.numchasis}}</b></div>
              </div>
            </div>



            <div class="mb-4 mt-4"><h3>Motor</h3></div>
            <div class="row ">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Cant. válvulas por cilindro:</div>
                <div><b>{{vehiculo.motorvalvulaxclindro}}</b></div>
               </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Cantidad de cilindros:</div>
                <div><b>{{vehiculo.motorcilindros}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Turbo:</div>
                <div><b>{{vehiculo.motorturbo}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Orientación:</div>
                <div><b>{{vehiculo.motororientacion}}</b></div>
              </div>
            </div>



            <div class="mb-4 mt-4"><h3>Dirección - Transmisión - Suspensión</h3></div>
            <div class="row ">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Tipo de dirección:</div>
                <div><b>{{vehiculo.tipodirecciondescrip}}</b></div>
               </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Tipo de transmisión:</div>
                <div><b>{{vehiculo.tipotransmisiondescrip}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Numero de velocidades:</div>
                <div><b>{{vehiculo.numvelocidades}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Tipo de Rodamientos:</div>
                <div><b>{{vehiculo.tiporodamientodescrip}}</b></div>
              </div>
            </div>
            <div class="row ">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Suspensión delantera:</div>
                <div><b>{{vehiculo.suspensiondelantera}}</b></div>
               </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Suspensión trasera:</div>
                <div><b>{{vehiculo.suspensiontrasera}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Número llantas:</div>
                <div><b>{{vehiculo.numllantas}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Dimensión de Rines:</div>
                <div><b>{{vehiculo.tiporodamiento}}</b></div>
              </div>
            </div>
            <div class="row ">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Material de rines:</div>
                <div><b>{{vehiculo.suspensiondelantera}}</b></div>
               </div>
            </div>



            <div class="mb-4 mt-4"><h3>Frenos</h3></div>
            <div class="row ">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Tipo de frenos delanteros:</div>
                <div><b>{{vehiculo.fecnosddescrip}}</b></div>
               </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Tipo de frenos traseros:</div>
                <div><b>{{vehiculo.fecnostdescrip}}</b></div>
              </div>
            </div>




            <div class="mb-4 mt-4"><h3>Carrocería</h3></div>
            <div class="row ">
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Numero de Serie:</div>
                <div><b>{{vehiculo.carroceriaserie}}</b></div>
               </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Número de ventanas:</div>
                <div><b>{{vehiculo.carroceriaventanas}}</b></div>
              </div>
              <div class="col col-12 col-sm-3 mt-3 mb-3">
                <div class="small">Capacidad de carga y/o pasajeros:</div>
                <div><b>{{vehiculo.carroceriacapacidadcarga}}</b></div>
              </div>

            </div>
          </section>

          <section *ngIf="!vehiculo">
            <div class="m-5">
              <div class="alert alert-secondary text-center" role="alert" style="max-width: 350px;margin: 0px auto;">
                <div>Seleccione un vehículo para consultar.</div>
              </div>
            </div>
          </section>

        </section>



        <!--Novedades-->
        <section *ngIf="activetag.codigo == 2" class="m-5 text-left">

          <section *ngIf="vehiculo">
            <div class="mb-4 mt-4"><h3>Novedades</h3></div>
          <div class="row mt-4 mb-4">
            <div class="col text-left">
              <div>Promedio Km. calculado :<b> {{promkmCalculado | number}} Km.</b></div>
              <div>Promedio km fijo: <b>{{ (promkmvehiculo ? promkmvehiculo.kmprom : 0 ) | number}}</b> </div>
              <div style="cursor: pointer;" (click)="showConfigProm()" title="Cambiar tipo de promedio" >Tipo Promedio : <b>{{promkmvehiculo?.kmpromtipo==1 ? 'Fijo' : 'Calculado'}} <img src="assets/GENERAL/config.png" width="20"> </b></div>
            </div>
            <div class="col text-right">
              <button type="button" class="btn btn-warning"  (click)="showGraph(vehiculo.codigo, vehiculo.placa)" ><img width="30px" title="Ver gráfica" src="assets/GENERAL/graph.png"/> Ver Gráfica</button>
             </div>
          </div>


          <section *ngIf="novedades.length > 0">

            <div class="cav-radius mt-2"></div>
            <div class="table-responsive">

              <table class="table" style="border: solid 1px #cccccc;">
                <thead class="thead-dark">
                <tr class="bg-info text-white">
                    <th scope="col" class="">SERVICIO</th>
                    <th scope="col" class=" text-left one-line">FECHA</th>
                    <th scope="col" class=" text-left one-line">LIMITE</th>
                    <th scope="col" class=" text-center one-line">Km. Pend.</th>
                    <th scope="col" class=" text-center one-line">Días Pend.</th>
                    <th scope="col" class=" text-center one-line">Fecha/Estimada</th>
                    <th scope="col" ></th>
                    <th scope="col" ></th>

                </tr>
                </thead>
                <tbody>

                  <tr class="tr-1" [ngClass]="{'red': diffDias(vehiculo.fechasoat) <= diasalertsoat }">
                    <td class="text-left">SOAT</td>
                    <td class="text-left">{{vehiculo.fechasoat !='0000-00-00' ? vehiculo.fechasoat : '-'}}</td>
                    <td class="text-left">360 Días</td>
                    <td class="text-center one-line">Na</td>
                    <td class="text-center one-line" >{{vehiculo.fechasoat != '0000-00-00' ? diffDias(vehiculo.fechasoat) : '-' }}</td>
                    <td class="text-center one-line" >{{vehiculo.fechasoat !='0000-00-00'  ? sumarAnios(vehiculo.fechasoat) : '-' }}</td>
                    <td class="p-1"><img width="20px"  [src]="diffDias(vehiculo.fechasoat) <= diasalertsoat ? 'assets/GENERAL/warning1.png' : 'assets/GENERAL/ok2.png'"/></td>
                    <td class="p-1"></td>

                  </tr>

                  <tr class="tr-2" [ngClass]="{'red': diffDias(vehiculo.fechatecnomecanica) <= diasalerttecnomecanica }">
                    <td class="text-left">Tecnomecánica</td>
                    <td class="text-left">{{vehiculo.fechatecnomecanica !='0000-00-00' ? vehiculo.fechatecnomecanica : '-'}}</td>
                    <td class="text-left">360 Días</td>
                    <td class="text-center one-line">Na</td>
                    <td class="text-center one-line" >{{vehiculo.fechatecnomecanica !='0000-00-00' ? diffDias(vehiculo.fechatecnomecanica) : '-' }}</td>
                    <td class="text-center one-line" >{{ vehiculo.fechatecnomecanica !='0000-00-00' ? sumarAnios(vehiculo.fechatecnomecanica) : '-' }}</td>
                    <td class="p-1"><img width="20px"   [src]="diffDias(vehiculo.fechatecnomecanica) <= diasalerttecnomecanica ? 'assets/GENERAL/warning1.png' : 'assets/GENERAL/ok2.png'"/></td>
                    <td class="p-1"></td>

                  </tr>


                <tr *ngFor="let item of novedades ;let i = index; let impar = odd;let par = even;"
                     [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive, 'red': item.estado == 0  }"
                     (click)="tractive = i" >

                  <td class="text-left">{{item.servicior.descripcion}}</td>
                  <td class="text-left">{{item.fecha}}</td>
                  <td class="text-left one-line">{{item.servicior.tipo == 1 ? item.servicior.kilometraje +' Km.' : item.servicior.dias+ ' Días'}} </td>

                  <td *ngIf="item.codigo != 0 && item.servicior.tipo == 1" class="text-center one-line" [title]="' km: [ '+( (item.servicior.kilometraje - item.totalkm) | number)+' ], KM Max: '+(item.servicior.kilometraje | number)+''">{{ (item.servicior.kilometraje - item.ultkm + item.km | number) }} Km</td>
                  <td *ngIf="item.codigo != 0 && item.servicior.tipo == 2" class="text-center one-line"> Na</td>
                  <td *ngIf="item.codigo == 0" class="text-center one-line"> - </td>


                  <td class="text-center one-line">{{  item.diaspendientes }} </td>
                  <td class="text-center one-line">{{   item.codigo != 0 ? (sumarDias(item.diaspendientes)) : '-' }} </td>



                  <td class="p-1"><img width="20px" [src]="item.estado == 0 ? 'assets/GENERAL/warning1.png' : 'assets/GENERAL/ok2.png'"/></td>
                  <td class="p-1"><img width="20px" title="Ver ultimo Mantenimiento" src="assets/GENERAL/detalles.png" (click)="detalleMantenimiento(item)"/></td>
                </tr>

                </tbody>
              </table>

          </div>

        </section>

        <section *ngIf="novedades.length == 0">
          <div class="m-5">
            <div class="alert alert-secondary text-center" role="alert" style="max-width: 350px;margin: 0px auto;">
              <div>No hay servicios recurrentes registrados.</div>
            </div>
          </div>
        </section>
      </section>


        <section *ngIf="!vehiculo">
          <div class="m-5">
            <div class="alert alert-secondary text-center" role="alert" style="max-width: 350px;margin: 0px auto;">
              <div>Seleccione un vehículo para consultar.</div>
            </div>
          </div>
        </section>


        </section>


        <!--revisiones-->
        <section *ngIf="activetag.codigo == 3 && activetag.enable " class="m-5 text-left">
                  <div class="mb-4 mt-4"><h3>Revisiones Diarias</h3></div>


                  <section *ngIf="revisiones.length>0">

                    <div class="row">
                      <div class="col text-left"><button type="button" class="btn btn-warning" (click)="getReporteRevisiones()"  >Descargar Reporte</button></div>
                      <div class="col text-right"> <button *ngIf="sesions.validOpcion(3)" type="button" class="btn btn-warning" (click)="newRevision()" >Nueva Revisión</button> </div>
                    </div>
                  <div class="cav-radius mt-2"></div>
                  <div class="table-responsive">

                    <table class="table" style="border: solid 1px #cccccc;">
                      <thead class="thead-dark">
                      <tr class="bg-info text-white small">
                          <th scope="col" >COD.</th>
                          <th scope="col" >PLACA</th>
                          <th scope="col" >FECHA</th>
                          <th scope="col" class="text-left">TIPO</th>
                          <th scope="col" class="text-left">USUARIO</th>
                          <th scope="col" class="text-center">KM</th>
                          <th scope="col" class="text-center">Dif.KM</th>
                          <th scope="col" class="text-center">ESTADO</th>
                          <th scope="col" ></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let item of revisiones ;let i = index; let impar = odd;let par = even;" [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" (click)="tractive = i" class="small" >
                        <td><b>{{item.codigo}}</b></td>
                        <td>{{item.vehiculoplaca}}</td>
                        <td class="text-left">{{item.fecha}}</td>
                        <td class="text-left">{{item.tipodescripcion}}</td>
                        <td>{{item.usuariosisnombre}}</td>
                        <td class="text-center one-line">{{item.kilometraje}} Km</td>
                        <td class="text-center one-line">{{item.km}} Km</td>
                        <td class="text-center" [ngClass]="{'red': item.estado == 1}">{{item.estado == 0 ? 'Completada' : 'Pendiente' }}</td>
                        <td><img width="25px" (click)="detalleRevision(item)" src="assets/GENERAL/detalles.png"/></td>
                      </tr>

                      </tbody>
                    </table>

                </div>

                <div class="text-center mt-4">
                  <pagination style="display: inline-block" [totalItems]="totalitem" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
                </div>
              </section>

              <section *ngIf="revisiones.length==0">
                <div class="m-5">
                  <div class="alert alert-secondary text-center" role="alert" style="max-width: 350px;margin: 0px auto;">
                    No hay revisiones registradas para el vehículos seleccionado.
                    <div><button type="button" *ngIf="sesions.validOpcion(3)"  class="btn btn-warning mt-5" (click)="newRevision()" >Nueva Revisión</button> </div>
                  </div>
                </div>
              </section>

        </section>





        <!--mantenimientos-->
        <section *ngIf="activetag.codigo == 4 && activetag.enable" class="m-5 text-left">
          <div class="mb-4 mt-4"><h3>Revisiones Mantenimientos</h3></div>
          <section *ngIf="mantenimientos.length > 0">
            <div class="row"><div class="col text-left"><button type="button" class="btn btn-warning" (click)="getReporteMant()"  >Descargar Reporte</button></div>
             <div class="col text-right"> <button  *ngIf="sesions.validOpcion(4)" type="button" class="btn btn-warning" (click)="newMantenimiento()" >Nuevo Mantenimiento</button> </div></div>
            <div class="cav-radius mt-2"></div>
            <div class="table-responsive">

              <table class="table" style="border: solid 1px #cccccc;">
                <thead class="thead-dark">
                <tr class="bg-info text-white small">
                    <th scope="col" >COD.</th>
                    <th scope="col" >FECHA</th>
                    <th scope="col" >FECHA_REG</th>
                    <th scope="col" >PLACA</th>
                    <th scope="col" class="text-left">MECÁNICO</th>
                    <th scope="col" class="text-left">OBSERVACIÓN</th>
                    <th scope="col" class="text-right">KM</th>
                    <th scope="col" class="text-right">VALOR</th>
                    <th scope="col" ></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of mantenimientos ;let i = index; let impar = odd;let par = even;" [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" (click)="tractive = i" class="small" >
                  <td><b>{{item.codigo}}</b></td>
                  <td class="text-left">{{item.fecha}}</td>
                  <td class="text-left">{{item.fechasis}}</td>
                  <td>{{item.placavehiculo}}</td>
                  <td>{{item.mecanico}}</td>
                  <td class="text-left">{{item.observacion}}</td>
                  <td class="text-right">{{item.km | number}}</td>
                  <td class="text-right"><b>${{item.valor | number}}</b></td>
                  <td><img width="25px" src="assets/GENERAL/detalles.png" (click)="detalleMantenimiento(item)"/></td>
                </tr>

                </tbody>
              </table>

          </div>

          <div class="text-center mt-4">
            <pagination style="display: inline-block" [totalItems]="totalitem" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
          </div>
        </section>

        <section *ngIf="mantenimientos.length==0">
          <div class="m-5">
            <div class="alert alert-secondary text-center" role="alert" style="max-width: 350px;margin: 0px auto;">
              <div>No hay mantenimientos registrados para el vehículos seleccionado.</div>
              <div class="m-3"><button *ngIf="sesions.validOpcion(4)"  type="button" class="btn btn-warning mt-5" (click)="newMantenimiento()" >Nuevo Mantenimiento</button> </div>
            </div>
          </div>
        </section>


        </section>


                <!--combustible-->
                <section *ngIf="activetag.codigo == 17 && activetag.enable" class="m-5 text-left">
                  <div class="mb-4 mt-4"><h3>Consumo Combustible</h3></div>
                  <section *ngIf="combustible.length > 0">
                    <div class="row"><div class="col text-left"><button type="button" class="btn btn-warning" (click)="getReporteConsumo()"  >Descargar Reporte</button></div>
                     <div class="col text-right"> <button  *ngIf="sesions.validOpcion(17)" type="button" class="btn btn-warning" (click)="newTanqueo()" >Nuevo Tanqueo</button> </div></div>
                    <div class="cav-radius mt-2"></div>
                    <div class="table-responsive">

                      <table class="table" style="border: solid 1px #cccccc;">
                        <thead class="thead-dark">
                        <tr class="bg-info text-white small">
                            <th scope="col" >COD.</th>
                            <th scope="col" >FECHA</th>
                            <th scope="col" >FECHA_REG</th>
                            <th scope="col" >PLACA</th>
                            <th scope="col" class="text-left">CONDUCTOR</th>
                            <th scope="col" class="text-left">CONSUMO</th>
                            <th scope="col" class="text-right">KM</th>
                            <th scope="col" class="text-right">VALOR</th>
                            <th scope="col" ></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of combustible ;let i = index; let impar = odd;let par = even;" [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" (click)="tractive = i" [title]="item.observacion" class="small" >
                          <td><b>{{item.codigo}}</b></td>
                          <td class="text-left">{{item.fecha}}</td>
                          <td class="text-left">{{item.fechasis}}</td>
                          <td class="text-left">{{item.placavehiculo}}</td>
                          <td>{{item.nombreconductor}}</td>
                          <td class="text-left">{{item.consumo}} Gal.</td>
                          <td class="text-right">{{item.km | number}}</td>
                          <td class="text-right"><b>${{item.valortotal | number}}</b></td>
                          <td><img width="25px" src="assets/GENERAL/detalles.png" (click)="detalleTanqueo(item)"/></td>
                        </tr>

                        </tbody>
                      </table>

                  </div>

                  <div class="text-center mt-4">
                    <pagination style="display: inline-block" [totalItems]="totalitem" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
                  </div>
                </section>

                <section *ngIf="combustible.length==0">
                  <div class="m-5">
                    <div class="alert alert-secondary text-center" role="alert" style="max-width: 350px;margin: 0px auto;">
                      <div>No hay consumos de combustible registrados para el vehículos seleccionado.</div>
                      <div class="m-3"><button *ngIf="sesions.validOpcion(17)"  type="button" class="btn btn-warning mt-5" (click)="newTanqueo()" >Nuevo consumo</button> </div>
                    </div>
                  </div>
                </section>


                </section>


        <!--comparendos-->
        <section *ngIf="activetag.codigo == 5 && activetag.enable" class="m-5 text-left">
          <div class="mb-4 mt-4"><h3>Comparendos</h3></div>
          <section *ngIf="comparendos.length>0">
            <div class="row"><div class="col text-left"><button type="button" class="btn btn-warning" (click)="getReporteComp()"  >Descar Reporte</button></div>
             <div class="col text-right"> <button *ngIf="sesions.validOpcion(5)" type="button" class="btn btn-warning" (click)="newComparendo()" >Nuevo Comparendo</button>  </div>
            </div>


            <div class="cav-radius mt-2"></div>
            <div class="table-responsive">

              <table class="table" style="border: solid 1px #cccccc;">
                <thead class="thead-dark">
                <tr class="bg-info text-white small">
                    <th scope="col" >COD.</th>
                    <th scope="col" >NUMERO</th>
                    <th scope="col" class="text-left">FECHA</th>
                    <th scope="col" class="text-left">PLACA</th>
                    <th scope="col" class="text-left">DESCRIPCIÓN</th>
                    <th scope="col" class="text-left">TIPO</th>
                    <th scope="col" class="text-left">RESPONSABLE</th>
                    <th scope="col" class="text-right">VALOR</th>
                    <th scope="col" class="text-right">ESTADO PAGO</th>
                    <th scope="col" class="col-1"></th>
                </tr>
                </thead>
                <tbody>
                <tr class="small" *ngFor="let item of comparendos ;let i = index; let impar = odd;let par = even;" [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" (click)="tractive = i" >
                  <td><b>{{item.codigo}}</b></td>
                  <td class="text-left">{{item.comparendo}}</td>
                  <td>{{item.fecha}}</td>
                  <td>{{item.vehiculoplaca}}</td>
                  <td class="text-left">{{item.descripcion}}</td>
                  <td class="text-left">{{item.tiporesponsable==1?'Conductor':'Propietario'}}</td>
                  <td class="text-left">{{item.responsable}}</td>
                  <td class="text-right one-line"><b>$ {{item.valor | number}}</b></td>
                  <td class="text-left">{{item.estadopago==1?'Pagado':'Pendiente'}}</td>
                  <td><img width="25px" (click)="detalleComparendo(item)" src="assets/GENERAL/detalles.png"/></td>
                </tr>

                </tbody>
              </table>

          </div>

          <div class="text-center mt-4">
            <pagination style="display: inline-block" [totalItems]="totalitem" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
          </div>
        </section>

        <section *ngIf="comparendos.length==0">
          <div class="m-5">
            <div class="alert alert-secondary text-center" role="alert" style="max-width: 350px;margin: 0px auto;">
             <div> No hay comparendos registrados para el vehículos seleccionado.</div>
              <div class="m-3"><button *ngIf="sesions.validOpcion(5)" type="button" class="btn btn-warning mt-5" (click)="newComparendo()" >Nuevo Comparendo</button> </div>
            </div>
          </div>
        </section>

        </section>



        <!--accidentes-->
        <section *ngIf="activetag.codigo == 6 && activetag.enable" class="m-5 text-left">
          <div class="mb-4 mt-4"><h3>Accidentes</h3></div>

          <section *ngIf="accidentes.length>0">
            <div class="row"><div class="col text-left"><button type="button" class="btn btn-warning" (click)="getReporteAccidentes()"  >Descar Reporte</button></div>
            <div class="col text-right"> <button *ngIf="sesions.validOpcion(6)" type="button" class="btn btn-warning" (click)="newAccidente()" >Nuevo Accidente</button>  </div>
           </div>

            <div class="cav-radius mt-2"></div>
            <div class="table-responsive">

              <table class="table" style="border: solid 1px #cccccc;">
                <thead class="thead-dark">
                <tr class="bg-info text-white small">
                    <th scope="col" >COD.</th>
                    <th scope="col" >FECHA</th>
                    <th scope="col" >PLACA</th>
                    <th scope="col" class="text-left">ÁREA</th>
                    <th scope="col" class="text-left">LUGAR</th>
                    <th scope="col" class="text-left">CONDUCTOR</th>
                    <th scope="col" class="text-left">DESCRIPCIÓN</th>
                    <th scope="col" class="text-left"></th>
                </tr>
                </thead>
                <tbody>
                <tr class="small" *ngFor="let item of accidentes ;let i = index; let impar = odd;let par = even;" [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" (click)="tractive = i" >
                  <td ><b>{{item.codigo}}</b></td>
                  <td class="text-left">{{item.fecha}}</td>
                  <td class="text-left">{{item.vehiculoplaca}}</td>
                  <td class="text-left">{{item.area}}</td>
                  <td class="text-left">{{item.lugar}}</td>
                  <td class="text-left">{{item.conductor}}</td>
                  <td class="text-left">{{item.descripcion}}</td>
                  <td><img width="25px" (click)="detalleAccidente(item)" src="assets/GENERAL/detalles.png"/></td>
                </tr>

                </tbody>
              </table>

          </div>

          <div class="text-center mt-4">
            <pagination style="display: inline-block" [totalItems]="totalitem" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
          </div>
        </section>

        <section *ngIf="accidentes.length == 0">
          <div class="m-5">
            <div class="alert alert-secondary text-center" role="alert" style="max-width: 350px;margin: 0px auto;">
             <div> No hay accidentes registrados para el vehículos seleccionado.</div>
              <div class="row"><div class="col text-right"> <button *ngIf="sesions.validOpcion(6)" type="button" class="btn btn-warning" (click)="newAccidente()" >Nuevo Accidente</button> </div></div>
            </div>
          </div>
        </section>

        </section>


      </div>

    </div>





   </div>



  </div>
 </div>
