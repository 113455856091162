<div class="row mb-5" style="max-width: 1000px;margin:  0px auto; min-height: 500px; ">
  <div class="col font-md text-center">
     <div class="mt-5 mb-2 title-text"><h3>Consulta</h3></div>

     <div class="btn-group mb-4" btnRadioGroup [(ngModel)]="option">
      <label (click)="getVehiculos(1)" class="btn btn-warning" btnRadio="vehiculos"
              tabindex="0" role="button">Vehículos</label>
      <label (click)="getConductores(1)" class="btn btn-warning"  btnRadio="conductores"
              tabindex="0" role="button">Conductores</label>
    </div>

     <div class="form-group row" >
      <label for="sucursal" class="col-sm-2 col-form-label" >Sucursal :</label>
      <div class="col-sm-4" >
        <select  class="custom-select" [(ngModel)]="sucursal" (change)="consultarData(1)">
          <option *ngFor=" let item of sucursales" [ngValue]="item.codigo" >{{item.descripcion}}</option>
        </select>
      </div>

      <label for="fecha" class="col-sm-3 col-form-label" >Filtrar Entre Fechas :</label>
      <div class="col-sm-3" >
          <input type="text"
          [bsConfig]="bsConfig"
          placeholder="Seleccione fecha inicial y fecha final."
          class="form-control"
          bsDaterangepicker
          [(ngModel)]="daterangepickerModel"
          [maxDate]="maxDate"
          (bsValueChange)="consultarData(1)">
      </div>
    </div>

    <section *ngIf="vehiculos.length==0">
      <div class="m-5">
        <div class="alert alert-secondary" role="alert" style="max-width: 350px;margin: 0px auto;">
          No hay vehículos registrados en el sistema.
        </div>
      </div>
    </section>

    <div *ngIf="load && vehiculos.length > 0">

      <div class="row mt-5" ><div class="col text-right">

        <div class="btn-group" dropdown>
          <button id="button-basic" dropdownToggle type="button" class="btn btn-warning dropdown-toggle" aria-controls="dropdown-basic">
            Descargar Reportes <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem" class="dropdown-item" style="cursor: pointer;" (click)="getReporte()">Novedades Vehículos</li>
            <li role="menuitem" class="dropdown-item" style="cursor: pointer;" (click)="getReporteMant()">Mantenimientos Vehículos</li>
            <li role="menuitem" class="dropdown-item" style="cursor: pointer;" (click)="getReporteGeneralRevisiones()">Revisiones Vehículos General</li>
            <li role="menuitem" class="dropdown-item" style="cursor: pointer;" (click)="getReporteGeneralCombustible()">Consumo Combustible Vehículos General</li>
            <li role="menuitem" class="dropdown-item" style="cursor: pointer;" (click)="getReporteGeneralComparendos()">Comparendos Vehículos General</li>
            <li role="menuitem" class="dropdown-item" style="cursor: pointer;" (click)="getReporteGeneralAccidentes()">Accidentes Vehículos General</li>
          </ul>
        </div>

        </div>
      </div>

    <div class="cav-radius mt-5"></div>
    <div class="table-responsive">

      <section *ngIf="option=='vehiculos'">
       <table class="table" style="border: solid 1px #cccccc;">
        <thead class="thead-dark">
         <tr class="bg-info text-white small">
            <th scope="col" class="text-left">PLACA</th>
            <th scope="col" class="text-left">TIPO</th>
            <th scope="col" class="text-left">MARCA</th>
            <!--<th scope="col" class="col-1 text-center">ACCIDENTES</th>
            <th scope="col" class="col-1 text-center">COMPARENDOS</th>-->
            <th scope="col" class="text-center">MANTENIMIENTOS</th>
            <th scope="col" class="text-center">REVISIONES</th>
            <th scope="col" class="text-center">REV.HOY</th>
            <th scope="col" class="text-center">NOT.</th>
            <th scope="col" class="small">Rev.</th>
            <th scope="col" class="small">Mant</th>
            <th scope="col"></th>
         </tr>
        </thead>
         <tbody>
         <tr *ngFor="let item of vehiculos ;let i = index; let impar = odd;let par = even;" [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" (click)="tractive = i" >
           <td class="text-left"><b>{{item.placa}}</b></td>
           <td class="text-left">{{item.tipodescrip}}</td>
           <td class="text-left">{{item.marcadescrip}}</td>
           <!--<td class="text-center">{{item.naccidentes}}</td>
           <td class="text-center">{{item.ncomparendos}}</td>-->
           <td class="text-center">{{item.nmantenimientos}}</td>
           <td class="text-center">{{item.nrevisiones}}</td>
           <td class="text-center"><img [src]="item.nrevisioneshoy > 0 ? 'assets/GENERAL/ok2.png' : 'assets/GENERAL/ok20.png'" width="20" > </td>
           <div *ngIf="item.notificaciones" >
              <td class="text-center">
                <div [ngClass]="{'noty': item.notificaciones.total > 0, 'grey': item.notificaciones.total == 0}"
                  (mouseenter)="item.showmensaje = true"
                  (mouseleave)="item.showmensaje = false"
                  >{{item.notificaciones.total}}
                  <div *ngIf="item.showmensaje && item.notificaciones.total > 0" class="custom-tooltip">
                    <div>{{item.placa}}</div>
                    <div class="tooltip-item" *ngFor="let s of item.notificaciones.servicios">{{s.nombre}}  ({{s.kms}} km / {{s.dias}} dias)   </div>
                  </div>
                </div>
                <div style="width: 30px;height: 30px;background-color: #0272fb;color: #fff;
                  border-radius: 25px;text-align: center;line-height: 30px;margin: 0 auto;cursor: default;" >
                  {{item.notificaciones.length}}
                </div>
              </td>
            </div>
           <td><img (click)="addRevision(item.placa)" title="Registrar nueva Revisión." width="35" src="assets/GENERAL/test.png"/></td>
           <td><img (click)="addMantenimiento(item.placa)" title="Registrar nuevo Mantenimiento." width="30" src="assets/GENERAL/config.png"/></td>
           <td><img (click)="consultarVehiculo(item.placa)" width="30px" src="assets/GENERAL/detalles.png"/></td>
         </tr>

        </tbody>
       </table>
      </section>


      <!--conductores-->
      <section *ngIf="option=='conductores'">
        <table class="table" style="border: solid 1px #cccccc;">
         <thead class="thead-dark">
          <tr class="bg-info text-white small">
             <th scope="col" class="text-left">NOMBRE</th>
             <th scope="col" class="col-1 text-center">ACCIDENTES</th>
             <th scope="col" class="col-1 text-center">COMPARENDOS</th>
             <th scope="col" class="text-center">TANQUEOS</th>
             <th scope="col" class="text-center">MANTENIMIENTOS</th>
             <th scope="col" class="text-center">REVISIONES</th>
             <th scope="col" class="text-center">REV.HOY</th>
             <th scope="col"></th>
             <th scope="col"></th>
          </tr>
         </thead>
          <tbody>
          <tr *ngFor="let item of conductores ;let i = index; let impar = odd;let par = even;" [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" (click)="tractive = i" >
            <td class="text-left">{{item.nombre}}</td>
            <td class="text-center">{{item.naccidentes}}</td>
            <td class="text-center">{{item.ncomparendos}}</td>
            <td class="text-center">{{item.ntanqueos}}</td>
            <td class="text-center">{{item.nmantenimientos}}</td>
            <td class="text-center">{{item.nrevisiones}}</td>
            <td class="text-center"><img [src]="item.nrevisioneshoy > 0 ? 'assets/GENERAL/ok2.png' : 'assets/GENERAL/ok20.png'" width="20" > </td>
            <td><img (click)="showGraph(item)" width="30px" src="assets/GENERAL/graph.png"/></td>
            <td><img (click)="consultarConductor(item.codigo, item.nombre)" width="30px" src="assets/GENERAL/detalles.png"/></td>
          </tr>

         </tbody>
        </table>
       </section>


   </div>

   <div class="text-center mt-4">
    <pagination style="display: inline-block" [totalItems]="totalitem" [itemsPerPage]="itemPag" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
    <div class="btn-group" dropdown>
      <select  class="custom-select" [(ngModel)]="itemPag" (change)="onPagesLength()">
        <option [ngValue]="50" >50</option>
        <option [ngValue]="25" >25</option>
        <option [ngValue]="10" >10</option>
      </select>
  </div>

</div>


  </div>
 </div>