import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { revisionCategoria } from 'src/app/models/revision-categoria';
import { revisionCategoriaItem } from 'src/app/models/revision-categoria-item';
import { SelectOption } from 'src/app/models/select-option';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdminrevisionService } from '../adminrevision.service';

@Component({
  selector: 'app-item-revision',
  templateUrl: './item-revision.component.html',
  styleUrls: ['./item-revision.component.css']
})
export class ItemRevisionComponent implements OnInit {
  public categorias: revisionCategoria[]=[];
  public tipos: SelectOption[]=[];

  public items: revisionCategoriaItem[]=[];

  public item: revisionCategoriaItem;
  public error: string;
  public tractive: number;
  public sub: any;

  constructor(private service: AdminrevisionService,
              private mensaje: ModalMensajeService,
              private route: ActivatedRoute,
              private sesions: SesionService,
              private router: Router) {
    this.item = {
      codigo: null,
      categoria: null,
      tipo: null,
      descripcion: ''
    }
    this.error ='';
    this.sub = this.route.params.subscribe(params => {
      if(params.categoria){
        this.item.categoria = parseInt(params.categoria);
      }
     });
   }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(10)) {
      this.router.navigate(['/inicio']);
    }
    this.getCats();
  }

  getCats(){
    this.service.iniItemsCat().subscribe(result => {
     this.categorias = result.categorias;
     this.tipos = result.tipos;

     if(this.item.categoria){
       this.getItemsCat();
     }
    });
  }


  getItemsCat(){
    if(this.item.categoria){
      this.service.getItemsCat(this.item.categoria).subscribe(result => {
        this.items = result.items;
      });
    }
  }

  insertItem(){
    if(!this.item.categoria){
      this.error ='Seleccione una categoria.';
      return;
    }
    if(!this.item.tipo){
      this.error ='Seleccione un tipo de item.';
      return;
    }
    if(this.item.descripcion.trim() ==''){
      this.error ='Ingrese una descripción para el item.';
      return;
    }

    this.error='';
    this.service.insertItem(this.item).subscribe(result => {
      if(result.estado){
        this.items = result.items;
        this.item.descripcion='';
        this.item.tipo=null;
      }else{
        this.mensaje.showMensaje('Error al realizar operación.','error');
      }
     });

  }



  enableItem(item: revisionCategoriaItem){
    if(item.estado==0){return;}
     this.items.forEach(element => {
        element.enable = element.codigo == item.codigo;
        element.temp = element.descripcion;
     });
  }


  modItem(item: revisionCategoriaItem){
    if(item.temp.trim() == ''){
      this.error ='ingrese una descripción para el item';
      return;
    }

    if(item.descripcion.trim() !== item.temp.trim()){
      this.error ='';
      this.service.updateItemCategoria(item).subscribe(result => {
        if(result.estado){
          item.descripcion = result.item.descripcion;
          item.enable = false;
        }else{
          this.mensaje.showMensaje('Error al realizar operación','error');
        }

      });
    }else{
      item.enable = false;
    }
  }
  deleteItem(item: revisionCategoriaItem) {
    if(item.estado==0){return;}
    this.mensaje.confirmMensaje('¿ Seguro que quiere eliminar el item ?').subscribe(arg => {
      if(arg){
        this.service.deleteItemCategoria(item.categoria, item.codigo).subscribe(result => {
          if (result.estado) {
             this.mensaje.showMensaje('Item eliminado','info')
          } else {
            this.mensaje.showMensaje('Error al realizar operación','error');
          }
         this.items = result.items;
        });
      }
    });
  }

}
