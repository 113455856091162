<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Detalles Revisión</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="padding: 10px;">

  <section *ngIf="revision">


    <div *ngIf="vehiculo" class="row" style="padding: 10px; max-width: 700px;margin: 0px auto;" >

      <div class="col-12 p-2">

        <section class="mb-5 text-left" id="cav">
          <div class="title"><b>DATOS VEHÍCULO</b></div>
          <div class="content-vehiculo">
            <div>
              <div class="cel-vehiculo">
                <div class="small">Placa:</div>
                <div>
                  <b>{{ vehiculo.placa }}</b>
                </div>
              </div>
              <div class="cel-vehiculo">
                <div class="small">Marca:</div>
                <div>
                  <b>{{ vehiculo.marcadescrip }}</b>
                </div>
              </div>
              <div class="cel-vehiculo">
                <div class="small">Tipo:</div>
                <div>
                  <b>{{ vehiculo.tipodescrip }}</b>
                </div>
              </div>
              <div class="cel-vehiculo">
                <div class="small">Color:</div>
                <div>
                  <b>{{ vehiculo.color }}</b>
                </div>
              </div>
            </div>
          </div>


          <div class="content-float">
            <div class="content-float">
              <div class="table-cell1">Usuario Revisión:</div>
              <div class="table-cell2">
                <b>{{revision.usuariosisnombre}}</b>
              </div>
            </div>

            <div class="content-float">
              <div class="table-cell1">Tipo Revisión: </div>
              <div class="table-cell2">
                <b>{{revision.tipodescripcion}}</b>
              </div>
            </div>

            <div class="content-float">
              <div class="table-cell1">Kilometraje:</div>
                    <div class="table-cell2" *ngIf="!isEditKm">
            <b>{{ revision.kilometraje | number }} km. </b>
            <img
              *ngIf="user.perfil == 1 && !isEditKm && revision.tipo != 18 && revision.tipo != 3"
              src="assets/GENERAL/mod.png"
              class="mt-2"
              title="Modificar Kilometraje"
              width="20"
              (click)="editKm(true)"
            />
          </div>

          <div class="table-cell2" *ngIf="isEditKm">
            <div class="content-float">
            <input
              type="text"
              class="form-control txt-float"
              placeholder="Kilometraje"
              [(ngModel)]="kmTemp"
            />

            <img
              src="assets/GENERAL/update.png"
              title="Actualizar Kilometraje"
              class="mt-2"
              width="20"
              (click)="updateKm()"
            />
            <img
              src="assets/GENERAL/descartar.png"
              title="Descartar Cambios"
              class="mt-2"
              (click)="editKm(false)"
              width="20"
            />
            </div>
            <div><small id="emailHelp" class="form-text text-muted text-left"> Anterior: {{ultKm | number}} km. Max {{maxKm > 0 ? maxKm : '-' }} km</small></div>
          </div>
            </div>

            <div class="content-float">
              <div class="table-cell1">Conductor:</div>
              <div class="table-cell2">
                <b>{{revision.nombreconductor}}</b>
              </div>
            </div>

            <div class="content-float" [hidden]="revision.observacion.trim()==''">
              <div class="table-cell1">Observación General:</div>
              <div class="table-cell2">
                <b>{{revision.observacion}}</b>
              </div>
            </div>
            </div>

</section>


<section>
             <section *ngIf="categorias.length==0 && revision.tipo != 3 $$ revision.tipo != 18" class="text-center mt-5 mb-5">
                 <img src="assets/GENERAL/loading.gif" width="30"> Cargando...
             </section>

            <section *ngFor="let categoria of categorias;">
              <div *ngIf="categoria.items.length>0" class="row m-2 mt-4" style="border:solid 1px #cccccc;border-radius: 10px;padding: 10px;">
                <div class="col col-12 text-left mt-4 mb-3 text-center"><b>{{categoria.descripcion}}</b></div>
                <div class="col p-0">

                  <div class="row m-2" *ngFor="let item of categoria.items;" >
                    <div class="col col-12 col-sm-12 col-lg-6 text-left" >{{item.descripcion}}</div>
                    <div class="col col-12 col-sm-12 col-lg-6 text-center">

                      <div *ngIf="item.tipo == 1">
                        <b>{{item.estadodescripcion}}</b>
                      </div>

                      <div class="row" *ngIf="item.tipo == 2" >
                        <div class="col-5">
                          <b>{{item.valor}}</b>
                          <!--<rating [(ngModel)]="item.valor" [readonly]="true" [max]="10"></rating>-->
                        </div>
                        <div class="col-1 p-0"><h4>/</h4></div>
                        <div class="col-1 p-0">
                            <h4>10</h4>
                        </div>
                      </div>

                      <div class="row" *ngIf="item.tipo == 3">
                        <div class="col-4">
                          <b>{{item.valor ==1 ? 'Si': 'No'}}</b>
                      </div>
                        <div class="col-8 text-left small">{{item.observacion}}
                        </div>
                      </div>


                      <div class="row" *ngIf="item.tipo == 4">
                        <div class="col-12 text-left small">{{item.observacion}}</div>
                      </div>



                    </div>
                  </div>

                </div>



                  <div class="col col-12 text-left" [hidden]="categoria.observacion.trim()==''">Observación General:</div>
                  <div class="col col-12 text-left" [hidden]="categoria.observacion.trim()==''">
                     <b>{{categoria.observacion}}</b>
                  </div>


                  <div  class="col-12 text-left" style="padding-top: 12px;" >
                    <div class="col-form-label" [hidden]="categoria.imagenes.length==0" >Fotografías :</div>

                    <div>
                      <a *ngFor="let img of categoria.imagenes" [href]="img.url " target="_blank"><div  class="img-cat" [ngStyle]="{'background-image': 'url(' + img.url + ')'}"></div></a>
                    </div>
                  </div>



              </div>
            </section>

            <div class="row">
              <div *ngIf="revision.imagenes && revision.imagenes.length > 0" class="col-12 text-left" style="padding-top: 12px;" >
                <div class="col-form-label" >Fotografías Generales :</div>
                <div>
                  <a *ngFor="let img of revision.imagenes" [href]="img.url " target="_blank"><div  class="img-cat" [ngStyle]="{'background-image': 'url(' + img.url + ')'}"></div></a>
                </div>
              </div>

              <div *ngIf="revision.firma==1 && revision.firmaurl !='' " class="col-12 " style="padding-top: 12px;">
                <div class=" col-form-label text-left">Firma 1:</div>
                <div class=" col-form-label text-left">
                  <img [src]="revision.firmaurl" width="100%" style="border: solid 1px #cccccc;border-radius: 20px;" >
                </div>
              </div>

              <div *ngIf="revision.firma==1 && revision.firmaurl2 !=''" class="col-12 " style="padding-top: 12px;">
                <div class=" col-form-label text-left">Firma 2:</div>
                <div class=" col-form-label text-left">
                  <img [src]="revision.firmaurl2" width="100%" style="border: solid 1px #cccccc;border-radius: 20px;" >
                </div>
              </div>


              <div *ngIf="user.perfil==1 && revision.tipo != 3" class="col-12 text-center " style="padding-top: 30px;" >
              <br> <button type="button" (click)="delete()" class="btn btn-danger">Eliminar Revisión</button> <br>
              <br> <button type="button" (click)="print()" class="btn btn-warning">Imprimir Revisión</button> <br>
            </div>
            </div>







        </section>
      </div>
</div>


  </section>

</div>
