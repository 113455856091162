<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Detalles Accidente</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">

  <section *ngIf="accidente">


    <div *ngIf="vehiculo" class="content" id="print-section" >

      <div class="title"><b>DATOS VEHÍCULO</b></div>
      <div class="content-vehiculo">
        <div>
          <div class="cel-vehiculo">
            <div class="small">Placa:</div>
            <div>
              <b>{{ vehiculo.placa }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Marca:</div>
            <div>
              <b>{{ vehiculo.marcadescrip }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Tipo:</div>
            <div>
              <b>{{ vehiculo.tipodescrip }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Color:</div>
            <div>
              <b>{{ vehiculo.color }}</b>
            </div>
          </div>
        </div>
      </div>


      <div class="content-float">
        <div>
          <div class="table-cell1">Usuario Accidente :</div>
          <div class="table-cell2">
            <b>{{accidente.usuariosisnombre}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Fecha :</div>
          <div class="table-cell2">
            <b>{{accidente.fecha}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Área :</div>
          <div class="table-cell2">
            <b>{{accidente.area}}</b>
          </div>
        </div>
        <div>
          <div class="table-cell1">Lugar :</div>
          <div class="table-cell2">
            <b>{{accidente.lugar}}</b>
          </div>
        </div>
        <div>
          <div class="table-cell1">Conductor :</div>
          <div class="table-cell2">
            <b>{{accidente.conductor}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Descripción :</div>
          <div class="table-cell2">
            <b>{{accidente.descripcion}}</b>
          </div>
        </div>

      </div>





      <div class="col-12 p-2">
        <section>
            <div class="row">
              <div *ngIf="accidente.imagenes && accidente.imagenes.length > 0" class="col-12 text-left" style="padding-top: 12px;" >
                <div class="col-form-label" >Fotografías :</div>
                <div>
                  <a *ngFor="let img of accidente.imagenes" [href]="img.url " target="_blank"><div  class="img-acc" [ngStyle]="{'background-image': 'url(' + img.url + ')'}"></div></a>
                </div>
              </div>

              <!--<div *ngIf="user.perfil==1" class="col-12 text-center " style="padding-top: 30px;" >
                <br> <button type="button" (click)="delete()" class="btn btn-danger">Eliminar Revisión</button> <br>
               </div>-->
            </div>
        </section>
      </div>
      <br><br>

      <div class="col-12 text-center " style="padding-top: 30px;" >
        <button  *ngIf="user.perfil==1" type="button" (click)="modAccidente()" class="btn btn-warning mr-2" >
          Modificar
        </button>

         <button type="button" (click)="print()" class="btn btn-warning">Imprimir</button> <br>
       </div>


</div>


  </section>

</div>
