import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollingService } from 'src/app/core/services/scrolling.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { LoginService } from 'src/app/login/login.service';
import { Modulos } from 'src/app/models/modulos';
import { usuario } from 'src/app/models/usuario';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public bannerShow: boolean;
  public activeLang = 'es';
  public sesion: usuario;
  public modulos: Modulos[]=[];

  constructor(private scroll: ScrollingService,
    private router: Router,
    private login: LoginService,
    private sesions: SesionService){

    this.scroll.select$().subscribe( s => this.bannerShow = s < 50);
    this.sesions.select$().subscribe( s=> {
      this.sesion = s;
      if(this.sesion) {
        this.modulos = this.sesion.modulos;
      }
    });
    }

  ngOnInit(): void {
    this.scroll.checkScolling();
  }


  salir(){
    this.login.salir().subscribe(result => {
      console.log(result);
      if(result.estado){
        //this.router.navigate(['/login']);
        window.location.href = "https://www.sicoe.com.co/sam/menu1/menu.php";
      }
    });
  }
}
