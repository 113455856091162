<div [ngClass]="{ 'nav-fixed': !bannerShow, 'nav-black':bannerShow }" class="title-f " style="background-color: transparent;" >

  <div class="pl-0 pr-0">
    <div  class="row m-0">



     <div *ngIf="bannerShow" class="col col-sm-10 col-10 col-lg-12 col-xl-12 pr-0 text-center  col-logo d-none d-sm-none d-md-none d-lg-block d-xl-block obj-animate ">
      <img class="logo mb-3 mt-4" src="assets/GENERAL/logo.png" width="70"/>
     </div>



     <div *ngIf="sesion" class="bar col col-sm-11 col-11 col-lg-12 col-xl-12   align-middle nav  text-center d-sm-none  d-none   d-lg-block obj-animate" style="padding: 0px;">
      <div class="row aling-right nav " style="display: table;margin: 0px auto;font-size: 15px;">

       <div style="float: left;" class="col-logo2 pt-2">
         <img src="assets/GENERAL/logo.png" height="40px" class="pr-4">
      </div>


      <div *ngFor="let item of modulos" class="nav-item">
        <span *ngIf="item.tipo==0"   [routerLink]="[item.url]" routerLinkActive="nav-item-active">{{item.descripcion}}</span>
        <span *ngIf="item.tipo==1">
          <div class="nav-item align-middle"  dropdown style="position: relative;">

            <div  id="basic-link" dropdownToggle placement="bottom right"
               aria-controls="basic-link-dropdown">{{item.descripcion}}</div>
            <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="basic-link">
              <li>
                <a *ngFor="let opcion of item.opciones" class="dropdown-item" [routerLink]="[opcion.url]" routerLinkActive="nav-item-active" >{{opcion.descripcion}}</a>
              </li>
            </ul>
          </div>

        </span>
      </div>




      </div>

      <div class="d-sm-none  d-none   d-lg-block" style="position: absolute;top:0px; right: 0px;width: 50px;height: 50px;">
        <img title="Cerrar Sesión" (click)="salir()" [src]="bannerShow ? 'assets/GENERAL/logout.png': 'assets/GENERAL/logout2.png' " style="margin: 5px;" width="40" />
      </div>
  </div>



  <div  class="col col-sm-10 col-10 col-lg-12 col-xl-12   align-middle nav  text-center d-sm-block  d-block   d-lg-none " style="background-color: #FFFFFF;padding: 0px;">
    <img src="assets/GENERAL/logo.png" height="40px" class="m-2">
  </div>
  <div *ngIf="sesion" class="col col-2 align-middle nav  d-sm-block  d-block   d-lg-none align-middle p-0" style="position: relative;" (click)="dropdown.toggle(true)">
    <div style="background-color: #444444;width: 60px;float: right;height: 55px;">
      <img style="margin: 10px;" src="assets/GENERAL/menu.png" width="40">
    </div>
  </div>


    </div>
  </div>



  <div  class="menu d-lg-none d-xl-none d-sm-block d-block" style="height: 0px;position: relative;top: -12px;right: calc(250px - 100%);">
    <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true">
      <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" (click)="dropdown.hide()" style="max-height: 550px;overflow: auto;font-size: 20px;"
          role="menu" aria-labelledby="button-triggers-manual">

        <li *ngFor="let item of modulos" role="menuitem">
          <a *ngIf="item.tipo==0" class="dropdown-item" [routerLink]="[item.url]" routerLinkActive="nav-item-active"> {{item.descripcion}}</a>
          <section *ngIf="item.tipo==1">
            <a class="dropdown-item">{{item.descripcion}}</a>
            <li *ngFor="let opcion of item.opciones" role="menuitem" style="padding-left: 25px;"><a class="dropdown-item" [routerLink]="[opcion.url]" routerLinkActive="nav-item-active"> {{opcion.descripcion}}</a></li>
          </section>
        </li>

           <li role="menuitem"><a class="dropdown-item" (click)="salir()"> Cerrar Sesión</a></li>
          </ul>
    </div>
  </div>
  <div style="width: 100%;height: 4px;background-color: #007BFF;"></div>
  <div *ngIf="sesion" style="width: 100%;font-size: 11px;text-align: right;margin-right: 20px;background-color: transparent;"><div style="float: right;padding: 2;background-color: #ffffff;position: absolute;right: 10px;">{{sesion.nombre}} ({{sesion.perfildesc}})</div></div>
  </div>


