<div class="row mb-5" style="max-width: 1000px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Administrar Usuario</h3></div>

     <div style="border:solid 1px #cccccc; border-radius: 10px;padding: 20px;max-width: 640px;margin: 0px auto;">

      <div  class="form-group row" style="max-width: 600px; " >


     <div class="col col-12 text-left mb-2">
       <label for="fecha" class="col-form-label" >Tipo Identificación</label>
       <select class="custom-select" [disabled]="estado==1" [(ngModel)]="usuario.tipoid" (change)="consultarUsuario()">
         <option *ngFor=" let item of tiposid" [ngValue]="item.codigo" >{{item.descripcion}}</option>
       </select>
     </div>

     <div class="col col-12 text-left mb-2">
       <label for="fecha" class="col-form-label" >Identificación</label>
       <input class="form-control"
       id="id"
       [disabled]="estado==1"
       [(ngModel)]="usuario.identificacion"
       (blur)="consultarUsuario()"
       (keydown.enter)="consultarUsuario()"
       placeholder="Ingrese numero de identificación."/>
     </div>

     <div class="col col-12 text-left mb-2">
      <label for="fecha" class="col-form-label" >Nombre Completo</label>
      <input class="form-control" id="nombre"  [(ngModel)]="usuario.nombre" placeholder="Ingrese nombres y apellidos."/>
    </div>


    <div class="col col-12 text-left mb-2">
      <label for="fecha" class="col-form-label" >Perfil</label>
      <select class="custom-select" [(ngModel)]="usuario.perfil">
        <option *ngFor=" let item of perfiles" [ngValue]="item.codigo" >{{item.descripcion}}</option>
      </select>
    </div>

    <div class="col col-12 text-left mb-2">
      <label for="fecha" class="col-form-label" >Estado</label>
      <select class="custom-select" [(ngModel)]="usuario.estado">
        <option *ngFor=" let item of estados" [ngValue]="item.codigo" >{{item.descripcion}}</option>
      </select>
    </div>


    <div class="col col-12 text-left mb-2">
      <label for="fecha" class="col-form-label" >Nombre de usuario</label>
      <input class="form-control" id="observacion" [(ngModel)]="usuario.username" placeholder="Ingrese correo electrónico."/>
    </div>


    <div class="col col-12 text-left">
      <div style="border: solid 1px #cccccc;border-radius: 10px;padding: 10px;">
        <div class="form-group form-check">
          <input type="checkbox" [disabled]="estado == 0" [(ngModel)]="usuario.updatepassword" class="form-check-input" id="exampleCheck1">
          <label class="form-check-label" for="exampleCheck1">Actualizar Contraseña</label>
        </div>
        <label for="fecha" class="col-form-label" >Contraseña</label>
        <input class="form-control" [disabled]="!usuario.updatepassword && estado == 1" id="observacion" [(ngModel)]="usuario.password" placeholder="Ingrese contraseña de acceso."/>
      </div>

    </div>



      </div>


     <div  class="row " style="max-width: 600px;margin: 0px auto; ">

       <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error !== '' " >
         {{error}}
       </div>

       <div class="btn-conten mt-5">
       <div *ngIf="estado == 0" class="butomForm" (click)="guardar()">
         <div class="img save"></div>
         <div>Registrar</div>
       </div>

       <div  *ngIf="estado == 1" class="butomForm" (click)="update()">
        <div class="img update"></div>
        <div>Actualizar</div>
      </div>

       <div class="butomForm" (click)="descartar()">
           <div class="img discard"></div>
         <div>Descartar</div>
       </div>
     </div>
     </div>

   </div>

  </div>
</div>
