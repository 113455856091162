import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-mensaje',
  templateUrl: './modal-mensaje.component.html',
  styleUrls: ['./modal-mensaje.component.css']
})
export class ModalMensajeComponent implements OnInit {

  title: string;
  mensaje: string;
  tipo: string;
  closeBtnName: string;
  list: any[] = [];

  icon: string;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    switch (this.tipo) {
      case 'info': {
         this.icon = 'assets/GENERAL/info.png';
         break;
         }
      case 'error': {
        this.icon = 'assets/GENERAL/error.png';
        break; }
      case 'warning': {
        this.icon = 'assets/GENERAL/warning1.png';
        break;
      }
    }
  }
}
