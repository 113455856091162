import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Combustible } from 'src/app/models/combustible';
import { Conductor } from 'src/app/models/conductor';

@Component({
  selector: 'app-grafica-conductor',
  templateUrl: './grafica-conductor.component.html',
  styleUrls: ['./grafica-conductor.component.css']
})
export class GraficaConductorComponent implements OnInit {
  public conductor: Conductor;
  public consumos: Combustible[];
  public desde: string;
  public hasta: string;

  public grafica = {
    type: 'ColumnChart',
    columns: ['Novedad', 'Cantidad'],
    options: this.getOptions(null, 235, 400, 'bottom', 22, 'right',['#007bff','#FF9900'], '#'),
    data: null
  };

  public graficaLinea = {
    type: 'AreaChart',
    columns: ['Fecha', 'KM/Gal'],
    options: this.getOptions(null, 235, 400, 'bottom', 22, 'right',['#007bff','#FF9900'], '#'),
    data: null
  };

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {

    const chartDataTemp = [];
    chartDataTemp.push([ 'Accidentes', this.conductor.naccidentes]);
    chartDataTemp.push([ 'Comparendos', this.conductor.ncomparendos]);
    chartDataTemp.push([ 'Tanqueos', this.conductor.ntanqueos]);
    chartDataTemp.push([ 'Mantenimientos', this.conductor.nmantenimientos]);
    chartDataTemp.push([ 'Revisiones', this.conductor.nrevisiones]);

    if(chartDataTemp.length > 0) {
      this.grafica.data = chartDataTemp;
    }


    let chartDataTemp2 = [];
    this.consumos.forEach(element => {
      const km = element.consumo > 0 ? this.roundToTwo(element.km / element.consumo) : 0;
      chartDataTemp2.push([ element.fecha, km ]);
    });


    if(chartDataTemp2.length > 0) {
      this.graficaLinea.data = chartDataTemp2;
    }



  }


  getOptions(title: string, w: number, h: number, legendPosition: string, fonttitlesize: number, textPosition: string, color: string[], format) {
    return {
        //title: title,
        displayExactValues: true,
        // width: w,
        // height: h,
        pieHole: 0.4,
        //titleTextStyle: {color: '#000000', fontName: 'Arial', fontSize: fonttitlesize, '1': 'normal', bold: false},
        legend: {position: legendPosition, fontSize: '10', color: '#9b9b9b'},
        colors: color,
        hAxis: {
            textPosition: textPosition,
            textStyle: {
                color: '#9b9b9b',
                fontSize: '10'
            },
            titleTextStyle: {
                color: '#9b9b9b',
                fontSize: '10'
            }
        },
        vAxis: {
            textPosition: '',
            labelMaxWidth: 20,
            textStyle: {
                color: '#666666',
                fontSize: '9'
            },
            titleTextStyle: {
                color: '#666666',
                fontSize: '8'
            },
            format:format
        }
    };

  }


  roundToTwo(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

}
