<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Resetear Tacometro vehículo <b>{{placa}}</b></h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3 pt-2">


  <div class="row">

    <div class="col col-12 text-left">
      <label for="kilometraje" class="col-form-label" >Kilometraje Inicial</label>
      <input type="text" class="form-control" name="kilometraje" placeholder="Kilometraje" [(ngModel)]="km">
      <small id="emailHelp" class="form-text text-muted text-left"> Actual {{ultKm | number}} km.</small>
    </div>
  </div>

  <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error !== '' " >
    {{error}}
  </div>

  <div class="text-center mt-4 mb-4">
    <button  (click)="bsModalRef.hide()" type="button" class="btn btn-warning" style="margin-right: 10px;">Cerrar</button> <button  (click)="guardar()" type="button" class="btn btn-warning">Guardar</button>
  </div>

</div>


