import { Injectable } from '@angular/core';
import { VehiculoComponent } from '../administracion/vehiculo/vehiculo.component';
import { NgHttpService } from '../core/http/ng-http.service';
import { Revision } from '../models/revision';
import { revisionCategoria } from '../models/revision-categoria';

@Injectable({
  providedIn: 'root'
})
export class RevisionService {

  constructor(private http: NgHttpService) { }

  ini(revision, vehiculo: number){
    return this.http.get('revision/'+revision+'/vehiculo/'+vehiculo+'/ini');
  }

  getCategoriaItems(revision: number, tipo: number) {
    return this.http.get('revision/'+revision+'/tipo/'+tipo+'/items');
  }

  insertRevision(revision: Revision) {
    return this.http.post('revision', revision);
  }

  updateRevision(revision: Revision) {
    return this.http.put('revision', revision);
  }

  deleteRevision(revision: number) {
    return this.http.delete('revision/'+revision);
  }

  insertRevisionCategoria(cats: revisionCategoria[]) {
    return this.http.post('revision/categoria', cats);
  }

  updateRevisionCategoria(cat: revisionCategoria) {
    return this.http.put('revision/categoria', cat);
  }

  endrevision(codigo: number) {
    return this.http.get('revision/'+codigo+'/end')
  }

  guardarFirmar(data: any){
    return this.http.post('revision/firma', data);
  }
}
