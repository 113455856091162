import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdminrevisionService } from '../../revision/adminrevision.service';
import { PermisosService } from '../permisos.service';

@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['./perfiles.component.css']
})
export class PerfilesComponent implements OnInit {
  public tractive: number;
  public perfiles: any[]=[];
  constructor(private service: PermisosService,
              private mensaje: ModalMensajeService,
              private sesions: SesionService,
              private router: Router) {

              }

              ngOnInit(): void {
                if(!this.sesions.validOpcion(16) && this.sesions.sesion.perfil != 1 ) {
                  this.router.navigate(['/inicio']);
                }
                this.getPerfiles();
              }

              getPerfiles(){
                this.service.getPerfiles().subscribe(result => {
                  this.perfiles = result.perfiles;
                });
              }

              modPerfil(item:any ){
                this.router.navigate(['/administracion/permisos/perfil', item.codigo]);
              }

              desactivarPerfil(item: any){
                 if(item.estado==0){return;}

                 this.mensaje.confirmMensaje('¿ Realmente quiere desactivar el perfil ?. se desactivará tambien el acceso a los usuarios con el mismo.').subscribe(arg => {
                   if(arg){
                    this.service.disablePerfil(item.codigo).subscribe(result => {
                      if(!result.estado){
                        this.mensaje.showMensaje('Error al realizar la operación.','error');
                      }
                      this.perfiles = result.perfiles;
                     });
                   }
                 });


              }
}
