import { Injectable } from '@angular/core';
import { NgHttpService } from 'src/app/core/http/ng-http.service';
import { revisionCategoria } from 'src/app/models/revision-categoria';
import { revisionCategoriaItem } from 'src/app/models/revision-categoria-item';
import { tipoRevision } from 'src/app/models/tipo-revision';

@Injectable({
  providedIn: 'root'
})
export class AdminrevisionService {

  constructor(private http: NgHttpService) { }

  getCategorias(){
    return this.http.get('administracion/revision/categorias');
  }

  insertCategoria(cat: revisionCategoria){
   return this.http.post('administracion/revision/categoria', cat);
  }

  updateCategoria(cat: revisionCategoria){
    return this.http.put('administracion/revision/categoria', cat);
  }

  deleteCategoria(codigo: number){
    return this.http.delete('administracion/revision/categoria/'+codigo);
   }






   iniItemsCat() {
    return this.http.get('administracion/revision/item/ini');
  }
   getItemsCat(cat: number) {
     return this.http.get('administracion/revision/categoria/'+cat+'/items');
   }
   insertItem(item: revisionCategoriaItem){
    return this.http.post('administracion/revision/categoria/item', item);
   }
   updateItemCategoria(cat: revisionCategoriaItem){
    return this.http.put('administracion/revision/categoria/item', cat);
  }
  deleteItemCategoria(cat: number,item: number){
    return this.http.delete('administracion/revision/categoria/'+cat+'/item/'+item);
   }




   getTiposRevision(){
     return this.http.get('administracion/revision/tipos');
   }

   getTipoRevision(codigo: number) {
    return this.http.get('administracion/revision/tipo/'+codigo);
  }
  insertTipoRevision(tipo: tipoRevision){
    return this.http.post('administracion/revision/tipo', tipo);
  }
  updateTipoRevision(tipo: tipoRevision){
    return this.http.put('administracion/revision/tipo', tipo);
  }

  deleteTipoRevision(tipo: number){
    return this.http.delete('administracion/revision/tipo/'+tipo);
  }

}
