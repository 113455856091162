import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CombustibleService } from 'src/app/combustible/combustible.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Combustible } from 'src/app/models/combustible';
import { usuario } from 'src/app/models/usuario';
import { Vehiculo } from 'src/app/models/vehiculo';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { StyleMantenimiento } from '../consulta-mantenimiento/constant';
import { VehiculoService } from '../vehiculo.service';

@Component({
  selector: 'app-consulta-combustible',
  templateUrl: './consulta-combustible.component.html',
  styleUrls: ['./consulta-combustible.component.css','../consulta-mantenimiento/consulta-mantenimiento.component.css']
})
export class ConsultaCombustibleComponent implements OnInit {
  public suscripM: Subscription[]=[];
  public suscrip: Subscription[]=[];

  public consumo: Combustible;
  public vehiculo: Vehiculo;
  public codvehiculo: number;
  public user: usuario;

  public kmTemp: number;
  public isEditKm: boolean;
  public ultKm : number;

  constructor(public bsModalRef: BsModalRef,
    private serviceM: ModalService,
    private locations: PlatformLocation,
    private modalService: BsModalService,
    public sesion: SesionService,
    private mensaje: ModalMensajeService,
    private servicecombustible: CombustibleService,
    private service: VehiculoService) {
      this.suscribModal();
      this.serviceM.nmodal = 1;

    }

    ngOnDestroy(): void {
      this.suscrip.forEach(element => {
        element.unsubscribe();
    });
    this.suscripM.forEach(element => {
      element.unsubscribe();
     });
    }
    suscribModal(){
      this.suscripM.push(this.modalService.onHide.subscribe((reason: string) => {
          this.suscripM.forEach(element => {
            element.unsubscribe();
          });
          this.suscrip = [];
          if(reason !== 'BACK') {
            history.back();
          }
       }));
    }

  ngOnInit(): void {
    this.suscrip.push(this.sesion.select$().subscribe(result => {
        this.user=result;
    }))

    history.pushState(null, '');
    this.locations.onPopState((event) => {
      // ensure that modal is opened

      if(this.serviceM.nmodal == 1){
        if(this.bsModalRef !== undefined) {
          this.modalService.setDismissReason('BACK');
         // if(!this.mensaje.estado) {
            this.bsModalRef.hide();
          // }else{
          //  this.mensaje.closeAll();
          //}
      }
      }

    });


    if(!this.vehiculo && this.codvehiculo) {
      this.service.getVehiculoCod(this.codvehiculo).subscribe(r => {
        this.vehiculo = r.vehiculo;
        this.getUltKm();
      });
    }else{
      this.getUltKm();
    }



  }

  getUltKm() {
    this.service.getUltmKmCombustible(this.consumo.codigo, this.vehiculo ? this.vehiculo.codigo:0).subscribe(res => {
       this.ultKm = res.km;
    });
  }

  editKm(key: boolean){
    this.isEditKm = key;
    this.kmTemp = this.consumo.kminicial;
  }

  updateKm() {
    const km = this.kmTemp-this.ultKm;
    let mnj = '';
    if(km < 0){
      this.mensaje.showMensaje('Kilometraje actual inferior al kilometraje anterior.','error');
      return false;
    }

    if(km > 200) {
      this.mensaje.confirmMensaje('¿ La diferencia de kilometros con relación al último tanqueo es mayor de 200 km, Seguro que quiere continuar.?').subscribe(arg => {
        if(arg) {
          this.confirmUpdateKm();
        }
    });
    }else{
      this.confirmUpdateKm();
    }

  }

  confirmUpdateKm(){
    this.service.updateKmCombustible(this.consumo.codigo, this.vehiculo? this.vehiculo.codigo : 0, this.kmTemp).subscribe(r => {
      if(r.estado) {
        this.consumo.kminicial = this.kmTemp;
        this.editKm(false);
      }
    })
  }



  modTanqueo(){
    this.service.eventPending = true;
    this.bsModalRef.hide();
  }



  print(){
    const cav =`<div style="text-align: center"><img height="40px" src="assets/GENERAL/logo.png"></div> <div style="text-align: center"><b>CONSUMO COMBUSTIBLE N° ${this.consumo.codigo}</b></div> `;


      let printContents, popupWin;
      printContents = document.getElementById('print-section').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print tab</title>
            <style>
               ${StyleMantenimiento.style}
            </style>
          </head>
      <body onload="window.print();window.close()">
      ${cav}
      ${printContents}
      </body>
      </html>`
      );
      popupWin.document.close();
    }


}
