import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VehiculoService } from '../consulta-vehiculo/vehiculo.service';
import { SesionService } from '../core/services/sesion.service';
import { UtilService } from '../core/services/util.service';
import { Vehiculo } from '../models/vehiculo';
import { ModalMensajeService } from '../share/modal-mensaje/modal-mensaje.service';
import { ComparendoService } from './comparendo.service';

@Component({
  selector: 'app-comparendo',
  templateUrl: './comparendo.component.html',
  styleUrls: ['./comparendo.component.css']
})
export class ComparendoComponent implements OnInit {

  public tractive: number;
  public sub: any;
  public bsConfig = { dateInputFormat: 'YYYY/MM/DD',
  containerClass: 'theme-default',
  isAnimated: false,
  adaptivePosition: true };
  maxDate = new Date();
  asyncSelected: string;

  public vehiculo: Vehiculo;

  public placavehiculo: string;
  public error: string;
  public error2: string;

  public codigo:number=0;
  public isUpdate: boolean;

  public formGroup: UntypedFormGroup;

  constructor(  private service: ComparendoService,
                private route: ActivatedRoute,
                private router: Router,
                private servicevehiculo: VehiculoService,
                private util: UtilService,
                private sesions: SesionService,
                private formBuilder: UntypedFormBuilder,
                private mensaje: ModalMensajeService) {
      this.error = '';
      this.error2 = '';
      this.sub = this.route.params.subscribe(params => {
        if(params.placa) {
          this.placavehiculo =params.placa ;
          this.codigo = params.codigo;
          this.isUpdate =  this.codigo > 0;
          this.consultarVehiculo();
        }
       });
     }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(5)) {
      this.router.navigate(['/inicio']);
    }
    if(this.isUpdate && this.sesions.sesion.perfil !=1){
      this.router.navigate(['/inicio']);
    }

    this.buildForm();

  }

  onSelectConductor(data: any): void {
    this.formGroup.controls.conductor.setValue(data.codigo);
    //this.formGroup.controls.nombreconductor.setValue(data.nombre);
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      codigo: [0, Validators.required],
      comparendo:['', Validators.required],
      vehiculo: ['', Validators.required],
      descripcion: ['', Validators.required],
      tiporesponsable: [null, Validators.required],
      idresponsable: ['', Validators.required],
      responsable: ['', Validators.required],
      conductor: ['', Validators.required],
      trabajo:[null,Validators.required],
      valor: ['', Validators.required],
      fecha: ['', Validators.required],
      fechasis: [''],
      usuariosis: [''],
      estadopago:[null, Validators.required]
     //auxnotificacion: [{value: null, disabled: true}],
     });

 }


 validarForm(): boolean {

  this.error = '';

  if(!this.vehiculo){
    this.error ='Ingrese un vehiculo';
  }
  if (this.formGroup.controls.comparendo.errors) {
    this.error = 'Ingrese el numero del comparendo.';
  }

  if (this.formGroup.controls.descripcion.errors) {
    this.error = 'Ingrese descripción del comparendo.';
  }

  if (this.formGroup.controls.fecha.errors) {
    this.error = 'Seleccione la fecha del comparendo.';
  }

  if (this.formGroup.controls.valor.errors) {
    this.error = 'Ingrese el valor del comparendo.';
  }

  if (this.formGroup.controls.tiporesponsable.errors) {
    this.error = 'Seleccione el tipo de responsable del comparendo.';
  }

  if (this.formGroup.controls.idresponsable.errors) {
    this.error = 'Ingrese la identificación del responsable del comparendo.';
  }

  if (this.formGroup.controls.responsable.errors) {
    this.error = 'Ingrese el nombre del responsable del comparendo.';
  }

  return this.error == '';
}

  consultarPlaca(){
    if(!this.placavehiculo || this.placavehiculo.trim() == '') {
      this.error='Ingrese la placa del vehículo a consultar.';
      return;
    }else{
      this.error ='';
    }

    this.router.navigate(['/add/comparendo',this.placavehiculo]);

  }


  consultarVehiculo(){
    if(!this.placavehiculo || this.placavehiculo.trim() == '') {
      this.error='Ingrese la placa del vehículo a consultar.';
      return;
    }else{
      this.error ='';
    }
    this.servicevehiculo.getVehiculo(this.placavehiculo).subscribe(result => {
       this.vehiculo = result.vehiculo;
       if(this.isUpdate){
        this.service.getComparendo(this.codigo).subscribe( com => {
           this.setComparendo(com);
        });
      }
    });
}

setComparendo(com: any){
  this.formGroup.controls.codigo.setValue(com.codigo);
  this.formGroup.controls.comparendo.setValue(com.comparendo);
  this.formGroup.controls.vehiculo.setValue(com.vehiculo);
  this.formGroup.controls.descripcion.setValue(com.descripcion);
  this.formGroup.controls.tiporesponsable.setValue(com.tiporesponsable);
  this.formGroup.controls.idresponsable.setValue(com.idresponsable);
  this.formGroup.controls.responsable.setValue(com.responsable);
  this.formGroup.controls.conductor.setValue(com.conductor);
  this.asyncSelected = com.nombreconductor;
  this.formGroup.controls.trabajo.setValue(com.trabajo);
  this.formGroup.controls.valor.setValue(com.valor);
  this.formGroup.controls.fecha.setValue(com.fecha);
  this.formGroup.controls.estadopago.setValue(com.estadopago);
}

registrarComparendo(){
  if(!this.validarForm()) {return;}

    this.formGroup.controls.vehiculo.setValue(this.vehiculo.codigo);

    this.service.insertComparendo(this.formGroup.value).subscribe( result => {
        if(result.estado) {
          const menj = this.isUpdate? 'Comparendo actualizado':'Comparendo agregado a la base de datos';
          this.mensaje.showMensaje(menj, 'info');
          this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/6']);
        }else{
          this.mensaje.showMensaje('No se pudo realizar la operación', 'error');
        }
    });
  }


descartar(){
  this.formGroup.reset();
  if(this.isUpdate){
    this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/6']);
  }
}


}
