import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { VehiculoService } from 'src/app/consulta-vehiculo/vehiculo.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilService } from 'src/app/core/services/util.service';
import { SelectOption } from 'src/app/models/select-option';
import { Vehiculo } from 'src/app/models/vehiculo';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdministracionService } from '../administracion.service';
import { ResetTacometroComponent } from './reset-tacometro/reset-tacometro.component';

@Component({
  selector: 'app-vehiculo',
  templateUrl: './vehiculo.component.html',
  styleUrls: ['./vehiculo.component.css']
})
export class VehiculoComponent implements OnInit {

  public formGroup: UntypedFormGroup;

  public tipos: SelectOption[];
  public sucursales : SelectOption[];
  public tiposfreno: SelectOption[];
  public tipostransmision: SelectOption[];
  public tiposrodamientos: SelectOption[];
  public tiposdireccion: SelectOption[];
  public usos: SelectOption[];
  public marcas: SelectOption[];
  public error: string;


  public bsConfig = { dateInputFormat: 'YYYY/MM/DD',
  containerClass: 'theme-default',
  isAnimated: true,
  adaptivePosition: true };

  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSourceUsuarios: Observable<any>;

  bsModalRef: BsModalRef;

  constructor(private service: AdministracionService,
              private servicevehiculo: VehiculoService,
              private mensaje: ModalMensajeService,
              private formBuilder: UntypedFormBuilder,
              private sesions: SesionService,
              private router: Router,
              private util: UtilService,
              private modal: BsModalService) {

    this.tipos=[];
    this.sucursales=[];
    this.tiposfreno = [];
    this.tipostransmision = [];
    this.tiposrodamientos = [];
    this.tiposdireccion = [];
    this.usos = [];
    this.marcas = [];
    this.error ='';
   }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(7)) {
      this.router.navigate(['/inicio']);
    }

    this.buildForm();
    this.ini();

    this.dataSourceUsuarios = Observable.create((observer: any) => {
      if (this.asyncSelected.length < 4) { return; }
     // this.wait.disable = true;
      observer.next(this.asyncSelected);
      }).pipe( mergeMap((token: string) =>  this.filterResultsUsers(token) ) );

  }


  filterResultsUsers(token: string) {
   // this.wait.disable = true;
    return this.service.filtrarUsuarios(token);
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
   // this.wait.disable = false;
  }

  typeaheadOnSelectUser(e: TypeaheadMatch): void {
    //this.idempleado = e.item.aux;
    //this.router.navigate(['/consultasiso',e.item.codigo]);
    this.formGroup.controls.conductor.setValue(e.item.codigo);
    this.formGroup.controls.nombreconductor.setValue(e.item.nombre);

  }



  validarForm(): boolean {

    this.error = '';
    if (this.formGroup.controls.placa.errors) {
      this.error = 'Ingrese la placa del vehículo. ';
    }

    if (this.formGroup.controls.sucursal.errors) {
      this.error = 'Seleccione la sucursal del vehículo. ';
    }
    if (this.formGroup.controls.linea.errors) {
      this.error = 'Ingrese la linea del vehículo. ';
    }
    if (this.formGroup.controls.tipo.errors) {
      this.error = 'Seleccione el tipo de vehículo. ';
    }
    if (this.formGroup.controls.marca.errors) {
      this.error = 'Seleccione la marca del vehículo. ';
    }
    if (this.formGroup.controls.color.errors) {
      this.error = 'Ingrese el color del vehículo. ';
    }
    if (this.formGroup.controls.numchasis.errors) {
      this.error = 'Ingrese el numero de chasis  del vehículo. ';
    }
    if (this.formGroup.controls.nummotor.errors) {
      this.error = 'Ingrese el numero del motor del vehículo. ';
    }
    if (this.formGroup.controls.cilindraje.errors) {
      this.error = 'Ingrese el cilindraje del vehículo. ';
    }
    if (this.formGroup.controls.uso.errors) {
      this.error = 'Seleccione el uso del vehículo. ';
    }
    if (this.formGroup.controls.numlicenciatransito.errors) {
      this.error = 'Ingrese el numero de licencia de transito del vehículo. ';
    }

    this.formGroup.controls.fechasoat.setValue(this.util.formatDate(this.formGroup.controls.fechasoat.value) );
    if (this.formGroup.controls.fechasoat.errors) {
      this.error = 'Seleccione la fecha de expedición del SOAT del vehículo. ';
    }
    this.formGroup.controls.fechatecnomecanica.setValue(this.util.formatDate(this.formGroup.controls.fechatecnomecanica.value) );
    if (this.formGroup.controls.fechatecnomecanica.errors) {
      this.error = 'Seleccione la fecha de expedición de la tecnomecanica del vehículo. ';
    }

    return this.error == '';
  }
  buildForm() {
    this.formGroup = this.formBuilder.group({
      codigo: [0],
      placa: ['', Validators.required],
      sucursal: ['', Validators.required],
      linea: ['', Validators.required],
      tipo: ['', Validators.required],
      marca: ['', Validators.required],
      color: ['', Validators.required],
      numchasis: ['', Validators.required],
      nummotor: ['', Validators.required],
      cilindraje: ['', Validators.required],
      uso: ['', Validators.required],
      numlicenciatransito: ['', Validators.required],
      fechasoat: ['', Validators.required],
      fechatecnomecanica: ['', Validators.required],
      conductor: [null],
      nombreconductor:[''],
      motorvalvulaxclindro: [''],
      motorcilindros: [''],
      motorturbo: [''],
      motororientacion: [''],
      tipodireccion: [''],
      tipotransmision: [''],
      numvelocidades: [''],
      tiporodamiento: [''],
      suspensiondelantera: [''],
      suspensiontrasera: [''],
      numllantas: [''],
      dimensionrines: [''],
      materialrines: [''],
      frenosdelanteros: [''],
      frenostraseros: [''],
      carroceriaserie: [''],
      carroceriaventanas: [''],
      carroceriacapacidadcarga: [''],

      tipodescrip:[''],
      marcadescrip:[''],
      usodescrip:[''],
      tipodirecciondescrip:[''],
      tipotransmisiondescrip:[''],
      tiporodamientodescrip:[''],
      fecnosddescrip:[''],
      fecnostdescrip:[''],
      nrevisioneshoy:[''],
      nrevisiones:[''],
      naccidentes:[''],
      ncomparendos:[''],
      nmantenimientos:[''],
      revisionpendiente:[null],
      notificaciones:[null],
      kmprom:[null],
      kmpromtipo:[null],
      ultkm:[0]
     //auxnotificacion: [{value: null, disabled: true}],
     });

 }



  ini(){

    this.service.iniVehiculo().subscribe(result =>{
       this.sucursales = result.sucursales;
       this.marcas = result.marcas;
       this.tiposdireccion = result.direcciones;
       this.tiposfreno = result.frenos;
       this.usos = result.usos;
       this.tipostransmision = result.tranmisiones;
       this.tipos = result.tipos;
       this.tiposrodamientos = result.rodamientos;
    });
  }


  consultarVehiculo(){
    if(this.formGroup.controls.placa.value.trim() ==''){return;}
     this.servicevehiculo.getVehiculo(this.formGroup.controls.placa.value).subscribe(result => {
      // if(result.vehiculo) {
        this.setVehiculo(result.vehiculo);
      // }
     });
  }

  setVehiculo(vehiculo: Vehiculo) {
    this.formGroup.setValue(vehiculo);
    this.formGroup.controls.placa.disable();

    this.asyncSelected = vehiculo.nombreconductor;
  }





  save(){
    if(!this.validarForm()) {return;}

    if(this.formGroup.controls.codigo.value == 0){
      this.service.insertVehiculo(this.formGroup.value).subscribe( result => {
          if(result.estado) {
            this.mensaje.showMensaje('Vehículo agregado a la base de datos', 'info');
            this.descartar();
          }else{
            this.mensaje.showMensaje('No se pudo realizar la operación', 'error');
          }
      });

    } else {
      this.service.updateVehiculo(this.formGroup.value).subscribe( result => {
        if(result.estado) {
          this.mensaje.showMensaje('Vehículo actualizado.', 'info');
          this.descartar();
        }else{
          this.mensaje.showMensaje('No se pudo realizar la operación', 'error');
        }
    });
    }
  }



  descartar() {
    this.formGroup.reset();
    this.formGroup.controls.codigo.setValue(0);
    this.formGroup.controls.placa.enable();
  }

  clear() { this.asyncSelected = ''; }



  showResetTacometro() {
    const initialState = {
      vehiculo: this.formGroup.controls.codigo.value,
      placa: this.formGroup.controls.placa.value,
      ultKm: this.formGroup.controls.ultkm.value
    };
    this.bsModalRef = this.modal.show(ResetTacometroComponent, {
      initialState: initialState,
      class: 'modal-500',
    });

    this.bsModalRef.onHide.subscribe((reason: string | any) => {
        this.consultarVehiculo();
    });
  }


}
