<div class="row mb-5" style="max-width: 1000px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Nuevo Mantenimiento</h3></div>


     <div class="form-group row" style="max-width: 400px;margin: 0px auto; " >

      <div class="col col-10  text-left">
       <label for="fecha" class="col-form-label" >Placa Vehículo</label>
       <div >
           <input type="text"
           class="form-control"
           name="id"
           placeholder="Numero de identificación"
           [(ngModel)]="placavehiculo"
            [disabled]="isUpdate"
           (keydown.enter)="consultarPlaca()"
           >
       </div>
      </div>
      <div class="col col-2  text-left p-0">
         <img *ngIf="!isUpdate" src="assets/GENERAL/buscar3.png" style="margin-top: 40px;" width="30" (click)="consultarPlaca()"/>
      </div>

      <div *ngIf="error !== '' " class="col col-12 mt-5 mb-5">
        <div class="row alert alert-danger p-2 small mb-4"   style="max-width: 350px;margin: 0px auto;">
          {{error}}
        </div>
      </div>
   </div>


    <div *ngIf="vehiculo" class="row" style="padding: 10px; max-width: 650px;margin: 0px auto;" >

            <div class="col-12 p-0">

              <section class="mt-5 mb-5 text-left">
                <div class="mb-4 mt-4"><b>DATOS VEHÍCULO</b></div>
                <div class="row " style="border: solid 1px #cccccc;border-radius: 20px;">
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Placa:</div>
                    <div><b>{{vehiculo.placa}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Marca:</div>
                    <div><b>{{vehiculo.marcadescrip}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Tipo:</div>
                    <div><b>{{vehiculo.tipodescrip}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Color:</div>
                    <div><b>{{vehiculo.color}}</b></div>
                  </div>

                </div>

              </section>



              <section>

                <div style="border:solid 1px #cccccc; border-radius: 10px;padding: 20px;max-width: 640px;margin: 0px auto;">

                  <div class="col col-12 text-left">
                    <label for="fecha" class="col-form-label" >Tipo Mantenimiento</label>
                    <select class="custom-select" [disabled]="!estado" [(ngModel)]="tipo" (change)="getServiciosCat()" >
                      <option *ngFor=" let item of tipos" [ngValue]="item.codigo" >{{item.descripcion}}</option>
                    </select>
                  </div>

                  <div class="col col-12 text-left">
                    <label for="fecha" class="col-form-label" >Fecha Mantenimiento</label>
                    <input type="text"
                    [disabled]="!estado"
                    class="form-control"
                    #dp="bsDatepicker"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    [maxDate]="maxDate"
                    [(ngModel)]="fechaMantenimiento"
                   >
                  </div>

                  <div class="col col-12 text-left">
                    <label for="fecha" class="col-form-label" >Conductor</label>
                    <app-search-conductor [setText]="nombreconductor" (onSelect)="onSelectConductor($event)"></app-search-conductor>
                  </div>

                  <div class="col col-12 text-left">
                    <label for="fecha" class="col-form-label" >Kilometraje</label>
                    <input type="text" class="form-control" [disabled]="!estado" name="kilometraje" placeholder="Kilometraje" [(ngModel)]="kilometraje">
                    <small id="emailHelp" class="form-text text-muted text-left"> Anterior: {{kilometrajeant | number}} km.</small>
                  </div>

                  <div class="col col-12 text-left">
                    <label for="fecha" class="col-form-label" >Mecánico</label>
                    <input type="text" class="form-control" [disabled]="!estado" name="salida" placeholder="Nombre del mecánico" [(ngModel)]="mecanico">
                  </div>

                  <div class="col col-12 text-left">
                    <label for="fecha" class="col-form-label" >Problema/Antecedente</label>
                      <textarea class="form-control" [disabled]="!estado" [(ngModel)]="problema" id="problema" placeholder="Ingrese el problema o la causa del mantenimiento."></textarea>
                  </div>

                  <div class="col col-12 text-left">
                    <label for="fecha" class="col-form-label" >Trabajo Realizado</label>
                      <textarea class="form-control" [disabled]="!estado" [(ngModel)]="observacion" id="observacion" placeholder="Ingrese diagnostico general del mantenimiento."></textarea>
                  </div>


                  <div class="col col-12">
                    <section *ngIf="mantservicios.length > 0">
                      <div class="text-left mt-5">SERVICIOS MANTENIMIENTO:</div>
                      <div class="cav-radius mt-1"></div>
                      <div class="table-responsive">

                         <table class="table" style="border: solid 1px #cccccc;">
                          <thead class="thead-dark">
                           <tr class="bg-info text-white small">
                              <th scope="col" class="text-left">SERVICIO</th>
                              <th scope="col" class="text-right one-line">$ UNID.</th>
                              <th scope="col" class="text-center">CANTIDAD.</th>
                              <th scope="col" class="text-right">TOTAL</th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                           </tr>
                          </thead>
                           <tbody>
                           <tr class="small" *ngFor="let item of mantservicios ;let i = index; let impar = odd;let par = even;" [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}" (click)="tractive = i" >
                             <td class="text-left">{{item.serviciodescripcion}}</td>
                             <td class="text-right">$ {{item.valorunidad | number}}</td>
                             <td class="text-center">{{item.cantidad | number}}</td>
                             <td class="text-right">$ {{item.valorunidad*item.cantidad | number}}</td>
                             <td><img *ngIf="estado" src="assets/GENERAL/mod.png" (click)="modServicio(item)" width="30"></td>
                             <td><img *ngIf="estado" src="assets/GENERAL/delete.png"  (click)="deleteServicio(i)" width="30"></td>
                           </tr>

                          </tbody>
                         </table>

                     </div>
                    </section>

                    <div *ngIf="mantservicios.length==0" class="col col-12 alert alert-danger p-2 small mt-4" style="margin: 0px auto;">
                      No hay Servicios agregados al mantenimiento.
                    </div>

                  </div>
                  <div *ngIf="estado" class="col col-12 text-left mt-5">
                    <div class="row" style="border:solid 1px #cccccc;border-radius: 10px;padding: 10px;">
                      <div class="col col-8 small mb-3">Agregar Nuevo Servicio</div>

                      <div class="col col-12">
                        <label for="fecha" class="col-form-label" >Categoría del Servicio</label>
                        <select class="custom-select" [disabled]="newservicio.mantenimiento==1" [(ngModel)]="categoria" (change)="getServiciosCat()" >
                          <option *ngFor=" let item of categorias" [ngValue]="item.codigo" >{{item.descripcion}}</option>
                        </select>
                      </div>

                      <div class="col col-12 ">
                        <label for="fecha" class="col-form-label" >Servicio</label>
                        <select class="custom-select" [disabled]="newservicio.mantenimiento==1" [(ngModel)]="newservicio.servicio" >
                          <option *ngFor=" let item of servicioscategoria" [ngValue]="item.codigo" >{{item.descripcion}}</option>
                        </select>
                      </div>

                      <div class="col col-8 ">
                        <label for="fecha" class="col-form-label" >Proveedor</label>
                        <select class="custom-select" [(ngModel)]="newservicio.proveedor" >
                          <option *ngFor=" let item of proveedores" [ngValue]="item.codigo" >{{item.descripcion}}</option>
                        </select>
                      </div>
                      <div class="col col-4 ">
                        <label for="factura" class="col-form-label" >Factura</label>
                        <input  type="text" class="form-control" [(ngModel)]="newservicio.numfactura" name="factura" placeholder="Número de la factura">
                      </div>


                      <div class="col col-12 text-left">
                        <label for="fecha" class="col-form-label" >Observación</label>
                          <textarea class="form-control" [(ngModel)]="newservicio.observacion" id="observacion" placeholder="Ingrese diagnostico general del mantenimiento."></textarea>
                      </div>

                      <div class="col col-4">
                        <label for="fecha" class="col-form-label" >Valor Unid.</label>
                        <input  type="number" class="form-control" [(ngModel)]="newservicio.valorunidad" (focus)="validCero()" name="id" placeholder="Valor">
                      </div>
                      <div class="col col-4">
                        <label for="fecha" class="col-form-label" >Cantidad</label>
                        <input  type="number" class="form-control" [(ngModel)]="newservicio.cantidad" (focus)="validCero()" name="id" placeholder="Cantidad">
                      </div>
                      <div class="col col-4">
                        <label for="fecha" class="col-form-label" >Total</label>
                        <input  type="number" class="form-control" disabled [(ngModel)]="newservicio.valorunidad * newservicio.cantidad" name="total" placeholder="Total">
                      </div>


                      <div *ngIf="error2!==''" class="col col-12 alert alert-danger p-2 small mt-2" style="margin: 0px auto;">
                        {{error2}}
                      </div>

                      <div class="col col-12 m-3 text-center">
                        <button type="button" class="btn btn-warning" (click)="addServicio()" >Agregar Servicio</button>
                      </div>

                    </div>

                   </div>
                   </div>

                   <div *ngIf="!estado || codigo > 0"  class="row ">
                    <div  class="col-12 text-left" style="padding-top: 12px;" >
                      <div class="col-form-label">Imágenes Facturas:</div>

                      <div>
                        <a *ngFor="let img of imagenes" [href]="img.url " target="_blank"><div  class="img-mant" [ngStyle]="{'background-image': 'url(' + img.url + ')'}"></div></a>
                        <div class="img-mant" >
                          <app-up-file
                            tipo="select"
                            [urlServer]="'/mantenimiento/'+codigo+'/file'"
                            (done)="upImgResult($event)"
                            [btLabel]="'Imagen'"
                            [activefile]="upimagen"
                            [typeFile]="'.jpg,.jpeg,.png'"></app-up-file>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div  class="row " style="max-width: 600px;margin: 0px auto; ">

                    <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error !== '' " >
                      {{error}}
                    </div>

                    <div  class="btn-conten mt-5">
                    <div  *ngIf="estado" class="butomForm" (click)="registrarMantenimiento()">
                      <div class="img save"></div>
                      <div>{{!isUpdate ? 'Registrar' : 'Actualizar'}} </div>
                    </div>

                    <div class="butomForm" (click)="descartar()">
                        <div class="img discard"></div>
                      <div>Descartar</div>
                    </div>
                  </div>
                  </div>



              </section>



            </div>





   </div>




  </div>
 </div>
