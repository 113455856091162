import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { usuario } from "src/app/models/usuario";
import { NgHttpService } from "../http/ng-http.service";

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  public sesion: usuario;
  public sesion$: BehaviorSubject<usuario>;

  constructor(private http: NgHttpService) {
    this.sesion$ = new BehaviorSubject<usuario>(this.sesion);
  }

  dispatch(sesion: usuario) {
    //if (sesion != null) {
      this.sesion = sesion;
    /*} else {

    }*/
    this.sesion$.next(this.sesion);
  }

  update() {
    this.sesion$.next(this.sesion);
    }
  select$(): Observable<usuario> {
    return this.sesion$.asObservable();
  }

  getSesion(){
    return this.http.get('sesion');
  }



  validOpcion(codigo: number) {
    if(!this.sesion) return false;
    let result = false;
    this.sesion.modulos.forEach(modulo => {
       modulo.opciones.forEach(opcion => {
           if(opcion.codigo==codigo){
            result = true;
           }
       });
    });
    return result;
  }

}
