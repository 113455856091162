<div class="row mb-5" style="max-width: 1000px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Nuevo Accidente</h3></div>


     <div class="form-group row" style="max-width: 400px;margin: 0px auto; " >

      <div class="col col-10  text-left">
       <label for="fecha" class="col-form-label" >Placa Vehículo</label>
       <div >
           <input type="text"
           class="form-control"
           name="id"
           placeholder="Placa del vehículo"
           [(ngModel)]="placavehiculo"
           (keydown.enter)="consultarPlaca()"
           [disabled]="isUpdate"
           >
       </div>
      </div>
      <div class="col col-2  text-left p-0">
         <img *ngIf="!isUpdate" src="assets/GENERAL/buscar3.png" style="margin-top: 40px;" width="30" (click)="consultarPlaca()"/>
      </div>

      <div *ngIf="error !== '' " class="col col-12 mt-5 mb-5">
        <div class="row alert alert-danger p-2 small mb-4"   style="max-width: 350px;margin: 0px auto;">
          {{error}}
        </div>
      </div>
   </div>


    <div *ngIf="vehiculo" class="row" style="padding: 10px; max-width: 650px;margin: 0px auto;" >

            <div class="col-12 p-0">

              <section class="mt-5 mb-5 text-left">
                <div class="mb-4 mt-4"><b>DATOS VEHÍCULO</b></div>
                <div class="row " style="border: solid 1px #cccccc;border-radius: 20px;">
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Placa:</div>
                    <div><b>{{vehiculo.placa}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Marca:</div>
                    <div><b>{{vehiculo.marcadescrip}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Tipo:</div>
                    <div><b>{{vehiculo.tipodescrip}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Color:</div>
                    <div><b>{{vehiculo.color}}</b></div>
                  </div>

                </div>

              </section>


<!---->
              <section>

                <div style="border:solid 1px #cccccc; border-radius: 10px;padding: 20px;max-width: 640px;margin: 0px auto;">

                  <form [formGroup]="formGroup" >



                  <div class="col col-12 text-left">
                    <label for="fecha" class="col-form-label" >Fecha Accidente</label>
                    <input type="text"
                    class="form-control"
                    #dp="bsDatepicker"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    [maxDate]="maxDate"
                    formControlName="fecha">
                  </div>


                  <div class="col col-12 text-left">
                    <label for="lugar" class="col-form-label" >Lugar</label>
                    <input type="text" class="form-control" placeholder="Lugar del accidente" formControlName="lugar">
                  </div>

                  <div class="col col-12 text-left">
                    <label for="area" class="col-form-label" >Área </label>
                    <input type="text" class="form-control" placeholder="Área del accidente" formControlName="area">
                  </div>



                  <!-- <div class="col col-12 text-left">
                    <label for="conductor" class="col-form-label" >Conductor</label>
                    <input type="text" class="form-control" placeholder="Conductor del vehículo" formControlName="conductor">
                  </div> -->

                  </form>

                  <div class="col col-12 text-left">
                    <label class="col-form-label" >Conductor</label>
                    <app-search-conductor (onSelect)="onSelectConductor($event)" [setText]="asyncSelected"></app-search-conductor>
                  </div>



                  <form  [formGroup]="formGroup" >

                  <div class="col col-12 text-left">
                    <label for="descripcion" class="col-form-label" >Descripción</label>
                      <textarea class="form-control" formControlName="descripcion" placeholder="Ingrese descripción del comparendo."></textarea>
                  </div>



                   </form>
                   </div>


                   <div *ngIf="!estado || isUpdate"  class="row ">
                    <div  class="col-12 text-left" style="padding-top: 12px;" >
                      <div class="col-form-label">Imágenes Accidente:</div>

                      <div>
                        <a *ngFor="let img of imagenes" [href]="img.url " target="_blank"><div  class="img-acc" [ngStyle]="{'background-image': 'url(' + img.url + ')'}"></div></a>
                        <div class="img-acc" >
                          <app-up-file
                            tipo="select"
                            [urlServer]="'/accidente/'+formGroup.controls.codigo.value+'/file'"
                            (done)="upImgResult($event)"
                            [btLabel]="'Imagen'"
                            [activefile]="upimagen"
                            [typeFile]="'.jpg,.jpeg,.png'"></app-up-file>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div  class="row " style="max-width: 600px;margin: 0px auto; ">

                    <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error !== '' " >
                      {{error}}
                    </div>

                    <div class="btn-conten mt-5">
                    <div  *ngIf="estado" class="butomForm" (click)="registrarAccidente()">
                      <div class="img save"></div>
                      <div>Registrar</div>
                    </div>

                    <div class="butomForm" (click)="descartar()">
                        <div class="img discard"></div>
                      <div>Descartar</div>
                    </div>
                  </div>
                  </div>








              </section>



            </div>





   </div>




  </div>
 </div>
