<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Configurar tipo de promedio del vehiculo <b>{{placa}}</b></h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3 pt-2">


  <div class="row">
    <div class="col col-12">
      <label for="fecha" class="col-form-label" >Tipo de promedio</label>
      <select class="custom-select"  [(ngModel)]="promtipo">
        <option value="0" >Promedio Calculado</option>
        <option value="1" >Promedio Fijo</option>
      </select>
    </div>

    <div class="col col-12 text-left" *ngIf="promtipo ==1">
      <label for="kilometraje" class="col-form-label" >Promedio KM</label>
      <input type="text" class="form-control" name="kilometraje" placeholder="Kilometraje" [(ngModel)]="km">
      <small id="emailHelp" class="form-text text-muted text-left"> Actual {{promfijo | number}} km.</small>
    </div>
  </div>

  <div class="text-center mt-4 mb-4">
    <button  (click)="bsModalRef.hide()" type="button" class="btn btn-warning" style="margin-right: 10px;">Cerrar</button> <button  (click)="guardar()" type="button" class="btn btn-warning">Guardar</button>
  </div>

</div>



