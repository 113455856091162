import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShareModule } from './share/share.module';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ConsultaVehiculoComponent } from './consulta-vehiculo/consulta-vehiculo.component';
import { RevisionComponent } from './revision/revision.component';
import { MantenimientoComponent } from './mantenimiento/mantenimiento.component';
import { ComparendoComponent } from './comparendo/comparendo.component';
import { AccidenteComponent } from './accidente/accidente.component';
import { ConsultaRevisionComponent } from './consulta-vehiculo/consulta-revision/consulta-revision.component';
import { ConsultaMantenimientoComponent } from './consulta-vehiculo/consulta-mantenimiento/consulta-mantenimiento.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { AdministracionModule } from './administracion/administracion.module';
import { FirmaComponent } from './revision/firma/firma.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ConsultaAccidenteComponent } from './consulta-vehiculo/consulta-accidente/consulta-accidente.component';
import { ConsultaComparendoComponent } from './consulta-vehiculo/consulta-comparendo/consulta-comparendo.component';
import { NovedadesGraficaComponent } from './consulta-vehiculo/novedades-grafica/novedades-grafica.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { ConfigPromedioComponent } from './consulta-vehiculo/config-promedio/config-promedio.component';
import { CombustibleComponent } from './combustible/combustible.component';
import { ConsultaCombustibleComponent } from './consulta-vehiculo/consulta-combustible/consulta-combustible.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { GraficaConductorComponent } from './home/grafica-conductor/grafica-conductor.component';
import { ResetTacometroComponent } from './administracion/vehiculo/reset-tacometro/reset-tacometro.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ConsultaVehiculoComponent,
    RevisionComponent,
    MantenimientoComponent,
    ComparendoComponent,
    AccidenteComponent,
    ConsultaRevisionComponent,
    ConsultaMantenimientoComponent,
    FirmaComponent,
    ConsultaAccidenteComponent,
    ConsultaComparendoComponent,
    NovedadesGraficaComponent,
    ConfigPromedioComponent,
    CombustibleComponent,
    ConsultaCombustibleComponent,
    GraficaConductorComponent,
    SplashScreenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShareModule,
    CoreModule,
    AdministracionModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    RatingModule.forRoot(),
    SignaturePadModule,
    GoogleChartsModule.forRoot(),
    ButtonsModule.forRoot(),

  ],
  providers: [ConsultaRevisionComponent,
    ConsultaMantenimientoComponent,
    ConsultaAccidenteComponent,
    ConsultaComparendoComponent,
    FirmaComponent,
    NovedadesGraficaComponent,
    ConfigPromedioComponent,
    ResetTacometroComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
