import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VehiculoService } from '../vehiculo.service';

@Component({
  selector: 'app-config-promedio',
  templateUrl: './config-promedio.component.html',
  styleUrls: ['./config-promedio.component.css']
})
export class ConfigPromedioComponent implements OnInit {
  public vehiculo: number;
  public placa: string;
  public promcalculado: number;
  public promfijo:number;
  public promtipo: number;

  public km: number =0;
  constructor(public bsModalRef: BsModalRef, public service: VehiculoService) { }

  ngOnInit(): void {
    this.km = this.promfijo;
  }


  guardar() {
    const prom={
      vehiculo: this.vehiculo,
      tipo: this.promtipo,
      prom: this.promtipo == 0 ? this.promfijo : this.km
    }

    this.service.updatePromVehiculo(prom).subscribe(r => {
       if(r.estado) {
        this.bsModalRef.hide();
       }
    });
  }

}
