<div
  class="row mb-5"
  style="max-width: 1000px; margin: 0px auto; min-height: 500px"
>
  <div class="col font-md text-center">
    <div class="mt-5 mb-5 title-text"><h3>Nuevo Comparendo</h3></div>

    <div class="form-group row" style="max-width: 400px; margin: 0px auto">
      <div class="col col-10 text-left">
        <label for="fecha" class="col-form-label">Placa Vehículo</label>
        <div>
          <input
            type="text"
            class="form-control"
            name="id"
            placeholder="Placa del vehículo"
            [(ngModel)]="placavehiculo"
            (keydown.enter)="consultarPlaca()"
            [disabled]="isUpdate"
          />
        </div>
      </div>
      <div class="col col-2 text-left p-0">
        <img *ngIf="!isUpdate"
          src="assets/GENERAL/buscar3.png"
          style="margin-top: 40px"
          width="30"
          (click)="consultarPlaca()"
        />
      </div>

      <div *ngIf="error !== ''" class="col col-12 mt-5 mb-5">
        <div
          class="row alert alert-danger p-2 small mb-4"
          style="max-width: 350px; margin: 0px auto"
        >
          {{ error }}
        </div>
      </div>
    </div>

    <div
      *ngIf="vehiculo"
      class="row"
      style="padding: 10px; max-width: 650px; margin: 0px auto"
    >
      <div class="col-12 p-0">
        <section class="mt-5 mb-5 text-left">
          <div class="mb-4 mt-4"><b>DATOS VEHÍCULO</b></div>
          <div
            class="row"
            style="border: solid 1px #cccccc; border-radius: 20px"
          >
            <div class="col col-12 col-sm-3 mt-3 mb-3">
              <div class="small">Placa:</div>
              <div>
                <b>{{ vehiculo.placa }}</b>
              </div>
            </div>
            <div class="col col-12 col-sm-3 mt-3 mb-3">
              <div class="small">Marca:</div>
              <div>
                <b>{{ vehiculo.marcadescrip }}</b>
              </div>
            </div>
            <div class="col col-12 col-sm-3 mt-3 mb-3">
              <div class="small">Tipo:</div>
              <div>
                <b>{{ vehiculo.tipodescrip }}</b>
              </div>
            </div>
            <div class="col col-12 col-sm-3 mt-3 mb-3">
              <div class="small">Color:</div>
              <div>
                <b>{{ vehiculo.color }}</b>
              </div>
            </div>
          </div>
        </section>

        <!---->
        <section>
          <div
            style="
              border: solid 1px #cccccc;
              border-radius: 10px;
              padding: 20px;
              max-width: 640px;
              margin: 0px auto;
            "
          >
            <form [formGroup]="formGroup">
              <div class="col col-12 text-left">
                <label for="comparendo" class="col-form-label"
                  >Numero Comparendo</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Numero del comparendo"
                  formControlName="comparendo"
                />
              </div>

              <div class="col col-12 text-left">
                <label for="fecha" class="col-form-label"
                  >Fecha Comparendo</label
                >
                <input
                  type="text"
                  class="form-control"
                  #dp="bsDatepicker"
                  bsDatepicker
                  [bsConfig]="bsConfig"
                  [maxDate]="maxDate"
                  formControlName="fecha"
                />
              </div>

              <div class="col col-12 text-left">
                <label for="conductor" class="col-form-label"
                  >Conductor</label
                >
                <app-search-conductor (onSelect)="onSelectConductor($event)" [setText]="asyncSelected"></app-search-conductor>

              </div>

              <div class="col col-12 text-left">
                <label for="trabajo" class="col-form-label"
                  >¿Asociado Trabajo?</label
                >
                <select class="custom-select" formControlName="trabajo">
                  <option [ngValue]="1">Si</option>
                  <option [ngValue]="0">No</option>
                </select>
              </div>

              <div class="col col-12 text-left">
                <label for="tiporesponsable" class="col-form-label"
                  >Tipo Responsable</label
                >
                <select class="custom-select" formControlName="tiporesponsable">
                  <option [ngValue]="1">Conductor</option>
                  <option [ngValue]="2">Propietario</option>
                </select>
              </div>

              <div class="col col-12 text-left">
                <label for="idresponsable" class="col-form-label"
                  >Id Responsable</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Identificación del responsable"
                  formControlName="idresponsable"
                />
              </div>

              <div class="col col-12 text-left">
                <label for="conductor" class="col-form-label"
                  >Responsable</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nombre del responsable"
                  formControlName="responsable"
                />
              </div>

              <div class="col col-12 text-left">
                <label for="descripcion" class="col-form-label"
                  >Descripción</label
                >
                <textarea
                  class="form-control"
                  formControlName="descripcion"
                  placeholder="Ingrese descripción del comparendo."
                ></textarea>
              </div>

              <div class="col col-12 text-left">
                <label for="valor" class="col-form-label">Valor</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Ingrese el valor del comparendo"
                  formControlName="valor"
                />
              </div>

              <div [hidden]="isUpdate" class="col col-12 text-left">
                <label for="estadopago" class="col-form-label"
                  >Estado Pago</label
                >

                <select class="custom-select" formControlName="estadopago" >
                  <option [ngValue]="1">Pagado</option>
                  <option [ngValue]="0">Pendiente</option>
                </select>
              </div>
            </form>
          </div>

          <div class="row" style="max-width: 600px; margin: 0px auto">
            <div
              class="col col-12 alert alert-danger p-2 small mt-4 mb-4"
              *ngIf="error !== ''"
            >
              {{ error }}
            </div>

            <div class="btn-conten mt-5">
              <div class="butomForm" (click)="registrarComparendo()">
                <div class="img save"></div>
                <div>{{isUpdate?'Actualizar':'Registrar'}}</div>
              </div>

              <div class="butomForm" (click)="descartar()">
                <div class="img discard"></div>
                <div>Descartar</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
