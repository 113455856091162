import { Injectable } from '@angular/core';
import { NgHttpService } from '../core/http/ng-http.service';
import { Mantenimiento } from '../models/mantenimiento';

@Injectable({
  providedIn: 'root'
})
export class MantenimientoService {

  constructor(private http: NgHttpService) { }

  ini(){
    return this.http.get('mantenimiento/ini');
  }

registrarMantenimiento(mant: Mantenimiento){
   return this.http.post('mantenimiento', mant);
}

deleteMantenimiento(codigo: number) {
  return this.http.delete('mantenimiento/'+codigo);
}

getMantenimiento(codigo: number) {
   return this.http.get('mantenimiento/all/'+codigo);
}

}
