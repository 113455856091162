import { Injectable } from '@angular/core';
import { NgHttpService } from '../core/http/ng-http.service';
import { usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private http: NgHttpService) { }

  login(user: usuario) {
    return this.http.post('login', user);
  }


  salir(){
    return this.http.get('login/salir');
  }



}
