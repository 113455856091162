
  <div class="modal-header">
      <h4 class="modal-title pull-left"><img src="assets/GENERAL/warning1.png" width="30" class="mr-2" >
         {{title}}
        </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"  [innerHTML]="mensaje">
    </div>
     <div class="modal-footer">
          <button type="button" class="btn  btn-orange" data-dismiss="modal"  (click)="onConfirm()">Si</button>
          <button type="button" class="btn  btn-orange" data-dismiss="modal"  (click)="onCancel()">No</button>
    </div>


