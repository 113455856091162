import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { ScrollingService } from './core/services/scrolling.service';
import { SesionService } from './core/services/sesion.service';
import { WaitService } from './core/services/wait.service';
import { usuario } from './models/usuario';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public bannerShow: boolean;
  public upShow: boolean;
  public wait: boolean = false;

  public sesion: usuario;



  constructor(private scroll: ScrollingService,
              private waitservice: WaitService,
              private sesions: SesionService,
              @Inject(DOCUMENT) private document: any,
             // private translate: TranslateService,
              private render: Renderer2) {

                this.waitservice.select$().subscribe( result => {
                  this.wait = result;
                  if (result) {
                  this.render.addClass(this.document.body, 'fixed');
                  // this.document.body.scrollIntoView();  esto hace que se haga scroll top y cuando hay modal pierde el foco

                  } else {
                  if (!this.waitservice.disable) {
                  this.render.removeClass(this.document.body, 'fixed');
                  }
                  }

                  }
                  );
    }





  ngOnInit(): void {

    this.sesions.select$().subscribe( s=> {
      this.sesion = s;
    });


    this.scroll.select$().subscribe( s => {
    this.bannerShow = s < 50 ;
    this.upShow = s > 790;
    });
    //this.onScrolling();
    //AOS.init();





   // AOS.init();
   this.sesions.getSesion().subscribe(result => {
      this.sesions.dispatch(result);
   });
  }

    onScrolling() {
      this.scroll.checkScolling();
    }




up(){
  window.scroll(0,0);
}
}
