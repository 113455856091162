import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AdministracionService } from 'src/app/administracion/administracion.service';

@Component({
  selector: 'app-search-conductor',
  templateUrl: './search-conductor.component.html',
  styleUrls: ['./search-conductor.component.css']
})
export class SearchConductorComponent implements OnInit {

  @Output() public onSelect = new EventEmitter();

  @Input('setText') set setText(value: string) {
      this.asyncSelected = value;
      this.nameConductor = value;
  }

  asyncSelected: string;
  nameConductor: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSourceUsuarios: Observable<any>;

  constructor( private adminservice: AdministracionService,) { }

  ngOnInit(): void {
    this.dataSourceUsuarios = Observable.create((observer: any) => {
      if (this.asyncSelected.length < 4) { return; }
     // this.wait.disable = true;
      observer.next(this.asyncSelected);
      }).pipe( mergeMap((token: string) =>  this.filterResultsUsers(token) ) );
  }

  filterResultsUsers(token: string) {
    // this.wait.disable = true;
     return this.adminservice.filtrarUsuarios(token);
   }

   changeTypeaheadLoading(e: boolean): void {
     this.typeaheadLoading = e;
    // this.wait.disable = false;
   }

   typeaheadOnSelectUser(e: TypeaheadMatch): void {
     //this.formGroup.controls.conductor.setValue(e.item.codigo);
     //this.formGroup.controls.nombreconductor.setValue(e.item.nombre);
     this.nameConductor = e.item.nombre;
     this.onSelect.emit(e.item);

   }

  clear() { this.asyncSelected = ''; }
}
