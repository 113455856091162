<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Detalles Tanqueo</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">

  <section *ngIf="consumo">

    <div *ngIf="vehiculo" class="content" id="print-section">
      <div class="title"><b>DATOS VEHÍCULO</b></div>
      <div class="content-vehiculo">
        <div>
          <div class="cel-vehiculo">
            <div class="small">Placa:</div>
            <div>
              <b>{{ vehiculo.placa }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Marca:</div>
            <div>
              <b>{{ vehiculo.marcadescrip }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Tipo:</div>
            <div>
              <b>{{ vehiculo.tipodescrip }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Color:</div>
            <div>
              <b>{{ vehiculo.color }}</b>
            </div>
          </div>
        </div>
      </div>



      <div class="content-float">
        <div>
          <div class="table-cell1">Conductor vehículo :</div>
          <div class="table-cell2">
            <b>{{consumo.nombreconductor}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Fecha :</div>
          <div class="table-cell2">
            <b>{{consumo.fecha}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Fecha Registro : </div>
          <div class="table-cell2">
            <b>{{consumo.fechasis}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Consumo :</div>
          <div class="table-cell2">
            <b>{{consumo.consumo}} Gal.</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Km Inicial :</div>
          <div class="table-cell2" *ngIf="!isEditKm">
            <b>{{ consumo.kminicial | number }} km. </b>
            <!-- <img
              *ngIf="user.perfil == 1 && !isEditKm"
              src="assets/GENERAL/mod.png"
              class="mt-2"
              title="Modificar Kilometraje"
              width="20"
              (click)="editKm(true)"
            /> -->
          </div>

          <!-- <div class="table-cell2" *ngIf="isEditKm">
            <div class="content-float">
            <input
              type="text"
              class="form-control txt-float"
              placeholder="Kilometraje"
              [(ngModel)]="kmTemp"
            />
            <img
              src="assets/GENERAL/update.png"
              title="Actualizar Kilometraje"
              class="mt-2"
              width="20"
              (click)="updateKm()"
            />
            <img
              src="assets/GENERAL/descartar.png"
              title="Descartar Cambios"
              class="mt-2"
              (click)="editKm(false)"
              width="20"
            />
          </div>
          <div><small id="emailHelp" class="form-text text-muted text-left"> Anterior: {{ultKm | number}} km.</small></div>
        </div> -->
        </div>

        <div>
          <div class="table-cell1">Km Final :</div>
          <div class="table-cell2">
            <b>{{consumo.kmfinal}} Km</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Km Recorridos :</div>
          <div class="table-cell2">
            <b>{{consumo.km}} Km</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Valor :</div>
          <div class="table-cell2">
            <b>$ {{consumo.valortotal | number}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Usuario Reg. :</div>
          <div class="table-cell2">
            <b>{{consumo.usuarionombre}}</b>
          </div>
        </div>

      </div>

      </div>

      <br />
      <div class="row">
        <div class="col text-center">

          <button *ngIf="user.perfil == 1" type="button" (click)="modTanqueo()" class="btn btn-warning mr-2" >
            Modificar
          </button>

          <button type="button" (click)="print()" class="btn btn-warning">
            Imprimir
          </button>
        </div>

      </div>

      <br />
  </section>

</div>
