import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SelectOption } from 'src/app/models/select-option';
import { usuario } from 'src/app/models/usuario';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdministracionService } from '../../administracion.service';

@Component({
  selector: 'app-new-usuario',
  templateUrl: './new-usuario.component.html',
  styleUrls: ['./new-usuario.component.css']
})
export class NewUsuarioComponent implements OnInit {
  public error: string;
  public tiposid: SelectOption[];
  public perfiles: SelectOption[];
  public estados: SelectOption[];
  public sub: any;

  public usuario: usuario;

  public estado: number =0;

  constructor(private service: AdministracionService,
              private mensaje: ModalMensajeService,
              private sesions: SesionService,
              private route: ActivatedRoute,
              private router: Router) {
    this.error ='';
    this.perfiles=[];
    this.usuario = {
      codigo: 0,
      tipoid: 1,
      identificacion: '',
      nombre: '',
      perfil: 0,
      username: '',
      password: '',
      estado: -1,
      updatepassword: false
    }

    this.estados=[{codigo: 1, descripcion: 'Activo'}, {codigo:0, descripcion: 'Inactivo'}]

    this.sub = this.route.params.subscribe(params => {
      let key = false;
      if(params.codigo){
        this.service.consultarCod(params.codigo).subscribe(result => {
          if(result.estado == 1) {
            this.usuario = result.usuario;
            this.estado =1;
          }else{
            this.estado = 0;
          }
        });
       }
     });

  }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(15)) {
      this.router.navigate(['/inicio']);
    }
    this.service.iniUsuarios().subscribe(result => {
      this.tiposid = result.tiposid;
      this.perfiles = result.perfiles;
    });
  }


  consultarUsuario(){
    if(this.usuario.tipoid !== 0 || this.usuario.identificacion !== '') {
      this.service.consultarId(this.usuario.tipoid, this.usuario.identificacion).subscribe(result => {
         if(result.estado == 1) {
           this.usuario = result.usuario;
           this.estado =1;
         }else{
           this.estado = 0;
         }
      });
    }
  }

  valid(){
    if(!this.usuario.tipoid) {
      this.error = 'Seleccione el tipo de identificación.';
      return false;
    }
    if(this.usuario.identificacion.trim() == '') {
      this.error = 'Ingrese la identificación.';
      return false;
    }

    if(this.usuario.nombre.trim() == '') {
      this.error = 'Ingrese el nombre completo del usuario.';
      return false;
    }

   if(!this.usuario.perfil) {
     this.error = 'Seleccione el perfil del usuario.';
     return false;
   }

   if(!this.usuario.estado == undefined || this.usuario.estado == null) {
    this.error = 'Seleccione el estado del usuario.';
    return false;
  }

  if(this.usuario.username.trim() == '') {
    this.error = 'Ingrese el nombre de usuario (correo electrónico).';
    return false;
  }

  if(this.estado ==0 || this.usuario.updatepassword){
    if(this.usuario.password.trim() == '' || this.usuario.password.length < 5 ) {
      this.error = 'Ingrese contraseña valida debe tener por lo menos 5 caracteres.';
      return false;
    }
  }

  this.error ='';
  return true;

  }

  guardar(){
     if(!this.valid()){
       return;
     }

     this.service.insertUser(this.usuario).subscribe(result => {
       if(result.estado) {
          this.mensaje.showMensaje('Usuario creado con éxito.', 'info');
          this.descartar();
       }else{
        this.mensaje.showMensaje(result.mensaje =='' ? 'No se pudo realizar la operación.': result.mensaje, 'error');
        this.error = result.mensaje;
       }
     });
  }

  update(){
    if(!this.valid()){
      return;
    }
    this.usuario.updatepassword = this.usuario.updatepassword ? this.usuario.updatepassword : false;
    this.service.updateUser(this.usuario).subscribe(result => {
      if(result.estado) {
         this.mensaje.showMensaje('Usuario actualizado con éxito.', 'info');
         this.descartar();
      }else{
       this.mensaje.showMensaje('No se pudo realizar la operación.', 'error');
      }
    });
  }

  descartar(){
    this.usuario = {
      codigo: 0,
      tipoid: 1,
      identificacion: '',
      nombre: '',
      perfil: 0,
      username: '',
      password: '',
      estado: -1,
      updatepassword: false
    }
    this.estado =0;
    this.error='';
  }
}
