import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { SesionService } from "../services/sesion.service";
import { WaitService } from "../services/wait.service";
import { TokenStoreService } from "./token-store.service";
import { catchError, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  private token = '';
  constructor(private router: Router,
              private tokenStore: TokenStoreService,
              private wait: WaitService,
              private sesion: SesionService) {
    this.tokenStore.select$().subscribe(token => {
      this.token = token;
    });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const xhr: any = this.token !== '' ? req.clone({headers: req.headers.set('Authorization', this.token )}) : req;

  // setTimeout(() => {
    this.wait.show();
  // }, 1);
    return next.handle(xhr).pipe(tap(evt => {
        if (evt instanceof HttpResponse) {
          this.wait.hidden();
        }
      }), catchError(this.handleError.bind(this)));
  }

  private handleError(err: any) {
    const unauthorizedcode = 403;
    if (err instanceof HttpErrorResponse) {

      if (err.status == unauthorizedcode) {
        this.tokenStore.dispatch('');
        this.closeAllModals();
        this.sesion.dispatch(null);
          this.router.navigate(['login']);

      }
      this.wait.hidden();
    }
    return throwError(err);
  }


  closeAllModals() {
    let element: HTMLElement;
    const elements = document.getElementsByClassName('close pull-right');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < elements.length; i++) {
      element = elements[i] as HTMLElement;
      element.click();
    }
  }

}

