import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { MantenimientoService } from 'src/app/mantenimiento/mantenimiento.service';
import { SelectOption } from 'src/app/models/select-option';
import { servicioRecurrente } from 'src/app/models/serviciorecurrente';
import { AdministracionService } from '../administracion.service';

@Component({
  selector: 'app-servicios-recurrentes',
  templateUrl: './servicios-recurrentes.component.html',
  styleUrls: ['./servicios-recurrentes.component.css']
})
export class ServiciosRecurrentesComponent implements OnInit {
  public tractive: number;
  public error: string;

  public categorias: SelectOption[];
  public servicioscategoria: SelectOption[]=[];
  public servicios: SelectOption[];

  public serviciosR: servicioRecurrente[];


  public categoria: number;
  public newservicio: servicioRecurrente;
  public estado: number=0;

  public tipos: SelectOption[];


  constructor(private service: AdministracionService ,
     private sesions: SesionService,
    private router: Router) {
    this.categorias = [];
    this.servicioscategoria = [];
    this.servicios  = [];

    this.serviciosR =[];
    this.error ='';

    this.newservicio={
      codigo:0,
      descripcion:''
    }

    this.tipos =[{codigo: 1, descripcion:'Kilómetros'}, {codigo: 2, descripcion: 'Días'}];
  }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(14)) {
      this.router.navigate(['/inicio']);
    }
    this.service.iniServiciosR().subscribe(result => {
      this.categorias = result.categorias;
      this.servicios = result.servicios;
      this.getServiciosR();
    });
  }


  modServicio(servicio: SelectOption) {
    let cat =0;
    this.servicios.forEach(element => {
      if(element.codigo == servicio.codigo) {
        element.estado=1;
        cat = element.aux2;
      }
    });

    this.categoria = cat;
    this.getServiciosCat();
    this.newservicio =JSON.parse(JSON.stringify(servicio));
    this.newservicio.estado=1;
  }

  valid(){
    this.error ='';
    if(!this.newservicio.codigo || this.newservicio.codigo == 0){
      this.error ='Seleccione un servicio.';
      return;
    }

    if(this.newservicio.tipo == 1) {
      if(!this.newservicio.kilometraje || this.newservicio.kilometraje <= 0) {
        this.error ='Ingrese el kilometraje.';
        return false;
      }

      if(!this.newservicio.kmnotificar || this.newservicio.kmnotificar <= 0) {
        this.error ='Ingrese el kilometraje de alerta.';
        return false;
      }
    }else{

      if(!this.newservicio.dias || this.newservicio.dias <= 0) {
        this.error ='Ingrese número de días.';
        return false;
      }

      if(!this.newservicio.diasnotificar || this.newservicio.diasnotificar <= 0) {
        this.error ='Ingrese el número de días de alerta.';
        return false;
      }
    }




    return true;
  }


  insertServicio(){
   if(!this.valid()){return;}
   this.service.insertServicioR(this.newservicio).subscribe(result => {
       this.serviciosR = result.servicios;
       this.descartarServicio();
    });
  }

  updateServicio(){
    if(!this.valid()){return;}
    this.service.updateServicioR(this.newservicio).subscribe(result => {
        this.serviciosR = result.servicios;
        this.descartarServicio();
     });
   }




  getServiciosCat() {
    this.servicioscategoria = [];
   this.servicios.forEach(element => {
       if(element.aux2 == this.categoria && element.estado !== 0) {
         this.servicioscategoria.push(element);
       }
   });

  }

  getServiciosR(){
    this.service.getServiciosR().subscribe(result => {
      this.serviciosR = result.servicios;
      this.descartarServicio();
    });
  }


  deleteServicio(codigo: number){
    this.service.deleteServicioR(codigo).subscribe(result => {
      this.serviciosR = result.servicios;
      this.descartarServicio();
   });
  }




 descartarServicio(){
   this.categoria =null;
  this.newservicio = {
    codigo: 0,
    descripcion: '',
    estado:0
   }

  this.servicioscategoria = [];

  this.servicios.forEach(element => {
    element.estado = 1;
  });

  this.servicios.forEach(element => {
    this.serviciosR.forEach(element2 => {
      if(element.codigo == element2.codigo) {
        element.estado = 0;
      }
    });
  });


}


}
