import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgHttpService } from 'src/app/core/http/ng-http.service';

@Injectable({
  providedIn: 'root'
})
export class PermisosService {

  constructor(private http: NgHttpService) { }

  getPerfiles() {
    return this.http.get('administracion/permisos/perfiles');
  }

  getPerfil(perfil: number) {
    return this.http.get('administracion/permisos/perfil/'+perfil);
  }

  savePerfil(perfil: any) {
    return this.http.post('administracion/permisos/perfil',perfil);
  }

  disablePerfil(perfil: number){
    return this.http.get('administracion/permisos/perfil/'+perfil+'/disable');
  }
}
