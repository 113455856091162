import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VehiculoService } from '../consulta-vehiculo/vehiculo.service';
import { SesionService } from '../core/services/sesion.service';
import { UtilService } from '../core/services/util.service';
import { Imagen } from '../models/imagen';
import { Vehiculo } from '../models/vehiculo';
import { ModalMensajeService } from '../share/modal-mensaje/modal-mensaje.service';
import { AccidenteService } from './accidente.service';

@Component({
  selector: 'app-accidente',
  templateUrl: './accidente.component.html',
  styleUrls: ['./accidente.component.css']
})
export class AccidenteComponent implements OnInit {

  public tractive: number;
  public sub: any;
  public bsConfig = { dateInputFormat: 'YYYY/MM/DD',
  containerClass: 'theme-default',
  isAnimated: false,
  adaptivePosition: true };
  maxDate = new Date();

  public vehiculo: Vehiculo;

  public placavehiculo: string;
  public error: string;
  public error2: string;

  public formGroup: UntypedFormGroup;
  public upimagen: boolean = false;
  public estado: boolean = true;
  public imagenes: Imagen[]=[];

  public codigo:number=0;
  public isUpdate: boolean;
  public asyncSelected: string;

  constructor(private service: AccidenteService,
    private route: ActivatedRoute,
    private router: Router,
    private servicevehiculo: VehiculoService,
    private util: UtilService,
    private formBuilder: UntypedFormBuilder,
    private sesions: SesionService,
    private mensaje: ModalMensajeService) {
      this.error = '';
      this.error2 = '';
      this.sub = this.route.params.subscribe(params => {
        if(params.placa) {
          this.placavehiculo =params.placa ;
          this.codigo = params.codigo;
          this.isUpdate =  this.codigo > 0;
          this.consultarVehiculo();
        }
       });
     }

     ngOnInit(): void {
      if(!this.sesions.validOpcion(6)) {
        this.router.navigate(['/inicio']);
      }
      if(this.isUpdate && this.sesions.sesion.perfil !=1){
        this.router.navigate(['/inicio']);
      }
      this.buildForm();
     }


     onSelectConductor(data: any): void {
      this.formGroup.controls.conductor.setValue(data.codigo);
      this.formGroup.controls.nombreconductor.setValue(data.nombre);
    }


    buildForm() {
      this.formGroup = this.formBuilder.group({

        codigo: [0, Validators.required],
        vehiculo:  ['', Validators.required],
        fecha:  ['', Validators.required],
        lugar:  ['', Validators.required],
        area:  ['', Validators.required],
        conductor:  ['', Validators.required],
        nombreconductor: [''],
        descripcion:  ['', Validators.required],
        fechasis:  [''],
        usuariosis:  ['']
       //auxnotificacion: [{value: null, disabled: true}],
       });

   }


   validarForm(): boolean {

    this.error = '';

    if(!this.vehiculo){
      this.error ='Ingrese un vehiculo';
    }
    if (this.formGroup.controls.lugar.errors) {
      this.error = 'Ingrese el lugar del accidente. ';
    }

    if (this.formGroup.controls.area.errors) {
      this.error = 'Ingrese el area del accidente. ';
    }

    if (this.formGroup.controls.descripcion.errors) {
      this.error = 'Ingrese la descripción del accidente. ';
    }


    if (this.formGroup.controls.fecha.errors) {
      this.error = 'Seleccione la fecha del accidente. ';
    }

    if (this.formGroup.controls.conductor.errors) {
      this.error = 'Ingrese el conductor del vehículo. ';
    }

    return this.error == '';
  }

    consultarPlaca(){
      if(!this.placavehiculo || this.placavehiculo.trim() == '') {
        this.error='Ingrese la placa del vehículo a consultar.';
        return;
      }else{
        this.error ='';
      }

      this.router.navigate(['/add/accidente',this.placavehiculo]);

    }


    consultarVehiculo(){
      if(!this.placavehiculo || this.placavehiculo.trim() == '') {
        this.error='Ingrese la placa del vehículo a consultar.';
        return;
      }else{
        this.error ='';
      }
      this.servicevehiculo.getVehiculo(this.placavehiculo).subscribe(result => {
         this.vehiculo = result.vehiculo;
         if(this.isUpdate){
          this.service.getAccidente(this.codigo).subscribe( acc => {
             this.setAccidente(acc);
          });
        }
      });
  }

  setAccidente(accidente: any){
    this.formGroup.controls.codigo.setValue(accidente.codigo);
    this.formGroup.controls.vehiculo.setValue(accidente.vehiculo);
    this.formGroup.controls.fecha.setValue(accidente.fecha);
    this.formGroup.controls.lugar.setValue(accidente.lugar);
    this.formGroup.controls.area.setValue(accidente.area);
    this.formGroup.controls.conductor.setValue(accidente.conductor);
    this.formGroup.controls.nombreconductor.setValue(accidente.nombreconductor);
    this.asyncSelected = accidente.nombreconductor;
    this.formGroup.controls.descripcion.setValue(accidente.descripcion);

    this.imagenes = accidente.imagenes;

  }
  registrarAccidente(){
    if(!this.validarForm()) {return;}

    this.formGroup.controls.vehiculo.setValue(this.vehiculo.codigo);

    this.service.insertAccidente(this.formGroup.value).subscribe( result => {
        if(result.estado) {

          if(!this.isUpdate){
            this.estado=false;
            this.formGroup.disable();
            this.formGroup.controls.codigo.setValue(result.accidente.codigo);

            this.mensaje.confirmMensaje('Accidente agregado a la base de datos.<br><br>¿ Quiere subir imágenes del accidente ?').subscribe(arg => {
              if(arg){
                this.upimagen = !this.upimagen;
              } else {
                this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/7']);
              }
            });

          }else{
            this.mensaje.showMensaje('Accidente actualiado', 'info');
            this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/7']);
          }

        }else{
          this.mensaje.showMensaje('No se pudo realizar la operación', 'error');
        }
    });
  }



  upImgResult(data:any){
    //this.ini();
    if(data.estado) {
      this.imagenes = data.imagenes;
    }else{
      this.mensaje.showMensaje(data.mensaje,'error');
    }

  }


  descartar(){
    this.formGroup.reset();
    this.formGroup.enable();
    this.estado = true;
    if(this.isUpdate){
      this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/7']);
    }
  }
}
