
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { tipoRevision } from 'src/app/models/tipo-revision';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdminrevisionService } from '../adminrevision.service';

@Component({
  selector: 'app-tipo-revision',
  templateUrl: './tipo-revision.component.html',
  styleUrls: ['./tipo-revision.component.css']
})
export class TipoRevisionComponent implements OnInit {
  public tractive: number;
  public tipos: tipoRevision[]=[];

  constructor(private service: AdminrevisionService,
              private router: Router,
              private sesions: SesionService,
              private mensaje: ModalMensajeService) { }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(11)) {
      this.router.navigate(['/inicio']);
    }
    this.getTipos();
  }

  getTipos(){
    this.service.getTiposRevision().subscribe(result => {
      this.tipos = result.tiposrevision;
    });
  }

  modTipo(item:tipoRevision ){
    this.router.navigate(['/administracion/revision/tipo', item.codigo]);
  }

  deleteTipo(item: tipoRevision){
     if(item.estado==0){return;}

     this.mensaje.confirmMensaje('¿ Realmente quiere eliminar el tipo de revisión ?.').subscribe(arg => {
       if(arg){
        this.service.deleteTipoRevision(item.codigo).subscribe(result => {
          if(!result.estado){
            this.mensaje.showMensaje('Error al realizar la operación.','error');
          }
          this.tipos = result.tipos;
         });
       }
     });


  }

  updateTipo(item: tipoRevision){

  }
}
