import { PlatformLocation } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Revision } from 'src/app/models/revision';
import { revisionCategoria } from 'src/app/models/revision-categoria';
import { usuario } from 'src/app/models/usuario';
import { Vehiculo } from 'src/app/models/vehiculo';
import { RevisionService } from 'src/app/revision/revision.service';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { StyleMantenimiento } from '../consulta-mantenimiento/constant';
import { VehiculoService } from '../vehiculo.service';

@Component({
  selector: 'app-consulta-revision',
  templateUrl: './consulta-revision.component.html',
  styleUrls: ['./consulta-revision.component.css','../consulta-mantenimiento/consulta-mantenimiento.component.css']
})
export class ConsultaRevisionComponent implements OnInit, OnDestroy {

  public suscripM: Subscription[]=[];
  public suscrip: Subscription[]=[];

  public kmTemp: number;
  public isEditKm: boolean;
  public ultKm : number;
  public maxKm : number = 0;

  public revision: Revision;
  public vehiculo: Vehiculo;
  public codvehiculo: number;

  public categorias: revisionCategoria[] = [];
  public user: usuario;

  public refres = new EventEmitter();

  constructor(public bsModalRef: BsModalRef,
    private serviceM: ModalService,
    private locations: PlatformLocation,
    private sercicerev: RevisionService,
    private modalService: BsModalService,
    public sesions: SesionService,
    private mensaje: ModalMensajeService,
    private service: VehiculoService) {
      this.suscribModal();
      this.serviceM.nmodal = 1;
      if(!this.vehiculo && this.codvehiculo) {
        this.service.getVehiculoCod(this.codvehiculo).subscribe(r => this.vehiculo=r.vehiculo);
      }
    }


    suscribModal(){
      this.suscripM.push(this.modalService.onHide.subscribe((reason: string) => {
          this.suscripM.forEach(element => {
            element.unsubscribe();
          });
          this.suscrip = [];
          if(reason !== 'BACK') {
            history.back();
          }
       }));
    }


  ngOnDestroy(): void {
    this.suscrip.forEach(element => {
      element.unsubscribe();
  });
  this.suscripM.forEach(element => {
    element.unsubscribe();
   });
  }

  ngOnInit(): void {

    this.suscrip.push(this.sesions.select$().subscribe(result => {
        this.user=result;
    }))

    history.pushState(null, '');
    this.locations.onPopState((event) => {
      // ensure that modal is opened

      if(this.serviceM.nmodal == 1){
        if(this.bsModalRef !== undefined) {
          this.modalService.setDismissReason('BACK');
            this.bsModalRef.hide();
      }
      }

    });

    if(!this.vehiculo && this.codvehiculo) {
      this.service.getVehiculoCod(this.codvehiculo).subscribe(r => {
        this.vehiculo = r.vehiculo;
        this.getDetalles();
      });
    } else {
      this.getDetalles();
    }

  }


  async getDetalles() {
    const[detalle, km] = await forkJoin([this.service.getRevision(this.revision.codigo),
                                         this.service.getUltmKm(this.revision.codigo, this.vehiculo.codigo)]).toPromise();

      this.categorias = detalle.items;
      this.revision.imagenes=detalle.imagenes;
      this.ultKm = km.km;
      this.maxKm = km.maxkm;
}


delete(){
  this.mensaje.confirmMensaje('¿Seguro que quiere eliminar la revisión.?').subscribe(arg => {
     if(arg) {
        this.sercicerev.deleteRevision(this.revision.codigo).subscribe(result => {
            this.bsModalRef.hide();
            this.mensaje.showMensaje('Revisión eliminada','info');
            this.refres.emit(result);
        });
     }
  });

}

editKm(key: boolean){
  this.isEditKm = key;
  this.kmTemp = this.revision.kilometraje;
}

updateKm() {
  const km = this.kmTemp-this.ultKm;
  let mnj = '';
  /* if(km < 0){
    this.mensaje.showMensaje('Kilometraje actual inferior al kilometraje anterior.','error');
     return false;
  } */

  if(this.maxKm && this.maxKm > 0 && this.kmTemp >= this.maxKm ){
    this.mensaje.showMensaje('Km sobrepasa al kilometraje de revisiones registradas posterior a la actual.','error');
     return false;
  }

  if(km > 200) {
    this.mensaje.confirmMensaje('¿ La diferencia de kilometros con relación a la última revisión es mayor de 200 km, Seguro que quiere continuar.?').subscribe(arg => {
      if(arg) {
         this.updateKmConfirm(km);
      }
   });
  }else{
    this.updateKmConfirm(km);
  }
}

updateKmConfirm(km: number) {
  this.service.updateKmRevision(this.revision.codigo, this.kmTemp, km ).subscribe(r => {
    if(r.estado) {
      this.revision.kilometraje = this.kmTemp;
      this.editKm(false);
    }
  });
}



getTd(item: any) : string{
  let revisionItems='';
  switch(item.tipo){
    case 1: {
      revisionItems +=`<b> ${item.estadodescripcion}</b>`;
    break;}
    case 2 : {
      revisionItems +=`<b> ${item.valor} / 10 </b>`;
    break;}
    case 3: {
      revisionItems +=`(<b> ${item.valor ==1 ? 'Si': 'No'}</b>) : <span style="font-size: 10px;">${item.observacion}</span>`;
    break;}
    case 4: {
      revisionItems +=`<span style="font-size: 10px;">${item.observacion}</span>`;
      break;
    }
  }
  return revisionItems;

}
print(){
  const cav =`<div style="text-align: center"><img height="40px" src="assets/GENERAL/logo.png"></div> <div style="text-align: center"><b>REVISIÓN N° ${this.revision.codigo}</b></div> `;
  let imgs='';

  if(this.revision.imagenes && this.revision.imagenes.length > 0) {
    imgs+='<div><b>IMAGENES GENERALES</b></div';
    this.revision.imagenes.forEach(element => {
      imgs +=`<img src="${element.url}" width="100%" >`;
    });
  }


    let printContents, popupWin;
    printContents = document.getElementById('cav').innerHTML;


    let revisionItems = '';
    this.categorias.forEach(cat => {
      if(cat.items.length>0){
          revisionItems +=`<table width="100%" border="1" cellspacing="0">
        <tr>
          <td colspan="4" style="text-align: center"><b>${cat.descripcion}</b></td>
        </tr>`;



        for(let i=0; i< cat.items.length; i++) {
          revisionItems +=`<tr>`;

          revisionItems +=`<td width="30%" style="padding: 5px;">${cat.items[i].descripcion}</td>
                           <td width="20%" style="padding: 5px;">${this.getTd(cat.items[i])}</td>`;

          if(i+2 <= cat.items.length) {
            i++;
            revisionItems +=`<td width="30%" style="padding: 5px;">${cat.items[i].descripcion}</td>
                             <td width="20%" style="padding: 5px;">${this.getTd(cat.items[i])}</td>`;
          } else {
            revisionItems +=`<td width="30%" style="padding: 5px;"> </td>
                             <td width="20%" style="padding: 5px;"> </td>`;
          }

          revisionItems +=`</tr>`;
        }

        revisionItems +=`</table>`;


        if(cat.imagenes.length > 0) {
          cat.imagenes.forEach(element => {
            imgs +=`<img src="${element.url}" width="90%" > <div style="font-size: 10px;">${cat.descripcion}</div><br><br>`;
          });
        }


      }
    });

    revisionItems +=`<br><table width="100%" border="1" cellspacing="0">
    <tr>
    <td width="50%" style="text-align: center">
      <img src="${this.revision.firmaurl}" width="100%" >
    </td>
    <td width="50%" style="text-align: center">
      <img src="${this.revision.firmaurl2}" width="100%" >
    </td>
    </tr>
    <tr><td style="text-align: center;padding: 5px;">Firma 1</td><td style="text-align: center;padding: 5px;">Firma 2</td></tr>
    </table>`;

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
             ${StyleMantenimiento.style}
          </style>
        </head>
    <body onload="window.print();window.close()">
    ${cav}
    ${printContents}
     <br>
     ${revisionItems}
     ${imgs}
    </body>
    </html>`
    );
    popupWin.document.close();
  }



}
