import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { PermisosService } from '../permisos.service';

@Component({
  selector: 'app-add-perfil',
  templateUrl: './add-perfil.component.html',
  styleUrls: ['./add-perfil.component.css']
})
export class AddPerfilComponent implements OnInit {
  public error: string="";
  public perfil: any;
  public sub: any;

  public codigo: number=0;

  constructor(private servicio: PermisosService,
    private route: ActivatedRoute,
    private router: Router,
    private sesions: SesionService,
    private mensaje: ModalMensajeService) {

      this.perfil = {
        codigo:0,
        descripcion: '',
        vehiculos:0,
        estado:0
      }

      this.sub = this.route.params.subscribe(params => {
        if(params.codigo){
          this.codigo = parseInt(params.codigo);
          this.getPerfil();
        }
       });

    }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(16) && this.sesions.sesion.perfil != 1 ) {
      this.router.navigate(['/inicio']);
    }
  }


  getPerfil(){
    this.servicio.getPerfil(this.codigo).subscribe(result => {
      this.perfil = result.perfil;
    });
  }


  validar(){
    if(!this.perfil.descripcion || this.perfil.descripcion.trim()==''){
      this.error ='Ingrese una descripción.';
      return false;
    }

    if(this.perfil.estado == undefined) {
      this.error ='Seleccione un estado.';
      return false;
    }

    let n =0;
    this.perfil.modulos.forEach(element => {
      element.opciones.forEach(i => {
        if(i.permiso) {
          n++;
        }
      });
  });

  if(n==0) {
    this.error ='Seleccione por lo menos una opción para los permisos del perfil.';
    return false;
  }

  this.error ='';
  return true;

  }


  savePerfil() {
     if(this.validar()) {
       this.servicio.savePerfil(this.perfil).subscribe(result => {
        if(result.estado) {
          this.router.navigate(['/administracion/permisos/perfiles']);
         }else{
           this.mensaje.showMensaje('Error al realizar operación.','error');
         }
       });
     }
  }
}
