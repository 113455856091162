import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiculoComponent } from './vehiculo/vehiculo.component';
import { CategoriaServicioComponent } from './categoria-servicio/categoria-servicio.component';
import { ServicioComponent } from './servicio/servicio.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiciosRecurrentesComponent } from './servicios-recurrentes/servicios-recurrentes.component';
import { ProveedorComponent } from './proveedor/proveedor.component';
import { CategoriaRevisionComponent } from './revision/categoria-revision/categoria-revision.component';
import { ItemRevisionComponent } from './revision/item-revision/item-revision.component';
import { TipoRevisionComponent } from './revision/tipo-revision/tipo-revision.component';
import { EditTipoRevisionComponent } from './revision/edit-tipo-revision/edit-tipo-revision.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AppRoutingModule } from '../app-routing.module';
import { PerfilesComponent } from './permisos/perfiles/perfiles.component';
import { AddPerfilComponent } from './permisos/add-perfil/add-perfil.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NewUsuarioComponent } from './usuario/new-usuario/new-usuario.component';
import { UsuariosComponent } from './usuario/usuarios/usuarios.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ResetTacometroComponent } from './vehiculo/reset-tacometro/reset-tacometro.component';


@NgModule({
  declarations: [VehiculoComponent, CategoriaServicioComponent, ServicioComponent, ServiciosRecurrentesComponent, ProveedorComponent, CategoriaRevisionComponent, ItemRevisionComponent, TipoRevisionComponent, EditTipoRevisionComponent, PerfilesComponent, AddPerfilComponent, NewUsuarioComponent, UsuariosComponent, ResetTacometroComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    AppRoutingModule,
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
  ]

})
export class AdministracionModule { }
