<div class="row mb-5" style="max-width: 700px;margin:  0px auto;min-height: 500px; ">
  <div *ngIf="perfil"  class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Perfil</h3></div>
     <section>


     <div class="row mt-5" style="border:solid 1px #cccccc;border-radius: 10px;padding: 10px;">
      <div class="col col-12 mb-3 text-left"><b>{{ codigo ? 'Modificar Perfil.' : 'Agregar Nuevo Perfil.' }}</b></div>

      <div class="col col-12 text-left">
        <label for="fecha" class="col-form-label " >Descripción Perfil</label>
        <input  type="text" class="form-control" [disabled]="perfil.codigo !==0 " [(ngModel)]="perfil.descripcion" name="id" placeholder="Descripción perfil">
      </div>

      <div class="col col-12 text-left">
        <label for="estado" class="col-form-label " >Acceso a Vehículos</label>
        <select class="custom-select"  [(ngModel)]="perfil.vehiculos">
          <option  [ngValue]="0" >Todos</option>
          <option  [ngValue]="1" >Vehículo que conduce</option>
        </select>
      </div>

      <div class="col col-12 text-left">
        <label for="estado" class="col-form-label " >Estado</label>
        <select class="custom-select"  [(ngModel)]="perfil.estado">
          <option  [ngValue]="1" >Activo</option>
          <option  [ngValue]="0" >Inactivo</option>
        </select>
      </div>

    </div>

<br>

<div *ngIf="perfil.estado==1">
  <div><h5>Permisos perfil:</h5></div>
  <br>

  <div style="border: solid 1px #cccccc; padding: 5px;margin: 5px;border-radius: 5px;" *ngFor="let modulo of perfil.modulos;" class="text-left">
  <div class="row" style="cursor: default;">
    <!-- <div class="col" style="max-width: 20px;"><input [(ngModel)]="categoria.check" (change)="chechAll(categoria); $event.stopPropagation();" [id]="'cat'+categoria.codigo" type="checkbox"></div> -->
    <div class="col" (click)="modulo.enable = !modulo.enable"  [attr.aria-expanded]="!modulo.enable" aria-controls="collapseBasic"><b>{{modulo.descripcion}} <div style="float: right;">{{ modulo.enable ? '-' : '+' }}</div></b></div>
   </div>
    <div style="padding-left: 20px;" id="collapseBasic" [collapse]="!modulo.enable" [isAnimated]="true">
      <br>
      <div *ngFor="let item of modulo.opciones;" class="form-group form-check">
        <input [(ngModel)]="item.permiso" [disabled]="item.codigo == 1 " type="checkbox" class="form-check-input" [id]="item.codigo">
        <label class="form-check-label" [for]="item.codigo">{{item.descripcion}}</label>
      </div>
    </div>
  </div>
</div>



    <div  class="row " style="max-width: 600px;margin: 0px auto; ">

      <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error !== '' " >
        {{error}}
      </div>

      <div class="btn-conten mt-5">
      <div *ngIf="!perfil.codigo"  class="butomForm" (click)="savePerfil()" >
        <div class="img save"></div>
        <div>Registrar</div>
      </div>
      <div *ngIf="perfil.codigo"  class="butomForm" (click)="savePerfil()" >
        <div class="img update"></div>
        <div>Actualizar</div>
      </div>

    </div>
    </div>


    </section>

  </div>
</div>
