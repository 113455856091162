import { Injectable } from '@angular/core';
import { NgHttpService } from '../core/http/ng-http.service';

@Injectable({
  providedIn: 'root'
})
export class VehiculoService {

  public eventPending: boolean;

  constructor(private http: NgHttpService) { }

  getVehiculo(placa: string) {
     return this.http.get('vehiculo/placa/' + placa);
  }

  getVehiculoCod(cod: number) {
    return this.http.get('vehiculo/codigo/' + cod);
 }

  getVehiculoKM(placa: string) {
    return this.http.get('vehiculo/placa/' + placa+'/km');
  }

  getNovedades(codigo: number) {
    return this.http.get('novedades/vehiculo/'+codigo) ;
  }

  getRevisiones(user: number,codigo: number, desde: string, hasta: string, pag: number) {
    return this.http.get('revisiones/usuario/'+user+'/vehiculo/'+codigo+'/desde/'+desde+'/hasta/'+hasta+'/pag/'+pag);
  }
  getRevision(codigo: number) {
    return this.http.get('revision/'+codigo);
  }
  getUltmKm(revision: number, vehiculo: number) {
    return this.http.get('revision/'+revision+'/vehiculo/'+vehiculo+'/km');
  }
  updateKmRevision(codigo: number,kilometraje: number, km: number) {
    const obj ={
      codigo: codigo,
      kilometraje: kilometraje,
      km: km
    }
    return this.http.put('revision/km', obj);
  }

  getMantenimientos(user: number,codigo: number, desde: string, hasta: string, pag: number) {
    return this.http.get('mantenimientos/usuario/'+user+'/vehiculo/'+codigo+'/desde/'+desde+'/hasta/'+hasta+'/pag/'+pag);
  }

  getMantenimientoServicios(codigo: number) {
    return this.http.get('mantenimiento/'+codigo);
  }
  getUltmKmMantenimiento(codigo: number, vehiculo: number) {
    return this.http.get('mantenimiento/'+codigo+'/vehiculo/'+vehiculo+'/km');
  }


  getCombustible(user: number,codigo: number, desde: string, hasta: string, pag: number) {
    return this.http.get('combustible/usuario/'+user+'/vehiculo/'+codigo+'/desde/'+desde+'/hasta/'+hasta+'/pag/'+pag);
  }

  getUltmKmCombustible(codigo: number, vehiculo: number) {
    return this.http.get('combustible/'+codigo+'/vehiculo/'+vehiculo+'/km');
  }
  updateKmCombustible(codigo: number, vehiculo: number, km: number) {
    const obj ={
      codigo: codigo,
      vehiculo: vehiculo,
      km: km
    }
    return this.http.put('combustible', obj);
  }

  getComparendos(user: number, codigo: number, desde: string, hasta: string, pag: number) {
    return this.http.get('comparendos/usuario/'+user+'/vehiculo/'+codigo+'/desde/'+desde+'/hasta/'+hasta+'/pag/'+pag);
  }

  getAccidentes(user: number, codigo: number, desde: string, hasta: string, pag: number) {
    return this.http.get('accidentes/usuario/'+user+'/vehiculo/'+codigo+'/desde/'+desde+'/hasta/'+hasta+'/pag/'+pag);
  }

  getGraficaNovedades(vehiculo: number) {
    return this.http.get('novedades/grafica/vehiculo/'+vehiculo);
  }

  updatePromVehiculo(prom: any) {
     return this.http.post('novedades/vehiculo/prom', prom);
  }

  updateKmMatenimiento(codigo: number, km: number) {
    const obj ={
      codigo: codigo,
      km: km
    }
    return this.http.put('mantenimiento', obj);
  }

  getReporteMantenimientos(user: number, vehiculo: number, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/mantenimientos/usuario/'+user+'/vehiculo/'+vehiculo+'/desde/'+desde+'/hasta/'+hasta,'ReporteMantenimientos');
  }

  getReporteConsumo(sucursal: number,user: number, vehiculo: number, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/combustible/sucursal/'+sucursal+'/usuario/'+user+'/vehiculo/'+vehiculo+'/desde/'+desde+'/hasta/'+hasta,'ReporteMantenimientos');
  }

  getReporteComparendos(user: number, sucursal: number,vehiculo: number, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/comparendos/sucursal/'+sucursal+'/usuario/'+user+'/vehiculo/'+vehiculo+'/desde/'+desde+'/hasta/'+hasta,'ReporteComnparendos');
  }

  getReporteRevisiones(user: number, vehiculo: number, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/revisiones/sucursal/-1/usuario/'+user+'/vehiculo/'+vehiculo+'/desde/'+desde+'/hasta/'+hasta,'ReporteRevisiones');
  }

  getReporteAccidentes(user: number, vehiculo: number, desde: string, hasta: string){
    return this.http.dowloadExcel('reporte/accidentes/sucursal/-1/usuario/'+user+'/vehiculo/'+vehiculo+'/desde/'+desde+'/hasta/'+hasta,'ReporteAccidentes');
  }
}
