import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccidenteComponent } from './accidente/accidente.component';
import { CategoriaServicioComponent } from './administracion/categoria-servicio/categoria-servicio.component';
import { AddPerfilComponent } from './administracion/permisos/add-perfil/add-perfil.component';
import { PerfilesComponent } from './administracion/permisos/perfiles/perfiles.component';
import { ProveedorComponent } from './administracion/proveedor/proveedor.component';
import { CategoriaRevisionComponent } from './administracion/revision/categoria-revision/categoria-revision.component';
import { EditTipoRevisionComponent } from './administracion/revision/edit-tipo-revision/edit-tipo-revision.component';
import { ItemRevisionComponent } from './administracion/revision/item-revision/item-revision.component';
import { TipoRevisionComponent } from './administracion/revision/tipo-revision/tipo-revision.component';
import { ServicioComponent } from './administracion/servicio/servicio.component';
import { ServiciosRecurrentesComponent } from './administracion/servicios-recurrentes/servicios-recurrentes.component';
import { NewUsuarioComponent } from './administracion/usuario/new-usuario/new-usuario.component';
import { UsuariosComponent } from './administracion/usuario/usuarios/usuarios.component';
import { VehiculoComponent } from './administracion/vehiculo/vehiculo.component';
import { CombustibleComponent } from './combustible/combustible.component';
import { ComparendoComponent } from './comparendo/comparendo.component';
import { ConsultaVehiculoComponent } from './consulta-vehiculo/consulta-vehiculo.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MantenimientoComponent } from './mantenimiento/mantenimiento.component';
import { RevisionComponent } from './revision/revision.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';


const routes: Routes = [
  //{path: '', component: HomeComponent, pathMatch : 'prefix'},
  
  //Temporal Component
  {path: 'splash/:username/:password', component: SplashScreenComponent, pathMatch: 'prefix'},

  {path: 'inicio', component: HomeComponent, pathMatch : 'prefix'},
  {path: 'consultavehiculo', component: ConsultaVehiculoComponent ,pathMatch : 'prefix'},
  {path: 'consultavehiculo/:placa', component: ConsultaVehiculoComponent ,pathMatch : 'prefix'},
  {path: 'consultavehiculo/:placa/:tab', component: ConsultaVehiculoComponent ,pathMatch : 'prefix'},
  {path: 'consultaconductor', component: ConsultaVehiculoComponent ,pathMatch : 'prefix'},
  {path: 'consultaconductor/:codigo/:nombre', component: ConsultaVehiculoComponent ,pathMatch : 'prefix'},
  {path: 'consultaconductor/:codigo/:nombre/:tab', component: ConsultaVehiculoComponent ,pathMatch : 'prefix'},
  {path: 'add/revision', component: RevisionComponent ,pathMatch : 'prefix'},
  {path: 'add/revision/:placa', component: RevisionComponent ,pathMatch : 'prefix'},
  {path: 'add/revision/:revision/:placa', component: RevisionComponent ,pathMatch : 'prefix'},
  {path: 'add/mantenimiento', component: MantenimientoComponent ,pathMatch : 'prefix'},
  {path: 'add/mantenimiento/:placa', component: MantenimientoComponent ,pathMatch : 'prefix'},
  {path: 'add/mantenimiento/:placa/:codigo', component: MantenimientoComponent ,pathMatch : 'prefix'},
  {path: 'add/combustible', component: CombustibleComponent ,pathMatch : 'prefix'},
  {path: 'add/combustible/:placa', component: CombustibleComponent ,pathMatch : 'prefix'},
  {path: 'add/combustible/:placa/:codigo', component: CombustibleComponent ,pathMatch : 'prefix'},
  {path: 'add/comparendo', component: ComparendoComponent ,pathMatch : 'prefix'},
  {path: 'add/comparendo/:placa', component: ComparendoComponent ,pathMatch : 'prefix'},
  {path: 'add/comparendo/:placa/:codigo', component: ComparendoComponent ,pathMatch : 'prefix'},
  {path: 'add/accidente', component: AccidenteComponent ,pathMatch : 'prefix'},
  {path: 'add/accidente/:placa', component: AccidenteComponent ,pathMatch : 'prefix'},
  {path: 'add/accidente/:placa/:codigo', component: AccidenteComponent ,pathMatch : 'prefix'},

  {path: 'administracion/vehiculo', component: VehiculoComponent ,pathMatch : 'prefix'},
  {path: 'administracion/proveedor', component: ProveedorComponent ,pathMatch : 'prefix'},

  {path: 'administracion/revision/categoria', component: CategoriaRevisionComponent ,pathMatch : 'prefix'},
  {path: 'administracion/revision/item', component: ItemRevisionComponent ,pathMatch : 'prefix'},
  {path: 'administracion/revision/item/categoria/:categoria', component: ItemRevisionComponent ,pathMatch : 'prefix'},
  {path: 'administracion/revision/tipo', component: TipoRevisionComponent ,pathMatch : 'prefix'},
  {path: 'administracion/revision/tipo/add', component: EditTipoRevisionComponent ,pathMatch : 'prefix'},
  {path: 'administracion/revision/tipo/:codigo', component: EditTipoRevisionComponent ,pathMatch : 'prefix'},

  {path: 'administracion/categoriaservicio', component: CategoriaServicioComponent ,pathMatch : 'prefix'},
  {path: 'administracion/servicio', component: ServicioComponent ,pathMatch : 'prefix'},
  {path: 'administracion/serviciosrecurrentes', component:ServiciosRecurrentesComponent ,pathMatch : 'prefix'},

  {path: 'administracion/usuarios', component: UsuariosComponent ,pathMatch : 'prefix'},
  {path: 'administracion/usuarios/add', component: NewUsuarioComponent ,pathMatch : 'prefix'},
  {path: 'administracion/usuario/:codigo', component: NewUsuarioComponent ,pathMatch : 'prefix'},

  {path: 'administracion/permisos/perfiles', component: PerfilesComponent ,pathMatch : 'prefix'},
  {path: 'administracion/permisos/perfil/:codigo', component: AddPerfilComponent ,pathMatch : 'prefix'},

  {path: 'login', component: LoginComponent, pathMatch : 'prefix'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
