import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {

  private scrollY$ = new Subject<number>();
  public scrollY: number;
  public showbaner: boolean;

  private enable$ = new Subject<boolean>();

  constructor() { }

  dispatch(y: number) {
    this.scrollY$.next(y);
  }

  select$() {
    return this.scrollY$.asObservable();
  }

  public checkScolling() {
    const result: number = window.pageYOffset;
    this.dispatch(result);
    this.scrollY = result;
    this.showbaner = this.scrollY < 100;
    return  result;
  }



  public dispatchEnable(estado: boolean) {
    this.enable$.next(estado);
  }

  selectEnable$() {
    return this.enable$.asObservable();
  }

}
