<div class="row mb-5" style="max-width: 400px;margin:  0px auto; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Inicio Sesión</h3></div>
     <form>
     <div class="form-group row" >
      <label class="col-sm-4 col-form-label text-left" for="username">Usuario :</label>
      <div class="col-sm-8" >
          <input type="text" class="form-control" [(ngModel)] = "username" name="username" autofocus
          placeholder="Nombre de usuario" (keydown.enter)="pass.focus()">
      </div>
    </div>
    <div class="form-group row" >
      <label  class="col-sm-4 col-form-label text-left" for="password" >Contraseña :</label>
      <div class="col-sm-8" >
          <input type="password" #pass class="form-control" [(ngModel)] = "password" name="password" (keydown.enter)="login()"
          placeholder="Contraseña">
      </div>
    </div>

    <div class="row alert alert-danger p-2 small mb-4"  *ngIf="error !== '' " style="max-width: 350px;margin: 0px auto;">
      {{error}}
    </div>


    <div class="row mt-5" >
      <div class="col text-center"><button type="button" class="btn btn-warning" (click)="login()">Ingresar</button></div>
    </div>
</form>
    </div>
    </div>
