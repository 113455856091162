
<form [formGroup] = "formFile" style="display: none;">
  <input #fileinput type="file" name="file"  (change)="onFileChange($event)" [accept]="typeFile"/>
</form>

<!--Drop-->
<div *ngIf="tipo == 'drop'" class="col-12 rmpm dropzone content">

  <div [hidden]="uploading" >
      <div *ngIf="mensaje">{{mensaje}}</div>
       <button type="button" class="btn btn-blue btn-sm mt-3" (click)="fileinput.click()">{{btLabel}}</button>
  </div>

  <div [hidden]="!uploading" >
      <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="0" [ngStyle]="{width: uploadResponse.message + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
  </div>
</div>

<!--Select-->
<div *ngIf="tipo == 'select'">
  <div  class="butomForm" (click)="fileinput.click()">
    <div class="img add"></div>
    <div>{{btLabel}}</div>
  </div>

  <!--<button type="button" class="btn btn-blue btn-sm " (click)="fileinput.click()"> <img *ngIf="icon" [src]="icon == 'up' ? 'assets/IMAGENES/GENERAL/up2.png' : 'assets/IMAGENES/GENERAL/update.png'" width="25" class="mr-2" >  {{btLabel}}</button>-->
</div>


<div *ngIf="tipo == 'textarea'" class="rmpm ">

  <div [hidden]="uploading" class="butomForm" (click)="fileinput.click()">
    <div class="img discard" style="background-image: url(assets/IMAGENES/GENERAL/add.png);"></div>
    <div>Archivo</div>
  </div>

  <div [hidden]="!uploading" >
      <div class="progress mt-4" style="height: 10px;">
          <div class="progress-bar" role="progressbar" aria-valuenow="0" [ngStyle]="{width: uploadResponse.message + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div style="font-size: 10px;">Cargando {{uploadResponse.message}} %</div>
  </div>
</div>


<!--social-->
<!--add-->
<div *ngIf="tipo == 'add'" class="col-12 rmpm dropzone content-add">

  <div [hidden]="uploading" >
      <div  (click)="fileinput.click()"><b>+</b></div>
  </div>

  <div [hidden]="!uploading" >
      <div class="progress mt-4" style="height: 10px;">
          <div class="progress-bar" role="progressbar" aria-valuenow="0" [ngStyle]="{width: uploadResponse.message + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div style="font-size: 10px;">Cargando {{uploadResponse.message}} %</div>
  </div>
</div>

<!--up img-->
<div *ngIf="tipo == 'bt-img'" (click)="fileinput.click()">
  <img width="23" src="assets/IMAGENES/MODULOS/HOME/foto.png" />
  Foto
</div>

<!--up video-->
<div *ngIf="tipo == 'bt-video'" (click)="fileinput.click()">
  <img width="23" src="assets/IMAGENES/MODULOS/HOME/video0.png">
       Video
</div>
<!--end social-->
