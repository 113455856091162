<div
  class="row mb-5"
  style="max-width: 1000px; margin: 0px auto; min-height: 500px"
>
  <div class="col font-md text-center">
    <div class="mt-5 mb-5 title-text"><h3>Registrar Consumo de Combustible</h3></div>

    <div class="form-group row" style="max-width: 400px; margin: 0px auto">
      <div class="col col-10 text-left">
        <label for="fecha" class="col-form-label">Placa Vehículo</label>
        <div>
          <input
            type="text"
            class="form-control"
            name="id"
            placeholder="Placa del vehículo"
            [(ngModel)]="placavehiculo"
            (keydown.enter)="consultarPlaca()"
            [disabled]="isUpdate"
          />
        </div>
      </div>
      <div class="col col-2 text-left p-0">
        <img *ngIf="!isUpdate"
          src="assets/GENERAL/buscar3.png"
          style="margin-top: 40px"
          width="30"
          (click)="consultarPlaca()"
        />
      </div>

      <div *ngIf="error !== ''" class="col col-12 mt-5 mb-5">
        <div
          class="row alert alert-danger p-2 small mb-4"
          style="max-width: 350px; margin: 0px auto"
        >
          {{ error }}
        </div>
      </div>
    </div>

    <div
      *ngIf="vehiculo"
      class="row"
      style="padding: 10px; max-width: 650px; margin: 0px auto"
    >
      <div class="col-12 p-0">
        <section class="mt-5 mb-5 text-left">
          <div class="mb-4 mt-4"><b>DATOS VEHÍCULO</b></div>
          <div
            class="row"
            style="border: solid 1px #cccccc; border-radius: 20px"
          >
            <div class="col col-12 col-sm-3 mt-3 mb-3">
              <div class="small">Placa:</div>
              <div>
                <b>{{ vehiculo.placa }}</b>
              </div>
            </div>
            <div class="col col-12 col-sm-3 mt-3 mb-3">
              <div class="small">Marca:</div>
              <div>
                <b>{{ vehiculo.marcadescrip }}</b>
              </div>
            </div>
            <div class="col col-12 col-sm-3 mt-3 mb-3">
              <div class="small">Tipo:</div>
              <div>
                <b>{{ vehiculo.tipodescrip }}</b>
              </div>
            </div>
            <div class="col col-12 col-sm-3 mt-3 mb-3">
              <div class="small">Color:</div>
              <div>
                <b>{{ vehiculo.color }}</b>
              </div>
            </div>
          </div>
        </section>

        <!---->
        <section>
          <div
            style="
              border: solid 1px #cccccc;
              border-radius: 10px;
              padding: 20px;
              max-width: 640px;
              margin: 0px auto;
            "
          >



              <div class="col col-12 text-left">
                <label for="conductor" class="col-form-label"
                  >Conductor</label
                >
                <app-search-conductor (onSelect)="onSelectConductor($event)" [setText]="asyncSelected"></app-search-conductor>

              </div>

              <form [formGroup]="formGroup">
              <div class="col col-12 text-left">
                <label for="fecha" class="col-form-label"
                  >Fecha Tanqueo</label
                >
                <input
                  type="text"
                  class="form-control"
                  #dp="bsDatepicker"
                  bsDatepicker
                  [bsConfig]="bsConfig"
                  [maxDate]="maxDate"
                  formControlName="fecha"
                />
              </div>


              <div class="col col-12 text-left">
                <label for="km" class="col-form-label"
                  >Kilometraje</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kilometraje"
                  formControlName="kminicial"
                />
                <small id="emailHelp" class="form-text text-muted text-left"> Anterior: {{formGroup.controls.kmanterior.value | number}} km.</small>
              </div>


              <div class="col col-12 text-left">
                <label for="consumo" class="col-form-label"
                  >Consumo Combustible</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Combustible en Galones"
                  formControlName="consumo"
                />
              </div>

              <div class="col col-12 text-left">
                <label for="valortotal" class="col-form-label"
                  >Valor Combustible</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Valor combustible"
                  formControlName="valortotal"
                />
              </div>

              <div class="col col-12 text-left">
                <label for="observacion" class="col-form-label"
                  >Descripción</label
                >
                <textarea
                  class="form-control"
                  formControlName="observacion"
                  placeholder="Ingrese una observación."
                ></textarea>
              </div>


            </form>
          </div>

          <div class="row" style="max-width: 600px; margin: 0px auto">
            <div
              class="col col-12 alert alert-danger p-2 small mt-4 mb-4"
              *ngIf="error !== ''"
            >
              {{ error }}
            </div>

            <div class="btn-conten mt-5">
              <div class="butomForm" (click)="registrarCombustible()">
                <div class="img save"></div>
                <div>{{!isUpdate ? 'Registrar' : 'Actualizar'}}</div>
              </div>

              <div class="butomForm" (click)="descartar()">
                <div class="img discard"></div>
                <div>Descartar</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
