<div class="row mb-5" style="max-width: 1000px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Nueva Revisión</h3></div>


     <div class="form-group row" style="max-width: 400px;margin: 0px auto; " >

      <div class="col col-10  text-left">
       <label for="fecha" class="col-form-label" >Placa Vehículo</label>
       <div >
           <input type="text"
           class="form-control"
           name="id"
           placeholder="Ingrese la placa del vehículo"
           [(ngModel)]="placavehiculo"
           (keydown.enter)="consultarPlaca()"
           >
       </div>
      </div>
      <div class="col col-2  text-left p-0">
         <img src="assets/GENERAL/buscar3.png" style="margin-top: 40px;" width="30" (click)="consultarPlaca()"/>
      </div>

      <div *ngIf="error !== '' " class="col col-12 mt-5 mb-5">
        <div class="row alert alert-danger p-2 small mb-4"   style="max-width: 350px;margin: 0px auto;">
          {{error}}
        </div>
      </div>
   </div>


    <div *ngIf="vehiculo" class="row" style="padding: 10px; max-width: 650px;margin: 0px auto;" >

            <div class="col-12 p-0">

              <section class="mt-5 mb-5 text-left">
                <div class="mb-4 mt-4"><b>DATOS VEHÍCULO</b></div>
                <div class="row " style="border: solid 1px #cccccc;border-radius: 20px;">
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Placa:</div>
                    <div><b>{{vehiculo.placa}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Marca:</div>
                    <div><b>{{vehiculo.marcadescrip}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Tipo:</div>
                    <div><b>{{vehiculo.tipodescrip}}</b></div>
                  </div>
                  <div class="col col-12 col-sm-3 mt-3 mb-3">
                    <div class="small">Color:</div>
                    <div><b>{{vehiculo.color}}</b></div>
                  </div>

                </div>

              </section>


              <div style="border:solid 1px #cccccc;padding: 10px;border-radius: 10px;">

              <section *ngIf="!categoria && currentPage==1">
                     <div class="row m-2">
                      <div class="col col-12 text-left mt-4 mb-3"><b>GENERAL</b></div>

                      <div class="col text-left" style="padding-top: 12px;">Tipo de Revisión</div>
                      <div class="col">
                        <select class="custom-select" [(ngModel)]="revision.tipo" [disabled]="revision && revision.codigo !==0 " (change)="getCategoriasItems()">
                          <option *ngFor=" let item of tipos" [ngValue]="item.codigo" >{{item.descripcion}}</option>
                          <option [ngValue]="0" ></option>
                        </select>
                      </div>
                     </div>

                     <div class="row m-2">
                      <div class="col text-left" style="padding-top: 12px;">Conductor</div>
                       <div class="col">
                        <!-- <input type="text"
                        class="form-control"
                        name="id"
                        placeholder="Nombre conductor"
                        [disabled]="revision.estado==0"
                        [(ngModel)]="revision.conductor"
                        > -->
                        <app-search-conductor [setText]="revision.nombreconductor" (onSelect)="onSelectConductor($event)"></app-search-conductor>
                       </div>
                    </div>

                     <div class="row m-2">
                      <div class="col text-left" style="padding-top: 12px;">Kilometraje</div>
                       <div class="col">
                        <input type="text"
                        class="form-control"
                        name="id"
                        placeholder="Kilometraje"
                        [disabled]="revision.estado==0"
                        [(ngModel)]="revision.kilometraje"
                        >
                        <small id="emailHelp" class="form-text text-muted text-left"> Anterior: {{kilometrajeant | number}} km.</small>

                        <!-- <small *ngIf="sesion.perfil==1" id="emailHelp" class="form-text text-muted text-left" style="padding-left: 20px;">
                          <input type="checkbox" id="_reset" [(ngModel)]="reset" class="form-check-input" >
                          <label class="form-check-label" for="_reset">
                            Reinicio de Tacometro
                          </label>

                        </small> -->
                       </div>
                    </div>


                    <div class="row m-2">
                      <div class="col-12 text-left" style="padding-top: 12px;">
                        <label for="fecha" class="col-form-label" >Observación</label>
                        <textarea class="form-control" [disabled]="revision.estado==0" [(ngModel)]="revision.observacion" id="observacion" placeholder="Ingrese observación general de la revisión."></textarea>

                      </div>

                    </div>

                  </section>



                  <!---AGRUPAR POR CATEGORIA-->
                <section *ngIf="categoria">
                  <div class="row m-2" *ngIf="categoria.items.length>0" >
                    <div class="col col-12 text-left mt-4 mb-3"><b>{{categoria.descripcion}}</b></div>
                    <div class="col p-0">

                      <div class="row m-2" *ngFor="let item of categoria.items;" >
                        <div class="col col-12 col-sm-12 col-lg-6 text-left" style="padding-top: 12px;">{{item.descripcion}}</div>
                        <div class="col col-12 col-sm-12 col-lg-6 text-left">

                          <select *ngIf="item.tipo == 1" [disabled]="revision.estado == 0" class="custom-select" [(ngModel)]="item.estado" >
                            <option *ngFor=" let item of estados" [ngValue]="item.codigo" >{{item.descripcion}}</option>
                          </select>

                          <div class="row" *ngIf="item.tipo == 2" >
                            <div class="col-5">
                             <input type="number"
                             [disabled]="revision.estado == 0"
                              class="form-control"
                              name="id"
                              placeholder="0"
                              [(ngModel)]="item.valor"
                              >
                              <rating [(ngModel)]="item.valor" [disabled]="categoria.estado == 1" [max]="10"></rating>
                            </div>
                            <div class="col-1 p-0"><h4>/</h4></div>
                            <div class="col-1 p-0">
                                <h4>10</h4>
                            </div>
                          </div>

                          <div class="row" *ngIf="item.tipo == 3">
                            <div class="col-4">
                              <select  class="custom-select" [disabled]="revision.estado == 0" [(ngModel)]="item.valor" >
                              <option  [ngValue]="1" >Si</option>
                              <option  [ngValue]="0" >No</option>
                            </select>
                          </div>
                            <div class="col-8">
                              <input type="text"
                                class="form-control"
                                name="id"
                                placeholder="Detalle"
                                [disabled]="revision.estado == 0"
                                [(ngModel)]="item.observacion"
                                >
                            </div>
                          </div>

                          <div class="row" *ngIf="item.tipo == 4">
                            <div class="col-12">
                              <input type="text"
                                class="form-control"
                                name="id"
                                placeholder="Detalle"
                                [disabled]="revision.estado == 0"
                                [(ngModel)]="item.observacion"
                                >
                            </div>
                          </div>


                        </div>
                      </div>


                    </div>


                    <div *ngIf="tipo.observacioncategoria" class="col-12 text-left" style="padding-top: 12px;">
                      <label class="col-form-label" >Observación General</label>
                      <textarea class="form-control" [(ngModel)]="categoria.observacion" [disabled]="revision.estado == 0" id="observacion" [placeholder]="'Ingrese observación general '+(categoria.descripcion | lowercase)"></textarea>
                    </div>

                    <div *ngIf="tipo.observacioncategoria" class="col-12 text-left" style="padding-top: 12px;" >
                      <div  [hidden]="categoria.estado == 1 && categoria.imagenes.length==0" >Fotografías :</div>

                      <div>
                        <a *ngFor="let img of categoria.imagenes" [href]="img.url " target="_blank"><div  class="img-cat" [ngStyle]="{'background-image': 'url(' + img.url + ')'}"></div></a>

                        <div class="img-cat" *ngIf="revision.estado == 1">
                          <app-up-file
                            tipo="select"
                            [urlServer]="'/revision/'+revision.codigo+'/categoria/'+categoria.codigo+'/file'"
                            (done)="upImgResult($event)"
                            [btLabel]="'Imagen'"
                            [typeFile]="'.jpg,.jpeg,.png'"></app-up-file>
                        </div>
                      </div>
                    </div>


                  </div>
                </section>






               <!---NOOOOOOOOOOOOOOOOOOOOOOOOOOOOO AGRUPAR POR CATEGORIA-->
               <section *ngIf="tipo && tipo.agrupar==0 && currentPage==2">

                <div *ngFor="let categoria of categorias; let i = index">

                  <div class="row m-2" *ngIf="categoria.items.length>0" >
                    <div class="col col-12 text-left mt-4 mb-3"><b>{{categoria.descripcion}}</b></div>

                    <div class="col p-0">
                      <div class="row m-2" *ngIf="revision.estado == 1 && categoria.tipoItem!=4">
                        <div class="col col-12 col-sm-12 col-lg-6 text-left" style="padding-top: 12px;font-size: 10px;"><b>SELECCIONAR TODOS</b></div>
                        <div class="col col-12 col-sm-12 col-lg-6 text-left">

                          <div class="row select-all-item" *ngIf="categoria.tipoItem == 1" >
                            <div class="col col-3" *ngFor="let estado of estados">
                              <div class="form-check form-check-inline">
                                <input hidden="true"   class="form-check-input" type="radio" (ngModelChange)="seleccionarTodosCat(i, categoria.tipoItem, $event)" [(ngModel)]="categoria.estadoItems" [name]="categoria.codigo" [id]="categoria.codigo+'-'+estado.codigo" [value]="estado.codigo">
                                <label class="form-check-label" [for]="categoria.codigo+'-'+estado.codigo" [title]="estado.descripcion"> <img [src]="getImgEstado(estado.codigo, categoria.estadoItems)" width="25"> </label>
                              </div>
                            </div>
                        </div>


                        <div class="row select-all-item" *ngIf="categoria.tipoItem == 3" >
                          <div class="col col-4">
                            <div class="row">
                              <div class="col col-6">
                                <div class="form-check form-check-inline">
                                  <input hidden="true" class="form-check-input" type="radio" (ngModelChange)="seleccionarTodosCat(i, categoria.tipoItem, $event)" [(ngModel)]="categoria.estadoItems" [name]="categoria.codigo" [id]="categoria.codigo+'-1'" [value]="1">
                                  <label class="form-check-label" [for]="categoria.codigo+'-1'" title="Si"> <img [src]="getImgEstado(4, categoria.estadoItems === 1 ? 4 : 2)" width="25"> </label>
                                </div>
                              </div>
                              <div class="col col-6">
                              <div class="form-check form-check-inline">
                                <input hidden="true" class="form-check-input" type="radio" (ngModelChange)="seleccionarTodosCat(i, categoria.tipoItem, $event)" [(ngModel)]="categoria.estadoItems" [name]="categoria.codigo" [id]="categoria.codigo+'-0'" [value]="0">
                                <label class="form-check-label" [for]="categoria.codigo+'-0'" title="No"><img [src]="getImgEstado(2, categoria.estadoItems === 0 ? 2 : 4)" width="25"> </label>
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>

                        </div>
                        </div>

                      <div class="row m-2" *ngFor="let item of categoria.items;" >
                        <div class="col col-12 col-sm-12 col-lg-6 text-left" style="padding-top: 12px;">{{item.descripcion}}</div>
                        <div class="col col-12 col-sm-12 col-lg-6 text-left">

                          <div class="row" *ngIf="item.tipo == 1">
                            <div class="col col-3" *ngFor="let estado of estados">
                              <div class="form-check form-check-inline">
                                <input hidden="true" [disabled]="revision.estado == 0" class="form-check-input" type="radio" [(ngModel)]="item.estado" [name]="categoria.codigo+'-'+item.codigo" [id]="categoria.codigo+'-'+item.codigo+'-'+estado.codigo" [value]="estado.codigo">
                                <label class="form-check-label" [for]="categoria.codigo+'-'+item.codigo+'-'+estado.codigo" [title]="estado.descripcion"> <img [src]="getImgEstado(estado.codigo, item.estado)" width="25"> </label>
                              </div>
                            </div>
                        </div>

                          <!-- <select *ngIf="item.tipo == 1" [disabled]="revision.estado == 0" class="custom-select" [(ngModel)]="item.estado" >
                            <option *ngFor=" let item of estados" [ngValue]="item.codigo" >{{item.descripcion}}</option>
                          </select> -->

                          <div class="row" *ngIf="item.tipo == 2" >
                            <div class="col-5">
                             <input type="number"
                             [disabled]="revision.estado == 0"
                              class="form-control"
                              name="id"
                              placeholder="0"
                              [(ngModel)]="item.valor"
                              >
                              <rating [(ngModel)]="item.valor" [disabled]="categoria.estado == 1" [max]="10"></rating>
                            </div>
                            <div class="col-1 p-0"><h4>/</h4></div>
                            <div class="col-1 p-0">
                                <h4>10</h4>
                            </div>
                          </div>

                          <div class="row" *ngIf="item.tipo == 3">
                            <div class="col-4">
                              <div class="row">
                                <div class="col col-6">
                                  <div class="form-check form-check-inline">
                                    <input hidden="true" [disabled]="revision.estado == 0" class="form-check-input" type="radio" [(ngModel)]="item.valor" [name]="categoria.codigo+'-'+item.codigo" [id]="categoria.codigo+'-'+item.codigo+'-1'" [value]="1">
                                    <label class="form-check-label" [for]="categoria.codigo+'-'+item.codigo+'-1'" title="Si"> <img [src]="getImgEstado(4, item.valor === 1 ? 4 : 2)" width="25"> </label>
                                  </div>
                                </div>
                                <div class="col col-6">
                                <div class="form-check form-check-inline">
                                  <input hidden="true" [disabled]="revision.estado == 0" class="form-check-input" type="radio" [(ngModel)]="item.valor" [name]="categoria.codigo+'-'+item.codigo" [id]="categoria.codigo+'-'+item.codigo+'-0'" [value]="0">
                                  <label class="form-check-label" [for]="categoria.codigo+'-'+item.codigo+'-0'" title="No"><img [src]="getImgEstado(2, item.valor === 0 ? 2 : 4)" width="25"> </label>
                                </div>
                              </div>
                              </div>
<!--
                              <select  class="custom-select" [disabled]="revision.estado == 0" [(ngModel)]="item.valor" >
                              <option  [ngValue]="1" >Si</option>
                              <option  [ngValue]="0" >No</option>
                            </select> -->
                          </div>
                            <div class="col-8">
                              <input type="text"
                                class="form-control"
                                name="id"
                                placeholder="Detalle"
                                [disabled]="revision.estado == 0"
                                [(ngModel)]="item.observacion"
                                >
                            </div>
                          </div>

                          <div class="row" *ngIf="item.tipo == 4">
                            <div class="col-12">
                              <input type="text"
                                class="form-control"
                                name="id"
                                placeholder="Detalle"
                                [disabled]="revision.estado == 0"
                                [(ngModel)]="item.observacion"
                                >
                            </div>
                          </div>



                        </div>
                      </div>

                    </div>


                    <div *ngIf="tipo.observacioncategoria == 1" class="col-12 text-left" style="padding-top: 12px;">
                      <label class="col-form-label" >Observación General Categoría</label>
                      <textarea class="form-control" [(ngModel)]="categoria.observacion" [disabled]="revision.estado == 0" id="observacion" [placeholder]="'Ingrese observación general '+(categoria.descripcion | lowercase)"></textarea>
                    </div>

                    <div *ngIf="tipo.imgcategoria==1" class="col-12 text-left" style="padding-top: 12px;" >
                      <div class="col-form-label" [hidden]="categoria.estado == 1 && categoria.imagenes.length==0" >Fotografías Categoría :</div>

                      <div>
                        <a *ngFor="let img of categoria.imagenes" [href]="img.url " target="_blank"><div  class="img-cat" [ngStyle]="{'background-image': 'url(' + img.url + ')'}"></div></a>

                        <div class="img-cat" *ngIf="revision.estado == 1">
                          <app-up-file
                            tipo="select"
                            [urlServer]="'/revision/'+revision.codigo+'/categoria/'+categoria.codigo+'/file'"
                            (done)="upImgResult($event)"
                            [btLabel]="'Imagen'"
                            [typeFile]="'.jpg,.jpeg,.png'"></app-up-file>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>


                <div *ngIf="tipo.imgcategoria==0" class="row text-left" style="padding-top: 12px;" >
                  <div class="col-12 col-form-label" [hidden]="!estado && revision.imagenes.length == 0" >Fotografías Generales :</div>

                  <div class="col-12">
                    <a *ngFor="let img of revision.imagenes" [href]="img.url " target="_blank"><div  class="img-cat" [ngStyle]="{'background-image': 'url(' + img.url + ')'}"></div></a>

                    <div class="img-cat" *ngIf="revision.estado == 1">
                      <app-up-file
                        tipo="select"
                        [urlServer]="'/revision/'+revision.codigo+'/categoria/0/file'"
                        (done)="upImgResult($event)"
                        [btLabel]="'Imagen'"
                        [typeFile]="'.jpg,.jpeg,.png'"></app-up-file>
                    </div>
                  </div>
                </div>




              </section>





                <div class="col-12" *ngIf="estado" >
                  <div class="row " style="max-width: 600px;margin: 0px auto; ">

                    <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error2 !== '' " >
                      {{error2}}
                    </div>

                    <div class="btn-conten mb-4"  >
                    <div  class="butomForm" (click)="guardar()"  *ngIf="proceso==0">
                      <div class="img save"></div>
                      <div>Registrar</div>
                    </div>

                    <div  class="butomForm" (click)="update()"  *ngIf="proceso==1">
                      <div class="img update"></div>
                      <div>Actualizar</div>
                    </div>

                    <div *ngIf="revision.codigo!==0  && revision.estado == 1" class="butomForm" (click)="delete()">
                        <div class="img delete"></div>
                      <div>Eliminar</div>
                    </div>
                  </div>
                  </div>


                </div>


              </div>

                  <div class="row mt-5">
                    <div class="col-xs-12 col-12 text-center">
                      <pagination style="display: inline-block"  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"  [totalItems]="(npag*10 )" [maxSize]="5" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"></pagination>
                    </div>
                  </div>


            </div>





   </div>




  </div>
 </div>
