export class StyleMantenimiento{
  public static style =` .title{
    margin: 10px;;
  }
    .content{
      margin: 40px;
    }
  .content-vehiculo{
    border: solid 1px #cccccc;
    border-radius: 20px;
    padding: 10px;
    height: auto;
    overflow: hidden;
    margin: 10px;
  }

  .content-float{
    height: auto;
    overflow: hidden;
  }
  .cel-vehiculo{
    width: 25%;
    float: left;
  }

  .table-cell1{
    width: 30%;
    float: left;
  }
  .table-cell2{
    width: 60%;
    float: left;
  }

  .mt-2{
    margin-left: 10px;
  }

  .txt-float{
    width: 200px;
    float: left
  }

  td{
    padding: 10px;
    font-size: 12px;
  }

  th{
    color: #ffffff;
    padding:10px;
    background-color: #343a40;
    }
    .small{
      font-size: 10px;
    }
    .img-mant{
      width: 100px;
      height: 100px;
      float: left;
      margin: 10px;
      background-position: center center;
      background-size: auto 100%;
      background-repeat: no-repeat;
      border-radius: 5px;
    }`;
}
