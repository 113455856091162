<div class="modal-header">
  <h4 class="modal-title pull-left"><img [src]="icon" width="30" class="mr-2" > {{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="mensaje">
</div>
 <div class="modal-footer bg-blue text-white">
      <button type="button" class="btn btn-orange" data-dismiss="modal" (click)="bsModalRef.hide()">Cerrar</button>
</div>
