import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SelectOption } from 'src/app/models/select-option';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdministracionService } from '../administracion.service';

@Component({
  selector: 'app-categoria-servicio',
  templateUrl: './categoria-servicio.component.html',
  styleUrls: ['./categoria-servicio.component.css']
})
export class CategoriaServicioComponent implements OnInit {

  public categorias: SelectOption[]=[];

  public categoria: SelectOption;
  public error: string;
  public tractive: number;

  constructor(private service: AdministracionService,
               private mensaje: ModalMensajeService,
               private sesions: SesionService,
               private router: Router) {
    this.categoria = {
      codigo: null,
      descripcion: ''
    }
    this.error ='';
   }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(12)) {
      this.router.navigate(['/inicio']);
    }
  this.getCats();
  }

  getCats(){
    this.service.getCategorias().subscribe(result => {
     this.categorias = result.categorias;
    });
  }


  insertCategoria() {
    if(this.categoria.descripcion == ''){
      this.error ='ingrese una descripción para la categoría';
      return;
    }
    this.error ='';
    this.service.insertCategoria(this.categoria).subscribe(result => {
     this.categorias = result.categorias;
    });
  }



  enableCat(item: SelectOption){
    if(item.estado==0){return;}
    this.categorias.forEach(element => {
       element.enable = element.codigo == item.codigo;
       element.temp = element.descripcion;
    });
  }

  updateCat(item: SelectOption){
    if(item.temp.trim() == ''){
      this.error ='ingrese una descripción para el proveedor.';
      return;
    }

    if(item.descripcion.trim() !== item.temp.trim()){
      this.error ='';
      this.service.updateCategoria(item).subscribe(result => {
        if(result.estado){
          this.categorias = result.categorias;
        }else{
          this.mensaje.showMensaje('Error al realizar operación','error');
        }

      });
    }else{
      item.enable = false;
    }
  }

  deleteCat(item: SelectOption){
    if(item.estado==0){return;}
    this.mensaje.confirmMensaje('¿ Seguro que quiere eliminar la categoría ?').subscribe(arg => {
      if(arg){
        this.service.deleteCategoria(item.codigo).subscribe(result => {
          if (result.estado) {
             this.mensaje.showMensaje('Categoría eliminada','info')
          } else {
            this.mensaje.showMensaje('Error al realizar operación','error');
          }
         this.categorias = result.categorias;
        });
      }
    });
  }


}
