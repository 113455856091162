import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ModalMensajeComponent } from './modal-mensaje.component';

@Injectable({
  providedIn: 'root'
})
export class ModalMensajeService {
  bsModalRef: BsModalRef;
  public estado: boolean;

  constructor(private modalService: BsModalService) { }

  showMensaje(Mensaje: string, Tipo: string) {
    const initialState = {
      title: 'Mensaje',
      tipo: Tipo,
      mensaje: Mensaje,
      class: 'm-mensaje'
    };
    this.bsModalRef = this.modalService.show(ModalMensajeComponent, {initialState, class: 'm-mensaje'});
  }

 confirmMensaje(menj: string) {
  const initialState = {
    title: 'Confirmar',
    mensaje: menj
  };
  this.bsModalRef = this.modalService.show(ModalConfirmComponent, {initialState, class: 'm-mensaje'});

  return  this.bsModalRef.content.onClose;
 }

 /*showWait(menj: string) {
  const initialState = {
    mensaje: menj
  };
  // tslint:disable-next-line: max-line-length
  this.bsModalRef = this.modalService.show(ModalWaitComponent, { initialState, keyboard: false, ignoreBackdropClick: true, class: 'm-mensaje'});
 }*/
 closeWait() {
  this.bsModalRef.hide();
 }
 closeAll(){
  this.bsModalRef.hide();
 }

}
