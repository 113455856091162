import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AccidenteService } from 'src/app/accidente/accidente.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Accidente } from 'src/app/models/accidente';
import { usuario } from 'src/app/models/usuario';
import { Vehiculo } from 'src/app/models/vehiculo';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { StyleMantenimiento } from '../consulta-mantenimiento/constant';
import { VehiculoService } from '../vehiculo.service';

@Component({
  selector: 'app-consulta-accidente',
  templateUrl: './consulta-accidente.component.html',
  styleUrls: ['./consulta-accidente.component.css','../consulta-mantenimiento/consulta-mantenimiento.component.css']
})
export class ConsultaAccidenteComponent implements OnInit, OnDestroy {
  public suscripM: Subscription[]=[];
  public suscrip: Subscription[]=[];

  public accidente: Accidente;
  public vehiculo: Vehiculo;
  public codvehiculo: number;

  public user: usuario;
  constructor(public bsModalRef: BsModalRef,
    private serviceM: ModalService,
    private locations: PlatformLocation,
    private modalService: BsModalService,
    private sesion: SesionService,
    private mensaje: ModalMensajeService,
    private serviceaccidente: AccidenteService,
    private service: VehiculoService) {
      this.suscribModal();
      this.serviceM.nmodal = 1;
    }

  ngOnDestroy(): void {
    this.suscrip.forEach(element => {
      element.unsubscribe();
  });
  this.suscripM.forEach(element => {
    element.unsubscribe();
   });
  }
  suscribModal(){
    this.suscripM.push(this.modalService.onHide.subscribe((reason: string) => {
        this.suscripM.forEach(element => {
          element.unsubscribe();
        });
        this.suscrip = [];
        if(reason !== 'BACK') {
          history.back();
        }
     }));
  }


  ngOnInit(): void {

    this.suscrip.push(this.sesion.select$().subscribe(result => {
        this.user=result;
    }))

    history.pushState(null, '');
    this.locations.onPopState((event) => {
      // ensure that modal is opened

      if(this.serviceM.nmodal == 1){
        if(this.bsModalRef !== undefined) {
          this.modalService.setDismissReason('BACK');
         // if(!this.mensaje.estado) {
            this.bsModalRef.hide();
          // }else{
          //  this.mensaje.closeAll();
          //}
      }
      }

    });

    if(!this.vehiculo && this.codvehiculo) {
      this.service.getVehiculoCod(this.codvehiculo).subscribe(r => {
        this.vehiculo = r.vehiculo;
      });
    }

    this.getImagenes();
  }

  modAccidente(){
    this.service.eventPending = true;
    this.bsModalRef.hide();
  }

getImagenes(){
  this.serviceaccidente.getImagenes(this.accidente.codigo).subscribe(result => {
    this.accidente.imagenes = result.imagenes;
  });
}


print(){
  const cav =`<div style="text-align: center"><img height="40px" src="assets/GENERAL/logo.png"></div> <div style="text-align: center"><b>ACCIDENTE N° ${this.accidente.codigo}</b></div> `;

  let imgs='';
  this.accidente.imagenes.forEach(element => {
    imgs +='<img src="'+element.url+'" width="100%">';
  });
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
             ${StyleMantenimiento.style}
          </style>
        </head>
    <body onload="window.print();window.close()">
    ${cav}
    ${printContents}
    <br>
    ${imgs}
    </body>
    </html>`
    );
    popupWin.document.close();
  }


}
