import { Component, Directive, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { revisionCategoria } from 'src/app/models/revision-categoria';
import { tipoRevision } from 'src/app/models/tipo-revision';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { AdminrevisionService } from '../adminrevision.service';





@Component({
  selector: 'app-edit-tipo-revision',
  templateUrl: './edit-tipo-revision.component.html',
  styleUrls: ['./edit-tipo-revision.component.css']
})
export class EditTipoRevisionComponent implements OnInit {
  public error: string="";
  public tipo: tipoRevision;
  public sub: any;

  public codigo: number=0;



  constructor(private servicio: AdminrevisionService,
              private route: ActivatedRoute,
              private router: Router,
              private sesions: SesionService,
              private mensaje: ModalMensajeService) {

    this.tipo={
      codigo:null,
      descripcion: ''
    }

    this.sub = this.route.params.subscribe(params => {
      if(params.codigo){
        this.codigo = parseInt(params.codigo);
        this.getTipoRevision();
      }
     });
   }

  ngOnInit(): void {
    if(!this.sesions.validOpcion(11)) {
      this.router.navigate(['/inicio']);
    }
    if(this.codigo==0){
      this.getTipoRevision();
    }
  }

  getTipoRevision(){
    this.servicio.getTipoRevision(this.codigo).subscribe(result => {

      this.tipo = result.tipo;
      this.tipo.items.forEach(element => {
          element.items.forEach(i => {
            i.enable = i.estado==1;
            if(i.enable){
              element.check=true;
            }
          });
      });
    });
  }


  validar(){
    if(!this.tipo.descripcion || this.tipo.descripcion.trim()==''){
      this.error ='Ingrese una descripción.';
      return false;
    }

    if(this.tipo.agrupar == undefined){
      this.error ='Seleccione si quiere paginar los item por categoría.';
      return false;
    }
    if(this.tipo.imgcategoria == undefined){
      this.error ='Seleccione como subir registro fotográfico.';
      return false;
    }
    if(this.tipo.observacioncategoria == undefined){
      this.error ='Seleccione como ingresar la observación.';
      return false;
    }


    let n =0;
    this.tipo.items.forEach(element => {
      element.items.forEach(i => {
        if(i.enable){
          n++;
        }
      });
  });

  if(n==0) {
    this.error ='Seleccione por lo menos un item para el tipo de revisión.';
    return false;
  }

  this.error ='';
  return true;

  }


  insertTipo() {

    if(!this.validar()){return;}

    this.servicio.insertTipoRevision(this.tipo).subscribe(result => {
       if(result.estado) {
        this.router.navigate(['/administracion/revision/tipo']);
       }else{
         this.mensaje.showMensaje('Error al realizar operación.','error');
       }
    });
  }


  updateTipo(){
    if(!this.validar()){return;}
    this.servicio.updateTipoRevision(this.tipo).subscribe(result => {
       if(result.estado) {
        this.router.navigate(['/administracion/revision/tipo']);
       }else{
         this.mensaje.showMensaje('Error al realizar operación.','error');
       }
    });
  }

  chechAll(cat: revisionCategoria){
      cat.items.forEach(element => {
        element.enable = cat.check;
      });
  }
}
