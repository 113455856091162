
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VehiculoService } from 'src/app/consulta-vehiculo/vehiculo.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Vehiculo } from 'src/app/models/vehiculo';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { CombustibleService } from './combustible.service';

@Component({
  selector: 'app-combustible',
  templateUrl: './combustible.component.html',
  styleUrls: ['./combustible.component.css']
})
export class CombustibleComponent implements OnInit {
  public tractive: number;
  public sub: any;
  public bsConfig = { dateInputFormat: 'YYYY/MM/DD',
  containerClass: 'theme-default',
  isAnimated: false,
  adaptivePosition: true };
  maxDate = new Date();

  public vehiculo: Vehiculo;

  public placavehiculo: string;
  public error: string;
  public error2: string;
  public asyncSelected ="";
  public codigo:number=0;
  public isUpdate: boolean;

  public formGroup: UntypedFormGroup;

  constructor(  private service: CombustibleService,
                private servicevehiculo: VehiculoService,
                private route: ActivatedRoute,
                private router: Router,
                private sesions: SesionService,
                private formBuilder: UntypedFormBuilder,
                private mensaje: ModalMensajeService) {
    this.error = '';
    this.error2 = '';
    this.sub = this.route.params.subscribe(params => {
      if(params.placa) {
        this.placavehiculo =params.placa ;
        this.codigo = params.codigo;
        this.isUpdate =  this.codigo > 0;
        this.consultarVehiculo();
      }
     });
  }

  ngOnInit(): void {

    if(!this.sesions.validOpcion(17)) {
      this.router.navigate(['/inicio']);
    }

    if(this.isUpdate && this.sesions.sesion.perfil !=1){
      this.router.navigate(['/inicio']);
    }

    this.buildForm();
  }



   onSelectConductor(data: any): void {
     this.formGroup.controls.conductor.setValue(data.codigo);
     this.formGroup.controls.nombreconductor.setValue(data.nombre);
   }


  buildForm() {
    this.formGroup = this.formBuilder.group({
      codigo: [0],
      vehiculo: ['', Validators.required],
      fecha: ['', Validators.required],
      fechasis: [''],
      usuariosis: [''],
      conductor: ['', Validators.required],
      nombreconductor:[''],
      kminicial: [null, Validators.required],
      kmanterior:[null, Validators.required],
      consumo: [null, Validators.required],
      valortotal:[null,Validators.required],
      observacion: ['']
     });

 }

 consultarPlaca(){
  if(!this.placavehiculo || this.placavehiculo.trim() == '') {
    this.error='Ingrese la placa del vehículo a consultar.';
    return;
  }else{
    this.error ='';
  }

  this.router.navigate(['/add/combustible',this.placavehiculo]);

}

consultarVehiculo(){
  if(!this.placavehiculo || this.placavehiculo.trim() == '') {
    this.error='Ingrese la placa del vehículo a consultar.';
    return;
  }else{
    this.error ='';
  }
  this.servicevehiculo.getVehiculo(this.placavehiculo).subscribe(result => {
     this.vehiculo = result.vehiculo;
     if(this.vehiculo.conductor > 0) {
       this.formGroup.controls.conductor.setValue(this.vehiculo.conductor);
       this.asyncSelected = this.vehiculo.nombreconductor;
       this.formGroup.controls.nombreconductor.setValue(this.vehiculo.nombreconductor);
     }
     if(this.isUpdate){
      this.service.getTanqueo(this.codigo).subscribe( tanq => {
         this.setTanqueo(tanq);
      });
    }else{
      this.service.getUltmKm(this.vehiculo.codigo).subscribe(r => this.formGroup.controls.kmanterior.setValue(r.km));
    }

  });
}

setTanqueo(tanq:any) {
  const{tanqueo} = tanq;
  this.formGroup.controls.codigo.setValue(tanqueo.codigo);
  this.formGroup.controls.vehiculo.setValue(tanqueo.vehiculo);
  this.formGroup.controls.fecha.setValue(tanqueo.fecha);
  this.formGroup.controls.conductor.setValue(tanqueo.conductor);
  this.formGroup.controls.nombreconductor.setValue(tanqueo.nombreconductor);
  this.asyncSelected = tanqueo.nombreconductor;
  this.formGroup.controls.kminicial.setValue(tanqueo.kminicial);
  this.formGroup.controls.kmanterior.setValue(tanq.kmAnterior);
  this.formGroup.controls.consumo.setValue(tanqueo.consumo);
  this.formGroup.controls.valortotal.setValue(tanqueo.valortotal);
  this.formGroup.controls.observacion.setValue(tanqueo.observacion);
}


validarForm(): boolean {

  this.error = '';
  this.formGroup.controls.vehiculo.setValue(this.vehiculo.codigo);

  if(!this.vehiculo) {
    this.error ='Ingrese un vehiculo';
    return false;
  }

  if (this.formGroup.controls.conductor.errors) {
    this.error = 'Seleccione el conductor del vehículo.';
    return false;
  }

  if (this.formGroup.controls.fecha.errors) {
    this.error = 'Seleccione la fecha del tanqueo.';
    return false;
  }

  if (this.formGroup.controls.kminicial.errors) {
    this.error = 'Ingrese el kilometraje actual del vehículo.';
    return false;
  } else {
    const km = this.formGroup.controls.kminicial.value - this.formGroup.controls.kmanterior.value;
     if(km < 0) {
      this.error = 'Ingrese un kilometraje mayor al ultimo.';
      return false;
     }
  }

  if (this.formGroup.controls.consumo.errors) {
    this.error = 'Ingrese el consumo de combustible.';
    return false;
  }

  if (this.formGroup.controls.valortotal.errors) {
    this.error = 'Ingrese el valor del combustible tanqueado.';
    return false;
  }

  return this.error == '';
}

registrarCombustible(){
  if(!this.validarForm()) {return;}

    this.service.insertCombustible(this.formGroup.value).subscribe( result => {
        if(result.estado) {
          const menj = this.isUpdate ? 'Consumo de combustible actualizado.' : 'Consumo de combustible registrado en la base de datos';
          this.mensaje.showMensaje(menj, 'info');
          this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/17']);
        }else{
          this.mensaje.showMensaje('No se pudo realizar la operación', 'error');
        }
    });
  }


descartar() {
  this.formGroup.reset();
  if (this.isUpdate) {
    this.router.navigate(['/consultavehiculo/'+this.vehiculo.placa+'/17']);
  }
}



}
