<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Detalles Comparendo</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">

  <section *ngIf="comparendo">

    <div *ngIf="vehiculo" class="content" id="print-section">
      <div class="title"><b>DATOS VEHÍCULO</b></div>
      <div class="content-vehiculo">
        <div>
          <div class="cel-vehiculo">
            <div class="small">Placa:</div>
            <div>
              <b>{{ vehiculo.placa }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Marca:</div>
            <div>
              <b>{{ vehiculo.marcadescrip }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Tipo:</div>
            <div>
              <b>{{ vehiculo.tipodescrip }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Color:</div>
            <div>
              <b>{{ vehiculo.color }}</b>
            </div>
          </div>
        </div>
      </div>



      <div class="content-float">
        <div>
          <div class="table-cell1">Usuario Comparendo :</div>
          <div class="table-cell2">
            <b>{{comparendo.usuariosisnombre}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Fecha :</div>
          <div class="table-cell2">
            <b>{{comparendo.fecha}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Tipo Responsable: </div>
          <div class="table-cell2">
            <b>{{comparendo.tiporesponsable==1?'Conductor':'Propietario'}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Id Responsable:</div>
          <div class="table-cell2">
            <b>{{comparendo.idresponsable}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Responsable:</div>
          <div class="table-cell2">
            <b>{{comparendo.responsable}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Valor :</div>
          <div class="table-cell2">
            <b>$ {{comparendo.valor | number}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Asociado al trabajo : </div>
          <div class="table-cell2">
            <b>{{comparendo.trabajo==1?'Si': 'No'}}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Estado Pago: </div>
          <div class="table-cell2">
            <select class="custom-select" [(ngModel)]="estadopago">
              <option [ngValue]="1" >Pagado</option>
              <option [ngValue]="0" >Pendiente</option>
            </select>
          </div>
        </div>


        <div *ngIf="user.perfil==1" class="col-12 text-center " style="padding-top: 30px;" >
          <br> <button type="button" (click)="update()" class="btn btn-warning">Actualizar Estado</button> <br></div>

      </div>

      </div>



      <br />
      <div class="row">
        <div class="col text-center">

          <button *ngIf="user.perfil==1" type="button" (click)="modComparendo()" class="btn btn-warning mr-2" >
            Modificar
          </button>

          <button type="button" (click)="print()" class="btn btn-warning">
            Imprimir
          </button>
        </div>

      </div>

      <br />
  </section>

</div>
