import { Injectable } from '@angular/core';
import { NgHttpService } from '../core/http/ng-http.service';
import { Combustible } from '../models/combustible';

@Injectable({
  providedIn: 'root'
})
export class CombustibleService {

  constructor(private http: NgHttpService) { }

  getUltmKm(codigo: number) {
    return this.http.get('combustible/vehiculo/'+codigo+'/km');
  }
  insertCombustible(comp: Combustible) {
    return  this.http.post('combustible', comp);
   }
   getTanqueo(codigo: number) {
    return  this.http.get('combustible/tanqueo/'+codigo);
   }

}
