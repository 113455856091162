import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FootpageComponent } from './footpage/footpage.component';
import { ModalMensajeComponent } from './modal-mensaje/modal-mensaje.component';
import { NavbarComponent } from './navbar/navbar.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { SearchConductorComponent } from './search-conductor/search-conductor.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [FootpageComponent, ModalMensajeComponent, NavbarComponent, ModalConfirmComponent, SearchConductorComponent, LoaderComponent],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    TypeaheadModule.forRoot(),
    FormsModule,
  ],
  exports: [NavbarComponent,FootpageComponent,BsDropdownModule, SearchConductorComponent, LoaderComponent]
})
export class ShareModule { }
