import { HttpClient, HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalMensajeService } from 'src/app/share/modal-mensaje/modal-mensaje.service';
import { NgHttpService } from '../../http/ng-http.service';
import { WaitService } from '../../services/wait.service';
import { map } from 'rxjs/operators';

export interface FileHandle {
  file: File;
  url: SafeUrl;
}

@Component({
  selector: 'app-up-file',
  templateUrl: './up-file.component.html',
  styleUrls: ['./up-file.component.css']
})
export class UpFileComponent implements OnInit {

  public uploadResponse: any = { status: '', message: '', filePath: ''};
  public error: any;
  public formFile: UntypedFormGroup;
  public uploading: boolean;
  public url: string;

  bsModalRef: BsModalRef;

  public file: any;


  @Input() mensaje: string;
  @Input('urlServer') set urlServer(urlServer: string) {
    if (urlServer) {
      this.url = this.http.server + urlServer;
    }
}


  @Input() data: any;
  @Input() tipo: string;
  @Input() btLabel: string;
  @Input() typeFile: string;
  @Input() crop: boolean;
  @Input() icon: string;

  @Output() done: EventEmitter<any> = new EventEmitter();
  @Output() changeProgess: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileinput') fileinput: ElementRef;

  @Input('activefile') set activefile(estado: boolean) {
    if(this.fileinput){
      this.fileinput.nativeElement.click();
    }

  }
  constructor(private sanitizer: DomSanitizer,
    private http: NgHttpService,
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private element: ElementRef,
    private render: Renderer2,
    private waitservice: WaitService,
    private ngMensaje: ModalMensajeService,
    private modalService: BsModalService) {
      this.url = '';
                this.uploading = false;
                this.formFile = this.formBuilder.group({ file: [] });
    }


  ngOnInit(): void {
    this.mensaje = !this.mensaje ? '' : this.mensaje;
    this.btLabel = !this.btLabel ? 'Seleccionar' : this.btLabel;
  }

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.render.addClass(this.element.nativeElement, 'active');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    const files: FileHandle[] = [];

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files.push({ file, url });
    }

    if (files.length > 0) {
     if (this.validExtension( files[0].file.name)) {
     /* const formData = new FormData();
      formData.append('file', files[0].file);
      formData.append('data', this.data);
      this.upFile(formData);*/
      if (this.crop) {console.log(evt);
        this.viewCropImagen(evt);
      } else {
        this.upFile(files[0].file);
      }
     } else {
       this.ngMensaje.showMensaje('Tipo de archivo inválido. debe subir un archivo con extensión (' + this.typeFile + ').', 'error');
       return;
     }
    }
  }



// seleccionar archivoo
onFileChange(event) {
  event.preventDefault();
  event.stopPropagation();

  if (event.target.files.length > 0) {
    this.file = event.target.files[0];

    if (this.validExtension( this.file.name)) {
      if (this.crop) {
        console.log(event);
        this.viewCropImagen(event);
      } else {
        this.upFile(this.file);
      }
     } else {
       this.ngMensaje.showMensaje('Tipo de archivo inválido. debe subir un archivo con extensión (' + this.typeFile + ').', 'error');
       return;
     }

  }



 /* if (event.target.files.length > 0) {
    const file = event.target.files[0];

    if (this.validExtension( file.name)) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('data', this.data);
      this.upFile(formData);
     } else {
       this.ngMensaje.showMensaje('Tipo de archivo inválido. debe subir un archivo con extensión (' + this.typeFile + ').', 'error');
       return;
     }

  }*/
}

cropUpFile(data: any) {
  this.upFile(data.file);
  //console.log('URL::::::' + this.url);
}


 upFile( file: any) {

  const formData = new FormData();
  formData.append('file', file);
  formData.append('data', this.data);

  const uploadURL = this.url;
  this.uploading = true;
  this.uploadResponse = { status: '', message: '', filePath: '' };

  this.waitservice.disable = true;
  this.httpClient.post<any>(uploadURL, formData, {
    reportProgress: true,
    observe: 'events'
  }).pipe(map((event) => {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        const progress = Math.round(100 * event.loaded / event.total);
        this.uploading = progress < 100;
        this.changeProgess.emit({ status: 'progress' , message: progress });
        return { status: 'progress' , message: progress };

      case HttpEventType.Response:
        return  event.body;
      default:
        return `Unhandled event: ${event.type}`;
    }
  })
  ).subscribe(
     (res) => {

              this.uploadResponse = res;
              if (res.estado) {
                this.waitservice.disable = false;
                this.done.emit(res);
              }
      },
     (err) => {
       this.error = err;
       this.uploading = false;
      }
     );

 }


validExtension(filename: string) {
  let result = false;

  if (this.typeFile) {
   const array = filename.split('.');
   const extension = array[array.length - 1];
   console.log('extension: ' + extension.toUpperCase());
   const types = this.typeFile.split(',');
   types.forEach(element => {
     if (extension.toUpperCase().trim() == element.toUpperCase().replace('.', '').trim()) {
       result = true;
       return;
     }
   });
   } else {
    result = true;
   }

  return result;
}




viewCropImagen(event) {
 /* const initialState = {
    title: 'Editar Imagen',
    imageChangedEvent: event
   };
  this.bsModalRef = this.modalService.show(CropImgComponent, {initialState});
  this.bsModalRef.content.upImg.subscribe(this.cropUpFile.bind(this));*/
   }

}
