<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Detalles Mantenimiento</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <div *ngIf="mantenimiento" id="print-section">

    <div *ngIf="vehiculo" class="content">
      <div class="title"><b>DATOS VEHÍCULO</b></div>
      <div class="content-vehiculo">
        <div>
          <div class="cel-vehiculo">
            <div class="small">Placa:</div>
            <div>
              <b>{{ vehiculo.placa }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Marca:</div>
            <div>
              <b>{{ vehiculo.marcadescrip }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Tipo:</div>
            <div>
              <b>{{ vehiculo.tipodescrip }}</b>
            </div>
          </div>
          <div class="cel-vehiculo">
            <div class="small">Color:</div>
            <div>
              <b>{{ vehiculo.color }}</b>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        Usuario Mantenimiento: <b>{{ mantenimiento.usuariosisnombre }}</b>
      </div>
      <br />
      <div><b>GENERAL</b></div>

      <div class="content-float">
        <div>
          <div class="table-cell1">Fecha :</div>
          <div class="table-cell2">
            <b>{{ mantenimiento.fecha }}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Tipo :</div>
          <div class="table-cell2">
            <b>{{ mantenimiento.tipodescrip }}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Mecánico :</div>
          <div class="table-cell2">
            <b>{{ mantenimiento.mecanico }}</b>
          </div>
        </div>

        <div>
          <div class="table-cell1">Km :</div>
          <div class="table-cell2" *ngIf="!isEditKm">
            <b>{{ mantenimiento.km | number }} km. </b>
            <!-- <img
              *ngIf="sesions.sesion.perfil == 1 && !isEditKm"
              src="assets/GENERAL/mod.png"
              class="mt-2"
              title="Modificar Kilometraje"
              width="20"
              (click)="editKm(true)"
            /> -->
          </div>

          <!-- <div class="table-cell2" *ngIf="isEditKm">
            <div class="content-float">
            <input
              type="text"
              class="form-control txt-float"
              placeholder="Kilometraje"
              [(ngModel)]="kmTemp"
            />
            <img
              src="assets/GENERAL/update.png"
              title="Actualizar Kilometraje"
              class="mt-2"
              width="20"
              (click)="updateKm()"
            />
            <img
              src="assets/GENERAL/descartar.png"
              title="Descartar Cambios"
              class="mt-2"
              (click)="editKm(false)"
              width="20"
            />
          </div>
          <div><small id="emailHelp" class="form-text text-muted text-left"> Anterior: {{ultKm | number}} km.</small></div>
        </div> -->

        </div>

        <div>
          <div class="table-cell1">Problema :</div>
          <div class="table-cell2">
            {{ mantenimiento.problema }}
          </div>
        </div>

        <div>
          <div class="table-cell1">Observación :</div>
          <div class="table-cell2">
            {{ mantenimiento.observacion }}
          </div>
        </div>
      </div>

      <br />
      <div><b>SERVICIOS</b></div>

      <div style="border: solid 1px #cccccc">
        <table
          width="100%"
          border="1"
          cellspacing="0"
          bordercolor="#cccccc"
          rules="rows"
          frame="void"
        >
          <tr>
            <th width="10%">Fact</th>
            <th width="35%">SERVICIO</th>
            <th width="35%">OBSERVACIÓN</th>
            <th width="20%">$_UNIDAD</th>
            <th width="15%">CANTIDAD</th>
            <th width="20%">TOTAL</th>
          </tr>

          <tr *ngFor="let item of servicios; let i = index">
            <td>{{ item.numfactura }}</td>
            <td>
              <div>{{ item.serviciodescripcion }}</div>
              <div class="small">{{ item.proveedordescrip }}</div>
            </td>
            <td>
              <div class="small">{{ item.observacion }}</div>
            </td>
            <td>
              <b>$ {{ item.valorunidad | number }}</b>
            </td>
            <td>
              <b> {{ item.cantidad }}</b>
            </td>
            <td>
              <b>$ {{ item.valorunidad * item.cantidad | number }}</b>
            </td>
          </tr>
        </table>
      </div>

      <br />

      <div *ngIf="imagenes.length > 0" style="padding-top: 12px; height: auto;overflow: hidden;">
        <div>Imágenes Facturas:</div>

        <div>
          <a *ngFor="let img of imagenes" [href]="img.url" target="_blank"
            ><div
              class="img-mant"
              [ngStyle]="{ 'background-image': 'url(' + img.url + ')' }"
            ></div
          ></a>
        </div>
      </div>
    </div>
  </div>
    <br />

    <div class="row">
      <div class="col text-center">
        <button  *ngIf="sesions.sesion.perfil == 1" type="button" (click)="modMantenimiento()" class="btn btn-warning mr-2" >
          Modificar
        </button>

        <button type="button" (click)="print()" class="btn btn-warning">
          Imprimir
        </button>
      </div>

    </div>

    <br />

</div>
