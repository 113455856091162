import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SesionService } from '../core/services/sesion.service';
import { UtilService } from '../core/services/util.service';
import { Accidente } from '../models/accidente';
import { Combustible } from '../models/combustible';
import { Comparendo } from '../models/comparendo';
import { Mantenimiento } from '../models/mantenimiento';
import { Modulos } from '../models/modulos';
import { Revision } from '../models/revision';
import { Tab } from '../models/tab';
import { usuario } from '../models/usuario';

import { Vehiculo } from '../models/vehiculo';
import { ConfigPromedioComponent } from './config-promedio/config-promedio.component';
import { ConsultaAccidenteComponent } from './consulta-accidente/consulta-accidente.component';
import { ConsultaCombustibleComponent } from './consulta-combustible/consulta-combustible.component';
import { ConsultaComparendoComponent } from './consulta-comparendo/consulta-comparendo.component';
import { ConsultaMantenimientoComponent } from './consulta-mantenimiento/consulta-mantenimiento.component';
import { ConsultaRevisionComponent } from './consulta-revision/consulta-revision.component';
import { NovedadesGraficaComponent } from './novedades-grafica/novedades-grafica.component';
import { VehiculoService } from './vehiculo.service';

@Component({
  selector: 'app-consulta-vehiculo',
  templateUrl: './consulta-vehiculo.component.html',
  styleUrls: ['./consulta-vehiculo.component.css'],
})
export class ConsultaVehiculoComponent implements OnInit {
  public sub: any;
  public vehiculo: Vehiculo;
  public codUser: number = 0;

  public placavehiculo: string = '';
  public error: string;
  public tractive: number;

  public tabs: Tab[] = [];
  public activetabs: Tab[] = [];
  public activetag: Tab;

  public daterangepickerModel: Date[];
  public fecha = new Date();

  public novedades: Mantenimiento[];
  public promkm: number = 0;
  public promkmCalculado: number = 0;
  public promkmvehiculo: any;
  public diasalert: number = 0;
  public diasalertsoat: number = 0;
  public diasalerttecnomecanica: number = 0;

  public revisiones: Revision[];
  public mantenimientos: Mantenimiento[];
  public combustible: Combustible[];
  public comparendos: Comparendo[];
  public accidentes: Accidente[];

  public bsConfig = {
    dateInputFormat: 'YYYY/MM/DD',
    containerClass: 'theme-default',
    isAnimated: false,
    adaptivePosition: true,
  };
  maxDate = new Date();

  public currentPage: number = 1;
  public totalitem: number;

  bsModalRef: BsModalRef;
  public sesion: usuario = { codigo: 0, perfil: -1 };

  asyncSelected: string = '';
  public tabIni: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: VehiculoService,
    private util: UtilService,
    public sesions: SesionService,
    private modal: BsModalService
  ) {
    this.error = '';
    this.novedades = [];
    this.revisiones = [];
    this.mantenimientos = [];
    this.combustible = [];
    this.comparendos = [];
    this.accidentes = [];

    this.tabs = [
      { codigo: 1, title: 'Datos Generales', active: true, enable: true },
      { codigo: 2, title: 'Novedades', active: false, enable: true },
      { codigo: 3, title: 'Revisiones', active: false, enable: false },
      { codigo: 4, title: 'Mantenimientos', active: false, enable: false },
      { codigo: 17, title: 'Combustible', active: false, enable: false },
      { codigo: 5, title: 'Comparendos', active: false, enable: false },
      { codigo: 6, title: 'Accidentes', active: false, enable: false },
    ];
    this.activetag = this.tabs[0];

    this.sesions.select$().subscribe((s) => {
      this.sesion = s;
      if (this.sesion) {
        if (this.sesion.vehiculos == 1) {
          this.codUser = this.sesion.codigo;
          this.asyncSelected = this.sesion.nombre;
        }

        const mod = this.sesion.modulos.find((item) => item.codigo == 2);
        this.setTab(mod);
      }
    });
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      let key = false;
      if (params.placa) {
        this.placavehiculo = params.placa;
        key = true;
      }

      if (params.codigo) {
        this.codUser = params.codigo;
        this.asyncSelected = params.nombre;
        key = true;
      }

      if (params.tab) {
        const index = this.activetabs.findIndex(
          (i) => i.codigo == parseInt(params.tab)
        );
        this.tabIni = index < 0 ? null : index;
      }

      if (key) {
        this.consultarVehiculo();
      }
    });
  }

  onSelectConductor(data: any) {
    this.codUser = data.codigo;
    this.asyncSelected = data.nombre;
    this.activeTab(
      this.tabs.findIndex((i) => i.codigo == this.activetag.codigo)
    );
  }

  setTab(mod: Modulos) {
    this.tabs[2].enable =
      mod.opciones.findIndex((item) => item.codigo == 18) >= 0;
    this.tabs[3].enable =
      mod.opciones.findIndex((item) => item.codigo == 19) >= 0;
    this.tabs[4].enable =
      mod.opciones.findIndex((item) => item.codigo == 22) >= 0;
    this.tabs[5].enable =
      mod.opciones.findIndex((item) => item.codigo == 20) >= 0;
    this.tabs[6].enable =
      mod.opciones.findIndex((item) => item.codigo == 21) >= 0;

    this.activetabs = this.tabs.filter((t) => t.enable);
  }

  consultarVehiculo() {
    if (
      (!this.placavehiculo || this.placavehiculo.trim() == '') &&
      (!this.codUser || this.codUser == 0)
    ) {
      this.error = 'Ingrese la placa o el conductor del vehículo a consultar.';
      this.vehiculo = null;
      return;
    } else {
      this.error = '';
    }

    if (this.placavehiculo && this.placavehiculo.trim() != '') {
      this.service.getVehiculo(this.placavehiculo).subscribe((result) => {
        this.vehiculo = result.vehiculo;
        this.activeTab(this.tabIni ?? 0);
      });
    } else {
      this.vehiculo = null;
      this.activeTab(this.tabIni ?? 2);
    }
  }

  getNovedades() {
    if (!this.vehiculo) return;
    this.service.getNovedades(this.vehiculo.codigo).subscribe((result) => {
      this.novedades = result.novedades;
      this.promkmCalculado = parseInt(result.promkm);
      this.diasalert = parseInt(result.diasalert);
      this.diasalertsoat = parseInt(result.diasalertsoat);
      this.diasalerttecnomecanica = parseInt(result.diasalerttecnomecanica);
      this.promkmvehiculo = result.promkmvehiculo;

      if (this.promkmvehiculo && this.promkmvehiculo.kmpromtipo == 1) {
        this.promkm = this.promkmvehiculo.kmprom;
      } else {
        this.promkm = this.promkmCalculado;
      }

      this.novedades.forEach((element) => {
        let servicior = element.servicior;
        let dias = 0;
        if (element.codigo != 0) {
          if (servicior.tipo == 1) {
            let kmpendientes =
           //   servicior.kilometraje - element.ultkm + element.km;
                servicior.kilometraje - element.totalkm;


            if (kmpendientes > 0) {
              dias = Math.round(kmpendientes / this.promkm);
              //console.log( kmpendientes+"/"+this.promkm+ "="+dias)
            } else {
              dias = 0;
            }

            element.estado =
              element.servicior.kilometraje - element.totalkm <=
                element.servicior.kmnotificar || dias <= this.diasalert
                ? 0
                : 1;
          } else {
            let fecha = new Date(element.fecha);
            fecha.setDate(fecha.getDate() + servicior.dias);

            var diasdif = fecha.getTime() - new Date().getTime();
            dias = Math.round(diasdif / (1000 * 60 * 60 * 24));

            element.estado = dias <= element.servicior.diasnotificar ? 0 : 1;
          }
        }

        element.diaspendientes = dias;
      });
    });
  }

  getRevisiones() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }

    const vehiculo = this.vehiculo ? this.vehiculo.codigo : 0;
    this.service
      .getRevisiones(this.codUser, vehiculo, desde, hasta, this.currentPage)
      .subscribe((result) => {
        this.revisiones = result.revisiones;
        this.totalitem = result.total;
      });
  }

  getMantenimientos() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    const vehiculo = this.vehiculo ? this.vehiculo.codigo : 0;
    this.service
      .getMantenimientos(this.codUser, vehiculo, desde, hasta, this.currentPage)
      .subscribe((result) => {
        this.mantenimientos = result.mantenimientos;
        this.totalitem = result.total;
      });
  }

  getCombustible() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    const vehiculo = this.vehiculo ? this.vehiculo.codigo : 0;
    this.service
      .getCombustible(this.codUser, vehiculo, desde, hasta, this.currentPage)
      .subscribe((result) => {
        this.combustible = result.consumos;
        this.totalitem = result.total;
      });
  }

  getComparendos() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    const vehiculo = this.vehiculo ? this.vehiculo.codigo : 0;
    this.service
      .getComparendos(this.codUser, vehiculo, desde, hasta, this.currentPage)
      .subscribe((result) => {
        this.comparendos = result.comparendos;
        this.totalitem = result.total;
      });
  }

  getAccidentes() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    const vehiculo = this.vehiculo ? this.vehiculo.codigo : 0;
    this.service
      .getAccidentes(this.codUser, vehiculo, desde, hasta, this.currentPage)
      .subscribe((result) => {
        this.accidentes = result.accidentes;
        this.totalitem = result.total;
      });
  }

  activeTab(index: number) {
    if (!this.activetabs[index] || !this.activetabs[index].enable) {
      return;
    }

    this.activetabs.forEach((tab) => {
      tab.active = false;
    });

    switch (this.activetabs[index].codigo) {
      case 2: {
        this.getNovedades();
        break;
      }
      case 3: {
        this.getRevisiones();
        break;
      }
      case 4: {
        this.getMantenimientos();
        break;
      }
      case 17: {
        this.getCombustible();
        break;
      }
      case 5: {
        this.getComparendos();
        break;
      }
      case 6: {
        this.getAccidentes();
        break;
      }
    }

    this.activetag = this.activetabs[index];
    this.activetabs[index].active = true;
  }

  pageChanged(event: any) {
    this.currentPage = event.page;
    switch (this.activetag.codigo) {
      case 2: {
        this.getNovedades();
        break;
      }
      case 3: {
        this.getRevisiones();
        break;
      }
      case 4: {
        this.getMantenimientos();
        break;
      }
      case 17: {
        this.getCombustible();
        break;
      }
      case 5: {
        this.getComparendos();
        break;
      }
      case 6: {
        this.getAccidentes();
        break;
      }
    }
  }

  newRevision() {
    this.router.navigate(['/add/revision', this.placavehiculo]);
  }

  detalleRevision(rev: Revision) {
    if (rev.estado == 0) {
      const initialState = {
        revision: rev,
        vehiculo: this.vehiculo,
        codvehiculo: rev.vehiculo,
      };
      this.bsModalRef = this.modal.show(ConsultaRevisionComponent, {
        initialState,
        class: 'modal-700',
      });
      this.bsModalRef.content.refres.subscribe(this.updateView.bind(this));
    } else {
      this.router.navigate([
        '/add/revision',
        { revision: rev.codigo, placa: rev.vehiculoplaca },
      ]);
    }
  }

  updateView() {
    this.activeTab(2);
  }

  newMantenimiento() {
    this.router.navigate(['/add/mantenimiento/'+this.placavehiculo ]);
  }
  detalleMantenimiento(mant: Mantenimiento) {
    const initialState = {
      mantenimiento: mant,
      vehiculo: this.vehiculo,
      codvehiculo: mant.vehiculo,
    };
    this.bsModalRef = this.modal.show(ConsultaMantenimientoComponent, {
      initialState: initialState,
      class: 'modal-700',
    });

    this.bsModalRef.onHidden.subscribe(res => {
      if( this.service.eventPending){
        this.service.eventPending = false;
        this.router.navigate(['/add/mantenimiento/'+this.vehiculo.placa+'/'+mant.codigo ]);
      }

    })
  }

  newTanqueo() {
    this.router.navigate(['/add/combustible', this.placavehiculo]);
  }

  detalleTanqueo(com: Combustible) {
    const initialState = {
      consumo: com,
      codvehiculo: com.vehiculo,
      vehiculo: this.vehiculo,
    };
    this.bsModalRef = this.modal.show(ConsultaCombustibleComponent, {
      initialState: initialState,
      class: 'modal-700',
    });

    this.bsModalRef.onHidden.subscribe(res => {
      if( this.service.eventPending){
        this.service.eventPending = false;
        this.router.navigate(['/add/combustible/'+this.vehiculo.placa+'/'+com.codigo ]);
      }
    });
  }

  newComparendo() {
    this.router.navigate(['/add/comparendo', this.placavehiculo]);
  }
  detalleComparendo(com: Comparendo) {
    const initialState = {
      comparendo: com,
      codvehiculo: com.vehiculo,
      vehiculo: this.vehiculo,
    };
    this.bsModalRef = this.modal.show(ConsultaComparendoComponent, {
      initialState: initialState,
      class: 'modal-700',
    });

    this.bsModalRef.onHidden.subscribe(res => {
      if( this.service.eventPending){
        this.service.eventPending = false;
        this.router.navigate(['/add/comparendo/'+this.vehiculo.placa+'/'+com.codigo ]);
      }
    });

  }

  newAccidente() {
    this.router.navigate(['/add/accidente', this.placavehiculo]);
  }

  detalleAccidente(accidente: Accidente) {
    const initialState = {
      accidente: accidente,
      codvehiculo: accidente.vehiculo,
      vehiculo: this.vehiculo,
    };
    this.bsModalRef = this.modal.show(ConsultaAccidenteComponent, {
      initialState: initialState,
      class: 'modal-700',
    });

    this.bsModalRef.onHidden.subscribe(res => {
      if( this.service.eventPending){
        this.service.eventPending = false;
        this.router.navigate(['/add/accidente/'+this.vehiculo.placa+'/'+accidente.codigo ]);
      }
    });

  }

  showGraph(vehiculo: number, placa: string) {
    const initialState = {
      vehiculo: vehiculo,
      placa: placa,
    };
    this.bsModalRef = this.modal.show(NovedadesGraficaComponent, {
      initialState: initialState,
      class: 'modal-800',
    });
  }

  showConfigProm() {
    const initialState = {
      vehiculo: this.vehiculo.codigo,
      placa: this.vehiculo.placa,
      promcalculado: this.promkmCalculado,
      promfijo: this.promkmvehiculo.kmprom,
      promtipo: this.promkmvehiculo.kmpromtipo,
    };
    this.bsModalRef = this.modal.show(ConfigPromedioComponent, {
      initialState: initialState,
      class: 'modal-500',
    });
    this.bsModalRef.onHide.subscribe((reason: string | any) => {
      this.getNovedades();
    });
  }

  sumarDias(dias: number) {
    let fecha = new Date();
    fecha.setDate(fecha.getDate() + dias);
    return this.util.formatDate(fecha);
  }

  sumarAnios(fecha: string) {
    let fecha_ = new Date(fecha);
    fecha_.setFullYear(fecha_.getFullYear() + 1);
    return this.util.formatDate(fecha_);
  }

  diffDias(fecha: string) {
    let ini_ = new Date(fecha);
    let fin_ = new Date(ini_.setFullYear(ini_.getFullYear() + 1));
    var diasdif = fin_.getTime() - new Date().getTime();
    return Math.round(diasdif / (1000 * 60 * 60 * 24));
  }

  getReporteMant() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    this.service.getReporteMantenimientos(
      this.codUser ?? 0,
      this.vehiculo ? this.vehiculo.codigo : 0,
      desde,
      hasta
    );
  }

  getReporteConsumo() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    this.service.getReporteConsumo(
      0,
      this.codUser ?? 0,
      this.vehiculo ? this.vehiculo.codigo : 0,
      desde,
      hasta
    );
  }

  getReporteComp() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    this.service.getReporteComparendos(
      this.codUser ?? 0,
      -1,
      this.vehiculo ? this.vehiculo.codigo : 0,
      desde,
      hasta
    );
  }

  getReporteRevisiones() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    this.service.getReporteRevisiones(
      this.codUser ?? 0,
      this.vehiculo ? this.vehiculo.codigo : -1,
      desde,
      hasta
    );
  }

  getReporteAccidentes() {
    let desde = '0';
    let hasta = '0';

    if (
      this.daterangepickerModel &&
      this.daterangepickerModel.length > 0 &&
      this.daterangepickerModel[0] &&
      this.daterangepickerModel[1]
    ) {
      desde = this.util.formatDate(this.daterangepickerModel[0]);
      hasta = this.util.formatDate(this.daterangepickerModel[1]);
    }
    this.service.getReporteAccidentes(
      this.codUser ?? 0,
      this.vehiculo ? this.vehiculo.codigo : 0,
      desde,
      hasta
    );
  }

  clear(option: number) {
    if (option == 1) {
      this.placavehiculo = '';
    } else {
      this.asyncSelected = '';
      this.codUser = 0;
    }

    this.consultarVehiculo();
  }
}
