<div class="modal-header bg-blue">
  <h4 class="modal-title pull-left">Firmar Revisión ({{revision}})</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0 pt-2">

  <!--<signature-pad
  (onSave)="onSaveHandler($event)"
  (onClear)="onClearHandler()"
  [width]="500"
  [height]="300"
  [hideFooter]="true"
  [formDataURL]="'url'"
  [label]="'prueba'"></signature-pad>
  -->
<div>Firma 1</div>
  <div style="background-color: #cccccc;width: 600px;margin: 0 auto;">
    <signature-pad #SignaturePad1 [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete(1)"></signature-pad>
    <button type="button" class="btn btn-danger mr-2"  (click)="clear(1)">Borrar 1</button>
  </div>

<br>
<div>Firma 2</div>
  <div style="background-color: #cccccc;width: 600px;margin: 0 auto;">
    <signature-pad #SignaturePad2 [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete(2)"></signature-pad>
    <button type="button" class="btn btn-danger mr-2"  (click)="clear(2)">Borrar 2</button>
  </div>

<div class="row mt-4">
  <div class="col col-12 alert alert-danger p-2 small" style="max-width: 500px;margin: 0 auto;" *ngIf="error !== '' " >
    {{error}}
  </div>

</div>


  <div class="text-center mt-4 mb-4">
    <button type="button" class="btn btn-warning"  (click)="enviar()">Guardar</button>
  </div>

</div>



