import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VehiculoService } from '../vehiculo.service';

@Component({
  selector: 'app-novedades-grafica',
  templateUrl: './novedades-grafica.component.html',
  styleUrls: ['./novedades-grafica.component.css']
})
export class NovedadesGraficaComponent implements OnInit {

  public vehiculo: number;
  public placa: string;

  public grafica = {
    type: 'ColumnChart',
    columns: ['Servicio', 'Km Servicio', 'km Calculado'],
    options: this.getOptions(null, 235, 400, 'bottom', 22, 'right',['#007bff','#FF9900'], '# KM'),
    data: null
  };

  public grafica2 = {
    type: 'ColumnChart',
    columns: ['Servicio', 'Fecha Servicio', 'Fecha Calculada'],
    options: this.getOptions(null, 235, 400, 'bottom', 22, 'right',['#007bff','#FF9900'],'Y-MM-dd'),
    data: null
  };

  constructor(public bsModalRef: BsModalRef, public service: VehiculoService) { }

  ngOnInit(): void {
    this.service.getGraficaNovedades(this.vehiculo).subscribe(data => {
      console.log(data)

      const chartDataTemp = [];
      data.novedadesKm.forEach(item => {
          chartDataTemp.push([ item.servicio, item.km, item.kmservicio]);
      });
      if(chartDataTemp.length > 0) {
        this.grafica.data = chartDataTemp;
      }


      const chartDataTemp2 = [];
      data.novedadesDias.forEach(item => {
          chartDataTemp2.push([ item.servicio, item.dias == 0 ? null: new Date(item.dias), new Date(item.diasservicio)]);
      });
      if(chartDataTemp2.length > 0) {
        this.grafica2.data = chartDataTemp2;
      }



    })
  }



  getOptions(title: string, w: number, h: number, legendPosition: string, fonttitlesize: number, textPosition: string, color: string[], format) {
    return {
        //title: title,
        displayExactValues: true,
        // width: w,
        // height: h,
        pieHole: 0.4,
        //titleTextStyle: {color: '#000000', fontName: 'Arial', fontSize: fonttitlesize, '1': 'normal', bold: false},
        legend: {position: legendPosition, fontSize: '10', color: '#9b9b9b'},
        colors: color,
        hAxis: {
            textPosition: textPosition,
            textStyle: {
                color: '#9b9b9b',
                fontSize: '10'
            },
            titleTextStyle: {
                color: '#9b9b9b',
                fontSize: '10'
            }
        },
        vAxis: {
            textPosition: '',
            labelMaxWidth: 20,
            textStyle: {
                color: '#666666',
                fontSize: '9'
            },
            titleTextStyle: {
                color: '#666666',
                fontSize: '8'
            },
            format:format
        }
    };

  }
}
