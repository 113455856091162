<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<div class="app-body" (window:scroll)="onScrolling()">


  <app-navbar></app-navbar>
  <div *ngIf="!bannerShow" style="height: 90px;width: 100%;background-color: #ffffff;">...</div>


  <div [ngClass]="{ 'nav-fixed': !bannerShow }" >
    <router-outlet ></router-outlet>
  </div>


  <!--foot page-->
  <app-footpage></app-footpage>
  </div>
  <div class="up" [hidden]="!upShow" (click)="up()"></div>

  <div *ngIf="sesion" class="whatsapp" >
     <div class="wa" [routerLink]="['/consultaempleado']" ></div>
  </div>


  <div *ngIf="wait" [hidden]="!wait" class="wait text-center">
    <div style="position: fixed;top:50%;left:48%;">
      <img src="assets/GENERAL/loading.gif" style="margin-bottom: 10px;" /><div style="font-size: 14px;">Procesando</div>
    </div>
  </div>
