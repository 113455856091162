import { Injectable } from '@angular/core';
import { NgHttpService } from '../core/http/ng-http.service';
import { Revision } from '../models/revision';
import { SelectOption } from '../models/select-option';
import { usuario } from '../models/usuario';
import { Vehiculo } from '../models/vehiculo';

@Injectable({
  providedIn: 'root'
})
export class AdministracionService {

  constructor(private http: NgHttpService) { }

  iniVehiculo() {
    return this.http.get('administracion/vehiculo/ini');
  }

  insertVehiculo(vehiculo: Vehiculo) {
    return this.http.post('administracion/vehiculo', vehiculo );
  }

  updateVehiculo(vehiculo: Vehiculo) {
    return this.http.put('administracion/vehiculo', vehiculo );
  }




  iniServiciosR(){
    return this.http.get('administracion/serviciosrecurrentes/ini');
  }
  getServiciosR(){
    return this.http.get('administracion/serviciosrecurrentes');
  }

  insertServicioR(serv: SelectOption){
    return this.http.post('administracion/serviciosrecurrentes',serv);
  }

  updateServicioR(serv: SelectOption){
    return this.http.put('administracion/serviciosrecurrentes',serv);
  }
  deleteServicioR(codigo: number) {
    return this.http.delete('administracion/serviciosrecurrentes/'+codigo);
  }






  getCategorias(){
    return this.http.get('administracion/categoriaservicio');
  }
  insertCategoria(cat: SelectOption) {
    return this.http.post('administracion/categoriaservicio', cat);
  }
  updateCategoria(cat: SelectOption) {
    return this.http.put('administracion/categoriaservicio', cat);
  }
  deleteCategoria(cat: number){
    return this.http.delete('administracion/categoriaservicio/'+cat);
  }


  getServiciosCat(cat: number){
    return this.http.get('administracion/categoria/'+cat+'/servicios');
  }
  insertServicio(serv: SelectOption){
    return this.http.post('administracion/servicio', serv);
  }
  updateServicio(serv: SelectOption){
    return this.http.put('administracion/servicio', serv);
  }
  deleteServicio(serv: number, cat: number) {
    return this.http.delete('administracion/categoria/'+cat+'/servicio/'+serv);
  }


  iniUsuarios() {
    return this.http.get('administracion/usuarios/ini');
  }

  getUsuarios(perfil: number, estado: number, pag: number, itempag: number) {
    return this.http.get('administracion/usuarios/perfil/'+perfil+'/estado/'+estado+'/pag/'+pag+'/npag/'+itempag);
  }
  consultarId(tipoid: number, id: string) {
    return this.http.get('administracion/usuario/tipoid/'+tipoid+'/id/'+id);
  }
  consultarCod(cod: number) {
    return this.http.get('administracion/usuario/'+cod);
  }
  filtrarUsuarios(nombre: string) {
    return this.http.get('administracion/usuario/filtro/'+nombre);
  }

  insertUser(user: usuario) {
    return this.http.post('administracion/usuario', user);
  }

  updateUser(user: usuario) {
    return this.http.put('administracion/usuario', user);
  }


 getProveedores(){
   return this.http.get('administracion/proveedores')
 }

 insertProveedor(prov: SelectOption){
  return this.http.post('administracion/proveedor', prov)
 }

 updateProveedor(prov: SelectOption){
  return this.http.put('administracion/proveedor', prov)
 }
 deleteProveedor(prov: number){
  return this.http.delete('administracion/proveedor/'+prov);
 }

 resetTacometro(revision: any){
  return this.http.post('administracion/reset/tacometro',revision);
 }

}
