<div class="row mb-5" style="max-width: 600px;margin:  0px auto;min-height: 500px; ">
  <div class="col font-md text-center">

     <div class="mt-5 mb-5 title-text"><h3>Nuevo Servicio</h3></div>
<section>

  <div class="row mt-5">

    <div class="col col-12 text-left">
      <label for="fecha" class="col-form-label " >Categoría</label>
      <select class="custom-select"  [(ngModel)]="categoria" (change)="getServiciosCat()">
        <option *ngFor=" let item of categorias" [ngValue]="item.codigo" >{{item.descripcion}}</option>
      </select>
    </div>

  </div>


     <section *ngIf="servicios.length > 0">
       <div class="mt-4 mb-4 text-left"><b>Servicios Categoría:</b></div>
      <div class="cav-radius mt-1"></div>
      <div class="table-responsive" >

         <table class="table" style="border: solid 1px #cccccc;">
          <thead class="thead-dark small">
           <tr class="bg-info text-white" >
            <th scope="col" >COD.</th>
            <th scope="col" class="text-left">CATEGORÍA</th>
              <th scope="col" class="text-left">DESCRIPCIÓN</th>
              <th scope="col" class="text-left"></th>
              <th scope="col" class="text-left"></th>
           </tr>
          </thead>
           <tbody>
           <tr *ngFor="let item of servicios;let i = index; let impar = odd;let par = even;"
            [ngClass]="{'tr-1':par,'tr-2':impar,'tr-active': i == tractive}"
            (click)="tractive = i"  >
            <td>{{item.codigo}}</td>
            <td class="text-left">{{item.aux}}</td>
            <td class="text-left" *ngIf="!item.enable">{{item.descripcion}}</td>
            <td class="text-left" *ngIf="item.enable">
              <input  type="text" class="form-control" [(ngModel)]="item.temp">
            </td>
            <td class="text-left" *ngIf="!item.enable"><img [src]="item.estado==1 ? 'assets/GENERAL/mod.png' : 'assets/GENERAL/mod0.png'" width="30" (click)="enableServ(item)">   </td>
            <td class="text-left" *ngIf="item.enable"><img src="assets/GENERAL/ok.png" width="30" (click)="updateServ(item)">   </td>

            <td class="text-left"><img  [src]="item.estado==1 ? 'assets/GENERAL/delete.png' : 'assets/GENERAL/delete2.png'" width="30" (click)="deleteServicio(item)">   </td>

           </tr>

          </tbody>
         </table>

     </div>
    </section>


     <div class="row mt-5" style="border:solid 1px #cccccc;border-radius: 10px;padding: 10px;">
      <div class="col col-12 mb-3 text-left"><b>Agregar Nuevo Servicio</b></div>

      <div class="col col-12 text-left">
        <label for="fecha" class="col-form-label " >Descripción</label>
        <input  type="text" class="form-control" [(ngModel)]="servicio.descripcion" name="id" placeholder="Descripción Servicio">
      </div>

    </div>

    <div  class="row " style="max-width: 600px;margin: 0px auto; ">

      <div class="col col-12 alert alert-danger p-2 small mt-4 mb-4"  *ngIf="error !== '' " >
        {{error}}
      </div>

      <div class="btn-conten mt-5">
      <div  class="butomForm" (click)="insertServicio()" >
        <div class="img save"></div>
        <div>Registrar</div>
      </div>

    </div>
    </div>


    </section>


  </div>
</div>
