import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {


  constructor() { }

  formatDate(d: Date): any {
    try {
    let month = '' + (d.getMonth() + 1);
    let day = '' + (d.getDate());
    const year = d.getFullYear();

    if (month.length < 2) {
    month = '0' + month;
    }

    if (day.length < 2) {
    day = '0' + day;
    }
    return [year, month, day].join('-');
  } catch (e) {
    return d;
  }
}


formatTime(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const strTime = hours + ':' + minutes + ':00.0';
  return strTime;
}

formatHoraAMPM(date: Date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const strTime = (hours < 10 ? '0' + hours : hours) + ':' + ( minutes < 10 ? '0' + minutes : minutes ) + ' ' + ampm;
  return strTime;
}


getHours(time: string): number {
  let aux = 0;
  if (time.substr(6, 2).trim() == 'PM' ) {
    aux = 12;
  }
  return parseInt(time.substr(0, 2), 0) + aux;
}

getMinutes(time: string): number {
    return parseInt(time.substr(3, 2), 0);
}



b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


}
